<div class="container">
    <div class="header__row">
        <div class="header_tags">
            <span class="header__label">Запись на услугу</span>
            <button
                class="cls-btn-second cls-btn-filter"
                *ngFor="let fl of filterList"
                (click)="selectFilter(fl); $event.preventDefault()"
                [ngClass]="
                    fl.keyid == selectedFilter?.keyid && selectedFilter === fl
                        ? 'cls-btn-filter-active'
                        : ''
                "
                [disabled]="loadingServices"
            >
                <span class="no-wrap">{{ fl.text }}</span>
            </button>
        </div>

        <div class="filterbar" (click)="this.talonPickerAnime = 'off'">
            <div class="filter-wrapper">
                <img
                    src="./assets/img/svg/loop.svg"
                    alt="loop"
                    class="filterbar-loop"
                />
                <input
                    type="search"
                    [(ngModel)]="filterSearchText"
                    placeholder="{{
                        selectedService?.text && filterSearchText
                            ? selectedService?.text
                            : 'Выбор услуги'
                    }}"
                    (input)="onFilterInput($event)"
                    (focus)="isDropdownOpen = true"
                    (click)="isDropdownOpen = true"
                    (keyup.esc)="clearFilterSearchText()"
                    class="filter filterbar__filter"
                    style="padding-left: 30px"
                />
            </div>

            <div
                class="filter__dropdown-list custom-scrollbar"
                *ngIf="isDropdownOpen"
            >
                <div
                    *ngFor="let serv of filterServices()"
                    class="filter__option"
                    (click)="pickService(serv)"
                >
                    <div class="filter__option-container">
                        <div class="filter__option-text">
                            <span>{{ serv.text }}</span>
                            <button
                                *ngIf="serv.description || serv.srv_description"
                                class="info-btn"
                                (click)="openServModal(serv, $event)"
                            >
                                <img src="./assets/img/svg/info.svg" />
                            </button>
                        </div>

                        <div class="filter__option-price">
                            <span
                                *ngIf="serv.price != 0"
                                class="filter__option-text-span"
                                >{{ serv.price }} руб</span
                            >
                            <span
                                *ngIf="serv.price == 0"
                                class="filter__option-text-span"
                                >Бесплатно</span
                            >
                            <!-- показываем иконку только в мобильной версии -->
                            <div
                                class="filter__option-icon-mobile"
                                *ngIf="serv.is_online_pay"
                            >
                                <img
                                    src="./assets/img/svg/rub.svg"
                                    alt="rub_svg"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="filter__option-icon" *ngIf="serv.is_online_pay">
                        <img src="./assets/img/svg/rub.svg" alt="rub_svg" />
                    </div>
                </div>
            </div>
        </div>

        <!-- //меню для телефона -->
        <div class="mobile-dropdown" *ngIf="!loadingServices">
            <button
                class="mobile-filter__button"
                (click)="mobileFilterHandler()"
                [ngClass]="{ 'border-active': staticFilterSelected }"
            >
                <img
                    src="./assets/img/svg/filters.svg"
                    alt="filter"
                    [ngClass]="{ 'border-active': staticFilterSelected }"
                    class="mobile-filter__button-swg"
                    *ngIf="!staticFilterSelected"
                />
                <img
                    src="./assets/img/svg/filtersAccept.svg"
                    alt="filterAccepted"
                    [ngClass]="{ 'border-active': staticFilterSelected }"
                    class="mobile-filter__button-swg"
                    *ngIf="staticFilterSelected"
                />
            </button>
            <div *ngIf="isMobileFlilterOpen" class="dropdown-menu">
                <app-filter-component
                    [staticFilterSelected]="staticFilterSelected"
                    (filterChanged)="onFilterChanged($event)"
                    (closeFilter)="isMobileFlilterOpen = false"
                    [searchAmine]="searchAmine"
                    class="dropdown-menu"
                ></app-filter-component>
            </div>
        </div>
    </div>
    <div
        *ngIf="!loadingServices && services.length === 0"
        class="services__empty"
    >
        Список пуст
    </div>
    <div class="loader-container" *ngIf="loadingServices">
        <div *ngIf="loadingServices">
            <loader type="page"> </loader>
        </div>
    </div>

    <div class="doctors custom-scrollbar" *ngIf="showDoctors">
        <ng-container *ngFor="let doctor of doctors">
            <button
                class="doctors__item"
                (click)="pickDoctor(doctor)"
                [class.active]="doctor === activeItem"
                *ngIf="!isDropdownOpen"
            >
                <img
                    class="doctors__img"
                    src="{{ getDocImg(doctor.docid) }}"
                    (error)="getDocImgDefault($event)"
                    alt="doc"
                    width="70"
                    height="70"
                    border-radius="5px"
                />
                <div class="doctors__item-container">
                    <div class="doctors__item-info">
                        <div class="doctors__name">
                            <!-- {{ doctor.text }} -->
                            <span class="doctor-name-full">{{
                                doctor.text
                            }}</span>
                            <span class="doctor-name-short">{{
                                formatDoctorName(doctor.text)
                            }}</span>
                            <button
                                type="button"
                                class="doctors__info"
                                (click)="openDocInfoModal(doctor, $event)"
                            >
                                <img
                                    src="./assets/img/svg/info.svg"
                                    alt="info"
                                />
                            </button>
                        </div>

                        <div
                            class="doctors__description"
                            *ngIf="doctor.info && doctor.info.length > 0"
                        >
                            <div class="doctors__description-text">
                                {{ experienceText(doctor.workExp) }}
                            </div>
                        </div>
                    </div>

                    <div class="doctors__right">
                        <div class="doctors__spec">
                            {{ doctor.specid_1 }} (специальность)
                        </div>
                        <div class="doctors__rcount" *ngIf="!loadingListDoc">
                            {{ rcountText(doctor.rcount) }}
                        </div>
                    </div>
                </div>
            </button>
        </ng-container>
    </div>

    <div
        *ngIf="showTalonPicker"
        class="cls-date-rnum"
        [@rightPanelAnimation]="talonPickerAnime"
    >
        <!-- [selectedDoctor]="selectedDoctor" -->
        <app-talonpicker
            [params]="paramsTalonPicker"
            (talonPickerClosed)="onTalonPickerClosed()"
        ></app-talonpicker>
    </div>

    <div #mobileTemplate *ngIf="openMobileTemplate" class="mobile-template">
        <rec-services-mobile
            [selectedDoctor]="selectedDoctor"
            [params]="paramsTalonPicker"
            (closePicker)="closeRegistrationPicker()"
        >
        </rec-services-mobile>
    </div>
</div>
