<!-- компонент для отображения списка услуг врача дляrec.component.html-->
<div class="srv__wrapper">
    <div class="srv__top">
        <button class="buttons">
            <img
                src="./assets/img/svg/arrow_back.svg"
                alt="Закрыть фильтр"
                (click)="closeSrvListHandler()"
                width="24"
                height="24"
            />
        </button>
        <div class="srv-top__title">Выбор услуги</div>
    </div>
    <div class="srv__list">
        <div
            class="srv__list-item"
            *ngFor="let srv of srvList"
            (click)="srvPicked(srv)"
        >
            <div class="srv__list-item-top">
                {{srv.text}}
                <button
                    *ngIf="srv.description"
                    class="doc-info-btn"
                    (click)="openServDescModal(srv, $event)"
                >
                    <img src="./assets/img/svg/info.svg" />
                </button>
            </div>
            <div class="srv-option__price-container">
                <div class="srv-option__price">
                    <span *ngIf="srv.price != 0">{{ srv.price }} руб.</span>
                    <span *ngIf="srv.price == 0">Бесплатно</span>
                </div>
                <div
                    *ngIf="srv.is_online_pay == 1"
                    class="srv-option__price-icon"
                >
                    <img src="./assets/img/svg/rub.svg" />
                </div>
            </div>
        </div>
    </div>
</div>
