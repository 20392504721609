import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from 'src/app/interfaces/select';

@Pipe({
    name: 'isChipSelected',
})
export class IsChipSelectedPipe implements PipeTransform {
    transform(chip: SelectOption, values: SelectOption[]): any {
        return !!values.find((c) => c.value === chip.value && c.selected);
    }
}
