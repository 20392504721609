import { SelectOption } from './select';
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';

/**
 * Способ ввода данных в протокол (приходит с базы)
 * */
export enum ProtocolItemInputType {
    string, //0  - Простой текст (строка),
    textarea, //1  - Описание (много строк),
    select_drop_down, //2  - Значения из списка,
    ref_select, //3  - Значения из внешнего справочника,
    formula, //4  - Формула,
    table, //5  - Таблица,
    sys_ref, //6  - Значение из системного справочника,
    sql, //7  - Формула SQL,
    diagnos_field, //8  - Поле для ввода диагноза,
    services_field, //9  - Поле для ввода услуг,
    tree, //10 - Значение из дерева,
    select_buttons, //11 - Значение из списка отмеченное галочками,
    file, //12 - Файл,
    schema, //13 - Схема (изображение);
}

/**
 * Формат хранения value в базе
 * */
export enum ProtocolItemDataType {
    string, //0-Текст,
    date, //1-Дата,
    time, //2-Время,
    datetime, //3-Дата и время,
    bool, //4-Логический,
    float, //5-Число с плавающей точкой,
    id, //6-Идентификатор,
    number, //7-Целое число,
    text, //8-Большой текст (clob)
}

/**
 * Разделитель, не разделитель
 */
export enum ProtocolItemType {
    delimeter, //0 - Разделитель
    editable, //1 - Рeдактируемый
}

export interface ProtocolInfo {
    is_exists: number;
    is_paid: number | null;
    is_true_pat: number;
    visit_dat: string;
    visit_time: string;
}

/**
 * Приходящий с базы
 */
export interface ProtocolItem {
    value_type: ProtocolItemInputType;
    typ: ProtocolItemType;
    data_type: ProtocolItemDataType;
    form_id: number;
    form_name: string;
    form_result_id: number;
    form_item_id: number;
    form_item_vals?: IQuestionsItems[]; // добавлено поле в контексте типа 2 и 3 и 11
    form_result_vals?: IQuestionsItems[]; // добавлено поле в контексте типов 2 и 3 и 11
    form_item_val_id?: number; // удалены поля в контексте типов 2 и 3 и 11
    form_item_val_text?: string; // удалены поля в контексте типов 2 и 3 и 11
    form_result_value_id?: number | null; // удалены поля в контексте типов 2 и 3 и 11
    form_result_value_text?: string | null; // удалены поля в контексте типов 2 и 3 и 11
    form_value_id: number | null;
    is_list_chkr: number | null;
    is_multi: number | null;
    is_required: number | null;
    question_name: string | null;
}
interface IQuestionsItems {
    id: number;
    text: string;
}

/**
 * Тип поддерживаемых инпутов в протоколе
 */
export type ProtocolControlType =
    | 'text'
    | 'select'
    | 'multi-select'
    | 'delimiter'
    | 'date';

/**
 * Тип для группировки записей из базы по id инпута
 */
export type ProtocolItemsGroup = [number, ProtocolItem[]];

/**
 * Конструктор протокола. Принимает записи с базы, возврадает на их основании новый протокол.
 */
export const ProtocolBuilder = (items: ProtocolItem[]) => {
    const formItemIdMap = new Map<number, ProtocolItem[]>();
    items.reduce((map, item) => {
        const formItem = map.get(item.form_item_id);

        if (formItem) formItem.push(item);
        else map.set(item.form_item_id, [item]);

        return map;
    }, formItemIdMap);
    // console.log(formItemIdMap);

    const protocolItemsGroup = Array.from(formItemIdMap);

    const controls = protocolItemsGroup.map(
        (item: ProtocolItemsGroup) => new ProtocolControl(item)
    );

    return new Protocol(controls);
};

/**
 * Основной класс для работы с протоколами
 * Создаётся при помощи ProtocolBuilder
 */
export class Protocol {
    controls: ProtocolControl[];
    form: FormGroup = new FormGroup({});

    constructor(controls: ProtocolControl[]) {
        this.controls = controls;
        this.initForm(controls);
    }

    /**
     * Инициализация формы
     */
    private initForm(controls: ProtocolControl[]) {
        controls.forEach((control) => {
            const validators: ValidatorFn[] = [];

            if (control.isRequired) validators.push(Validators.required);

            this.addFormControl(control, validators);
        });
    }

    /**
     * Добавляет новый FormControl в поле form. Можно передать массив валидаций
     * @param control  Поле протокола
     * @param validators Валидаторы поля
     */
    addFormControl(
        control: ProtocolControl,
        validators: ValidatorFn[] = []
    ): void {
        this.form.addControl(
            control.name,
            new FormControl(control.defaultValue, [...validators])
        );
        this.form.get('path');
    }

    /**
     * Получение массива записей с параметрами для сохранения протокола в БД
     */
    prepareToSave(visitID: number) {
        return this.controls.map((control) => {
            let valuesText = ''; // Значение / значения ('груша;яблоко')
            let valuesId = ''; // id / ids значений  ('1;3')
            let valueText = ''; // Отображаемое значение  (Груша, яблоко)

            const formControl = this.form.get(control.id.toString());

            if (control.type === 'multi-select') {
                // console.log(control.item.form_result_vals);
                valuesText =
                    control
                        .item!.form_result_vals?.map((item) => item.text)
                        .join(';') ?? '';
                valuesId =
                    control
                        .item!.form_result_vals?.map((item) => item.id)
                        .join(';') ?? '';
                valueText = valuesText.replace(';', ', ');
            } else if (control.type === 'select') {
                valuesText = control.item.form_result_vals?.[0]
                    ? control.item.form_result_vals?.[0].text
                    : '';
                valuesId = control.item.form_result_vals?.[0]
                    ? control.item.form_result_vals?.[0].id.toString()
                    : '';
                valueText = control.item.form_result_vals?.[0]
                    ? control.item.form_result_vals?.[0].text
                    : '';
            } else if (control.type === 'text') {
                valueText = formControl?.value;
            } else if (control.type === 'date') {
                valueText = formControl?.value;
            }

            if (valueText === null) valueText = '';
            if (valuesId === null) valuesId = '';
            if (valuesText === null) valuesText = '';

            return {
                p_visit_id: +visitID,
                p_form_result_id: +control.item.form_result_id,
                p_form_item_id: +control.item.form_item_id,
                p_values_text: valuesText,
                p_values_id: valuesId,
                p_text: valueText,
            };
        });
    }
}

/**
 * Поле/контрол протокола
 */
export class ProtocolControl {
    base: ProtocolItemsGroup; // Содержит все записи из базы по протоколу с одинаковым form_item_id
    id: number;
    name: string;
    label?: string;
    isRequired: boolean;
    options?: SelectOption[]; // Опшены для списков
    isMultiSelect: boolean;
    item: ProtocolItem;
    defaultValue: any = '';
    data_type: number;

    constructor(base: ProtocolItemsGroup) {
        this.base = base;
        const [id, items] = base;
        const item = items[0];

        // Присвоение общей информации об инпуте
        this.item = item;
        this.id = id;
        this.name = this.id.toString();
        this.label = item.question_name ?? undefined;
        this.isRequired = !!item.is_required;
        this.isMultiSelect = !!item.is_multi;
        this.data_type = item.data_type;

        // Присвоение дефолтного value
        if (
            this.item.value_type === ProtocolItemInputType.select_buttons ||
            this.item.value_type === ProtocolItemInputType.select_drop_down ||
            this.item.value_type === ProtocolItemInputType.ref_select
        ) {
            this.options = this.initOptions(item);
            // console.log(this.options);
            this.defaultValue = this.isMultiSelect
                ? this.options?.filter((opt) => opt.selected)
                : this.options?.find((opt) => opt.selected);
        } else if (this.item.value_type === ProtocolItemInputType.string) {
            this.defaultValue = this.item.form_result_value_text;
        }
    }

    /**
     * Формирует массив опшенов на основании записей из базы
     */
    initOptions(itemObject: ProtocolItem) {
        // console.log(itemObject.form_result_vals, itemObject.form_item_vals);
        return itemObject.form_item_vals?.map((item) => ({
            value: item.id,
            text: item.text,
            selected:
                itemObject.form_result_vals?.some(
                    (val) => val.id === item.id
                ) || false,
        }));
    }

    /**
     * Получение типа контрола
     */
    get type(): ProtocolControlType {
        const { typ, data_type, value_type } = this.item;

        if (typ === ProtocolItemType.delimeter) return 'delimiter';
        if (data_type === ProtocolItemDataType.date) return 'date';

        if (
            value_type === ProtocolItemInputType.select_drop_down ||
            value_type === ProtocolItemInputType.select_buttons ||
            (value_type === ProtocolItemInputType.ref_select &&
                this.options &&
                this.options.length > 0)
        ) {
            return this.isMultiSelect ? 'multi-select' : 'select';
        }

        if (
            value_type === ProtocolItemInputType.ref_select &&
            this.options &&
            this.options.length === 0
        ) {
            return 'text';
        }

        return 'text';
    }
}
