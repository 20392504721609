export namespace Utils {
    export function experienceText(exp: string): string {
        if (!exp || exp === '') return '';

        const years = parseInt(exp.split(' ')[0]);
        if (isNaN(years)) {
            if (exp.toLowerCase().includes('стаж')) return exp;
            return `Стаж: ${exp}`;
        }
        const lastDigit = years % 10;
        const lastTwoDigits = years % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return `Стаж ${years} лет`;
        }

        switch (lastDigit) {
            case 1:
                return `Стаж ${years} год`;
            case 2:
            case 3:
            case 4:
                return `Стаж ${years} года`;
            default:
                return `Стаж ${years} лет`;
        }
    }
}
