<div class="calendar">
    <div class="calendar__header">
        <button class="buttons button__prev" (click)="prevMonth()">
            <img src="./assets/img/svg/arrow_r.svg" alt="prev" />
        </button>
        <span class="calendar__date">
            {{ formatMonth() }}
        </span>
        <button class="buttons button__next" (click)="nextMonth()">
            <img src="./assets/img/svg/arrow_r.svg" alt="next" />
        </button>
    </div>

    <div class="calendar__grid">
        <div class="weekdays">
            <div *ngFor="let day of weekDays">
                {{ day }}
            </div>
        </div>

        <div class="weeks">
            <div class="week" *ngFor="let week of weeks">
                <div
                    *ngFor="let day of week"
                    class="day"
                    [class.active]="isActiveDate(day)"
                    [class.selected]="selectedDate?.isSame(day, 'day')"
                    [class.other-month]="!day.isSame(currentMonth, 'month')"
                    (click)="selectDate(day)"
                >
                    {{ day.date() }}
                </div>
            </div>
        </div>
    </div>
</div>
