/* Компонент раздела "Лаборатория" мобильной версии */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { lastValueFrom, of } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { IDateRange } from '../../../interfaces/date-range.interface';
import {
    IBactery,
    ILabOrder,
    ILabResearch,
    ILabResearchForm,
    IPakaz,
    IResearchForm,
    IResearchFormBio,
} from '../../../interfaces/lab.interface';
import { ConfigService } from '../../../services/application/config.service.';
import { LoadService } from '../../../services/application/load.service';
import { compToPagination } from '../../../services/compToPagination';
import { LabService } from '../../../services/lab.service';
import { Size } from '../../../services/size';
import {
    getNameDay,
    getStrEnd,
    getTime,
} from '../../application/global.function';
import { PayPeriodDialogComponent } from '../../payments/dialogs/pay-period-dialog/pay-period-dialog.component';

@Component({
    selector: 'app-labs-mobile',
    templateUrl: './labs-mobile.component.html',
    styleUrls: ['./labs-mobile.component.scss'],
})
export class LabsMobileComponent implements OnInit {
    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';
    isCovid = false; // КОВИД значение

    orderList: ILabOrder[] | null = null;
    selectedOrder: ILabOrder | null = null; // Выбранный заказ
    labResearchs: ILabResearch[] | null = null;

    labResult: ILabResearchForm[] = []; // Итоговый результат

    orderListLength: number = 0; // Количество записей
    // countRectoPage = 5;     // Количетсво записей на странице
    isLoading = false;
    loadedPages = 1;
    viewPage = 1;
    width = 0;
    periodText: any = '';

    countOfPages = 1;
    servicesPages: Array<ILabOrder[]> = [];
    servicesListLength: number = 0; // Количество записей
    isServicesListLengthLoaded = false;
    countRecToPage = 15; // Количетсво записей на странице
    // loadingServicesList = false;      // Загрузка
    loadingMoreContent = false;
    toggleOrder = false;

    loadingOrderList = false; // Загрузка заказов
    loadingResearchList = false; // Загрузка результатов
    private _setting: any;
    date!: IDateRange;
    pdfLoading = false;
    isNotShowResult = false;

    /* Инициализация компонента */
    constructor(
        private lab: LabService,
        private size: Size,
        private cToP: compToPagination,
        private configS: ConfigService,
        private load: LoadService,
        private title: Title,
        private dialog: MatDialog,
        private alert: AlertService,
        private http: HttpNewService
    ) {
        moment.locale('ru');
        this._setting = load.configData.pages.laboratory;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }
        this.date = { dt_begin: new Date(), dt_end: new Date() };

        // this.changePeriod({dt_begin: this.dtBegin, dt_end: this.dtEnd});
    }

    /* Установка параметров при инициализации */
    ngOnInit(): void {
        moment.locale('ru');
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 365);
        this.dtEnd = new Date();

        this.isLoading = true;
        this.changePeriod({ dt_begin: this.dtBegin, dt_end: this.dtEnd }).then(
            () => (this.isLoading = false)
        );
    }

    /* Получение количества записей */
    async getOrdersSize() {
        this.loadingOrderList = true;
        this.orderList = null;

        try {
            const res = await lastValueFrom(
                this.lab.getLaboratoryOrderSize(this.dtBegin, this.dtEnd)
            );

            if (res.size) {
                this.orderListLength = res.size;
                this.servicesListLength = res.size;
                await this.loadMoreServices(1, this.countRecToPage);
            } else {
                this.orderListLength = 0;
                this.loadingOrderList = false;
                this.servicesListLength = 0;
                this.loadingMoreContent = false;
            }
            this.setCountOfPages();
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка Лаб. заказов');
            console.error(error);
        } finally {
            this.loadingOrderList = false;
        }
    }

    /* Получение списка заказов */
    getOrders(pStart?: number, pEnd?: number) {
        console.log(pStart, pEnd);

        this.loadingOrderList = true;
        this.orderList = null;
        let order = 'asc';
        if (this.orderUP) {
            order = 'desc';
        } else {
            order = 'asc';
        }
        this.lab
            .getLaboratoryOrderList(
                this.dtBegin,
                this.dtEnd,
                pStart,
                pEnd,
                order
            )
            //    this.lab.getLaboratoryOrderList(new Date(2015, 1, 1), new Date(2021, 18, 1), pStart, pEnd, order)
            .subscribe(
                (res) => {
                    //

                    this.orderList = res;
                    this.servicesPages.push(res);
                    this.servicesPages.forEach((item) => {
                        item.forEach((el) => {
                            el.dtSort = new Date(
                                el.regdate.replace(
                                    /(\d+).(\d+).(\d+)/,
                                    '$3/$2/$1'
                                )
                            );
                        });
                    });
                    this.orderList.forEach((item) => {
                        item.dtSort = new Date(
                            item.regdate.replace(
                                /(\d+).(\d+).(\d+)/,
                                '$3/$2/$1'
                            )
                        );
                    });
                    //
                    if (this.orderList && this.orderList.length > 0) {
                        this.selectOrder(this.orderList[0]);
                    }
                    this.loadingOrderList = false;
                },
                (err) => {
                    console.error('ERRROr=', err);
                    this.loadingOrderList = false;
                }
            );
    }

    /* Выставление в фильтре сегодняшней даты */
    btnFiltrTek() {
        this.dtBegin = new Date();
        this.dtEnd = new Date();
        // this.changePeriod({begin: this.dtBegin, end: this.dtEnd});
    }

    /* Выставление в фильтре 3 предыдущих дней */
    btnFiltr3Day() {
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 3);
        this.dtEnd = new Date();
        // this.changePeriod({begin: this.dtBegin, end: this.dtEnd});
    }

    /* Изменение сортировки */
    sortUp() {
        this.cToP.setPage(1); // Переходим на первую страницу
        /*
        if (this.orderList) {
          this.orderList.sort((a, b) => a.dtSort < b.dtSort ? 1 : -1);
        }
    */
    }

    /* Изменение сортировки */
    sortAp() {
        this.cToP.setPage(1); // Переходим на первую страницу
        /*
        if (this.orderList) {
          this.orderList.sort((a, b) => a.dtSort > b.dtSort ? 1 : -1);
        }
    */
    }

    /* Сначала последние */
    btnFiltrOrderUp() {
        if (this.orderList && this.orderList.length > 0) {
            this.orderUP = !this.orderUP;
            if (this.orderUP === true) {
                this.orderUPText = 'Сначала последние';
                this.sortUp();
            } else {
                this.orderUPText = 'Сначала первые';
                this.sortAp();
            }
        }
    }

    /* Вывод текущего дня в формате ГГГГ */
    getTekDay(dt: Date | null | undefined): string {
        if (dt) {
            return moment(dt).format('dddd');
        } else {
            return '!';
        }
    }

    /* Вывод дня недели из даты */
    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    /* Вывод времени в формате ЧЧ:ММ из даты */
    getTime(dt: Date): string {
        return getTime(dt);
    }

    /** Получение периода текстом в формате "D MMM YYYY"*/
    formatDateToString(dt: IDateRange) {
        if (dt.dt_begin) {
            dt.str_begin = moment(new Date(dt.dt_begin)).format('D MMM YYYY');
        }
        if (dt.dt_end) {
            dt.str_end = moment(new Date(dt.dt_end)).format('D MMM YYYY');
        }
    }

    /* Заблокирован ли выбор года в календаре */
    isBlockYeer(index: number): boolean {
        let f = false;
        if (
            this.orderList &&
            this.orderList[index] &&
            this.orderList[index - 1]
        ) {
            if (
                this.orderList[index].dtSort.getFullYear() !==
                this.orderList[index - 1].dtSort.getFullYear()
            ) {
                f = true;
            }
        }
        if (index === 0) {
            f = true;
        }
        return f;
    }

    /* Получение года заказа по его порядковому номеру */
    getYeer(index: number): string {
        if (this.orderList && this.orderList[index]) {
            return this.orderList[index].dtSort.getFullYear() + '';
        } else {
            return '';
        }
    }

    /* Выбор заказа */
    selectOrder(order: ILabOrder) {
        this.selectedOrder = order;
        //  selectOrder(this.selectedOrder.research_id: number) {
        if (
            order.text_status === 'выполнен' ||
            order.text_status === 'частично выполнен'
        ) {
            this.isNotShowResult = false;
            this.labResearchs = [];
            this.loadingResearchList = true;
            this.labResult = [];
            let resFormaBio: ILabResearch[] = [];
            let resForma: ILabResearch[] = [];

            let formBio: IResearchFormBio[] = [];
            let form: IResearchForm[] = [];
            this.isCovid = false;

            this.lab
                .getLaboratoryResearch(order.research_id)
                //     this.lab.getLaboratoryResearch(tempId)
                .subscribe(
                    (res) => {
                        this.labResearchs = res;
                        //
                        if (res && res.length > 0) {
                            res.forEach((item, index) => {
                                if (
                                    !item.test_name &&
                                    !item.res_display_order
                                ) {
                                    // форма BIO
                                    resFormaBio.push(item);
                                } else {
                                    resForma.push(item);
                                }
                            });
                        }

                        //
                        //
                        //          let resultBio: IResearchFormBio[] = [];

                        if (resFormaBio.length > 0) {
                            of(resFormaBio)
                                .pipe(
                                    mergeMap((res) => res),
                                    groupBy((person) => person.ids), // Группируем по IDS
                                    mergeMap((group) => group.pipe(toArray())) // Возврат массива по группам
                                )
                                .subscribe((val) => {
                                    //
                                    // Группируем по микроорганизмам
                                    of(val)
                                        .pipe(
                                            mergeMap((res) => res),
                                            groupBy(
                                                (micro) =>
                                                    micro.abio_bactery_text
                                            ), // Группируем
                                            mergeMap((group) =>
                                                group.pipe(toArray())
                                            ) // Возврат массива по группам
                                        )
                                        .subscribe((valMicro) => {
                                            //
                                            let tmp: IBactery[] = [];
                                            tmp = <IBactery[]>valMicro.map(
                                                (itemMicro) => {
                                                    return {
                                                        name: itemMicro.res_antibiotic_text,
                                                        sir: itemMicro.res_sir,
                                                        mic: itemMicro.res_mic,
                                                        dia: itemMicro.res_dia,
                                                    };
                                                }
                                            );
                                            //

                                            formBio.push({
                                                name: valMicro[0]
                                                    .abio_bactery_text,
                                                bactery: tmp,
                                            });
                                        });

                                    //
                                    this.labResult.push({
                                        formBio: true,
                                        name: val[0].specimen_text,
                                        ids: val[0].ids,
                                        res: <IResearchFormBio[]>formBio,
                                        srvText: val[0].srvtext,
                                        srvRes: val[0].abio_conclusion,
                                        note: val[0].abio_note,
                                    });

                                    formBio = [];
                                });
                        }

                        //
                        // Разбираем стандартную
                        if (resForma.length > 0) {
                            of(resForma)
                                .pipe(
                                    mergeMap((res) => res),
                                    groupBy((frm) => frm.ids), // Группируем по IDS title_id
                                    mergeMap((group) => group.pipe(toArray())) // Возврат массива по группам
                                )
                                .subscribe((val) => {
                                    //
                                    let resPokaz: IPakaz[] = [];
                                    form = [];
                                    // Группировка по title
                                    of(val)
                                        .pipe(
                                            mergeMap((res) => res),
                                            groupBy(
                                                (frmTitle) => frmTitle.title_id
                                            ), // Группируем по title_id
                                            mergeMap((group) =>
                                                group.pipe(toArray())
                                            ) // Возврат массива по группам
                                        )
                                        .subscribe(
                                            (valTitle) => {
                                                resPokaz = [];
                                                //
                                                valTitle.forEach((item) => {
                                                    resPokaz.push({
                                                        name: this.getParamStrAndNull(
                                                            item.test_name
                                                        ),
                                                        val: this.getParamNumberAndNull(
                                                            item.value
                                                        ),
                                                        valEd: this.getParamStrAndNull(
                                                            item.unit
                                                        ),
                                                        min: this.getParamNumberAndNull(
                                                            item.norm_min
                                                        ),
                                                        max: this.getParamNumberAndNull(
                                                            item.norm_max
                                                        ),
                                                        last_res:
                                                            this.getParamNumberAndNull(
                                                                item.last_res
                                                            ),
                                                        patLevel:
                                                            this.getParamStrAndNull(
                                                                item.pat_level
                                                            ),
                                                        norm: this.getParamStrAndNull(
                                                            item.norm
                                                        ),
                                                    });
                                                });
                                                /* -- не показывал результаты без TITLE (COVID)
                                                              if (valTitle[0].title) {
                                                                form.push({name: valTitle[0].title, listResult: resPokaz});
                                                              }
                                        */
                                                form.push({
                                                    name: valTitle[0].title,
                                                    listResult: resPokaz,
                                                });
                                            },
                                            (errTitle) => {
                                                console.error(
                                                    'ERRROr Title=',
                                                    errTitle
                                                );
                                                this.loadingResearchList =
                                                    false;
                                            }
                                        );
                                    //
                                    this.labResult.push({
                                        formBio: false,
                                        name: val[0].specimen_text,
                                        ids: val[0].ids,
                                        res: form,
                                    });
                                });
                        }

                        //
                        //

                        this.loadingResearchList = false;
                    },
                    (err) => {
                        console.error('ERRROr=', err);
                        this.loadingResearchList = false;
                    }
                );
        } else {
            this.isNotShowResult = true;
        }
    }

    /* Вывод строки либо (если undefined) null */
    getParamStrAndNull(pr: any): string | null {
        if (pr) {
            return pr;
        } else {
            return null;
        }
    }
    /* Вывод целого числа либо (если undefined) null */
    getParamNumberAndNull(pr: any): number | null {
        if (pr) {
            return pr;
        } else {
            return null;
        }
    }

    //  getDateOrder(dt: Date | null): string {
    /* Вывод даты заказа */
    getDateOrder(dt: Date | null | undefined): string {
        if (dt) {
            // return getTekDayDATE(dt);

            return moment(dt).format('D MMMM YYYY [в] LT');
        } else {
            return '';
        }
    }

    /* Получение интерфейса формы биоматериала */
    resForFormBio(
        r: IResearchFormBio[] | IResearchForm[] | null
    ): IResearchFormBio[] {
        return <IResearchFormBio[]>r;
    }

    /* Получение интерфейса формы поиска */
    resForForm(
        r: IResearchFormBio[] | IResearchForm[] | null
    ): IResearchForm[] {
        return <IResearchForm[]>r;
    }

    /* Подстановка окончания к существительному */
    strEnd(val: number | undefined, str: string, massStr: string[]): string {
        return getStrEnd(val, str, massStr);
    }

    async goPdf(researchId: number | undefined) {
        if (researchId) {
            this.pdfLoading = true;
            try {
                const res = await lastValueFrom(
                    this.http.get(
                        this.getDownloadUrlForPDF(researchId),
                        null,
                        true
                    )
                );

                const isSafari = /^((?!chrome|android).)*safari/i.test(
                    navigator.userAgent
                );

                const file = new Blob([res], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                if (isSafari) {
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = `research_${researchId}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(fileURL);
                } else {
                    const emptyPage = window.open('about:blank', '_blank');
                    if (emptyPage) {
                        emptyPage.location.href = fileURL;
                    }
                }
            } catch (error) {
                this.alert.error('Ошибка загрузки');
            } finally {
                this.pdfLoading = false;
            }
        }
    }

    getDownloadUrlForPDF(researchId: number | undefined) {
        return `/report/pdf/${researchId}.pdf`;
    }

    //  isPrint(order: ILabOrder | null): boolean{
    /*озможна ли распечатка документа */
    isPrint(labResearch: ILabResearchForm[] | null): boolean {
        let f = false;
        if (labResearch && labResearch.length > 0) {
            //
            labResearch.forEach((item) => {
                if (item.res && item.res.length > 0) {
                    // @ts-ignore
                    item.res.forEach((itemRes) => {
                        //            if (itemRes && itemRes.name && itemRes.name.length>1){
                        if (itemRes) {
                            f = true;
                        }
                    });
                }
            });
        }
        return f;
    }

    /** Подгрузка услуг следующей страницы */
    loadNextPage() {
        // if (this.loadedPages / this.countOfPages !== 1 && !this.loadingMoreContent) {
        if (
            this.loadedPages / this.countOfPages < 1 &&
            !this.loadingMoreContent
        ) {
            this.loadedPages++;
            this.loadMoreServices(
                this.loadedPages * this.countRecToPage -
                    this.countRecToPage +
                    1,
                this.loadedPages * this.countRecToPage
            );
        }
    }

    /** Подгрузка дополнительного кал-ва услуг */
    async loadMoreServices(pStart?: number, pEnd?: number) {
        this.loadingMoreContent = true;
        let order: string;

        if (this.orderUP) {
            order = 'desc';
        } else {
            order = 'acs';
        }

        try {
            const res = await lastValueFrom(
                this.lab.getLaboratoryOrderList(
                    this.dtBegin,
                    this.dtEnd,
                    pStart,
                    pEnd,
                    order
                )
            );

            if (res && res.length > 0) {
                this.servicesPages.push(res);

                this.servicesPages.forEach((item) => {
                    item.forEach((el) => {
                        el.dtSort = new Date(
                            el.regdate.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')
                        );
                    });
                });
            }
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка Лаб. заказов');
        } finally {
            this.loadingMoreContent = false;
        }
    }

    /** Установка количества страниц*/
    setCountOfPages() {
        // this.countOfPages  = Math.ceil((this.orderListLength / this.countRecToPage) + 1);
        this.countOfPages = Math.ceil(
            this.orderListLength / this.countRecToPage
        );
        if (this.countOfPages == 0) {
            this.countOfPages = 1;
        }
    }

    /** Получение текста из периода */
    getPeriodText(dt: IDateRange) {
        let periodText = '';
        let isSelectedOneDay = dt.str_begin == dt.str_end;

        if (isSelectedOneDay) {
            if (dt.dt_begin == null && dt.dt_end == null)
                periodText = 'Лабораторные исследования за всё время';
            else if (dt.dt_begin?.toDateString() == new Date().toDateString())
                periodText = 'Последние исследования';
            else if (dt.str_begin) periodText = dt.str_begin;
        } else if (dt.dt_begin && dt.dt_end) {
            const yearAgo = moment(dt.dt_end).subtract(1, 'year');
            if (yearAgo.isSame(dt?.dt_begin)) {
                const yearAgo = moment(dt.dt_end).subtract(1, 'year');

                periodText = `За 1 год`;
            } else {
                periodText = `${dt.str_begin} - ${dt.str_end}`;
            }
        } else if (dt.str_begin) {
            periodText = dt.str_begin;
        } else if (dt.str_end) {
            periodText = dt.str_end;
        }
        return periodText;
    }

    /** Вызов диалога для выбора периода */
    showPeriodDialog() {
        const periodDialog = this.dialog.open(PayPeriodDialogComponent, {
            maxWidth: '650px',
            width: '100%',
            height: '100%',
            data: {
                dateRange: {
                    dt_begin: this.dtBegin,
                    dt_end: this.dtEnd,
                } as IDateRange,
            },
        });

        periodDialog.afterClosed().subscribe((result) => {
            if (result?.date) {
                this.dtBegin = result.date.dt_begin;
                this.dtEnd = result.date.dt_end;
                this.changePeriod(result.date);
            }
        });
    }
    /* Изменение периода в фильтре */
    async changePeriod(dt: IDateRange) {
        this.date = dt;
        this.servicesPages = [];
        this.orderListLength;
        await this.getOrdersSize();
        this.formatDateToString(this.date);
        this.periodText = this.getPeriodText(this.date);
        this.loadedPages = 1;
        this.viewPage = 1;
    }

    currDiv: any;

    slideToggle = false;
    /* Открытие блока с результатами анализа */
    slideMe(divVal: any) {
        if ((this.currDiv = divVal)) {
            this.slideToggle = !this.slideToggle;
        }
    }
    /* Открытие попапа с информацией по заказу */
    closeFakePopup() {
        this.toggleOrder = false;
        this.slideToggle = false;
    }

    /**
     * Фильтрация записей по дате
     * @param {number | null} startOffset Сдвиг даты С
     * @param {number | null} endOffset Сдвиг даты ПО
     */
    btnFilterByDate(
        startOffset: number | null = 0,
        endOffset: number | null = 0
    ) {
        if (startOffset !== null && endOffset !== null) {
            this.date.dt_begin = new Date();
            this.date.dt_begin.setDate(
                this.date.dt_begin.getDate() - startOffset
            );
            this.date.dt_end = new Date();
            this.date.dt_end.setDate(this.date.dt_end.getDate() - endOffset);
            this.isLoading = true;
            this.changePeriod({
                dt_begin: this.date.dt_begin,
                dt_end: this.date.dt_end,
            }).then(() => (this.isLoading = false));
        } else {
            this.dtBegin = new Date(2000, 0, 1);
            this.dtEnd = new Date();
            this.date.dt_begin = new Date(2000, 0, 1);
            this.date.dt_end = new Date();
            this.isLoading = true;
            this.changePeriod(this.date).then(() => {
                this.isLoading = false;
            });
        }
    }
}
