import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    forwardRef,
    ElementRef,
    ViewChild,
    HostListener,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../../interfaces/select';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectComponent),
        },
    ],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
    @Input() options: SelectOption[] = [];
    @Input() value?: SelectOption;
    @Input() placeholder = 'Выберите из списка...';
    @Input() label?: string;
    @Input() required = false;
    @Input() theme: 'primary' | 'flat' = 'primary';

    @Output() onSelectionChange = new EventEmitter<SelectOption>();
    onChange = (value: any) => {};

    @ViewChild('select')
    selectComponentRef!: ElementRef;

    selectedOptionText!: string;

    constructor() {}

    isDropdownOpen: boolean = false;

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
    writeValue(obj: any): void {}

    ngOnInit() {
        // console.log(this.value);
        this.selectedOptionText =
            this.value && this.value.selected
                ? (this.value?.text as string)
                : this.placeholder;
    }

    selectOption(opt: SelectOption) {
        this.onSelectionChangeHandler(opt);
        this.isDropdownOpen = false;
    }

    onSelectionChangeHandler(opt: SelectOption) {
        this.selectedOptionText = opt.text as string;
        this.value = opt;
        this.onChange(opt);
        this.onSelectionChange.emit(opt);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        if (!clickedElement.closest('.select')) {
            this.isDropdownOpen = false;
        }
    }
}
