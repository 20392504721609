<div class="narrow">
    <div class="app__mobile-page payments-page">
        <div
            class="container container--finance"
            *ngIf="showAbonement || showDeposit"
        >
            <app-panel
                class="finanse-header--fixed"
                classSrt="cls-position-relative"
                [ngClass]="visibill ? 'novisibill' : 'visibill'"
            >
                <div class="row cls-h-block">
                    <div class="finanse-header">
                        <div class="finanse-header__item" *ngIf="showDeposit">
                            <div class="cls-balans-text">Баланс</div>
                            <div class="cls-balans-sum">
                                {{ balans | priceSpace }}
                                {{ balans | i18nPlural : priceMapper }}
                            </div>
                        </div>
                        <div
                            class="finanse-header__item"
                            *ngIf="showBalance && showDeposit"
                        >
                            <button
                                class="btn cls-btn-second cls-btn-pay"
                                (click)="
                                    ShowTogglePopupPay(); this.showMyPay = true
                                "
                            >
                                Пополнить
                            </button>
                        </div>
                    </div>

                    <div class="pay-abonent__btnWrap" *ngIf="showAbonement">
                        <button
                            class="pay-anonent__btnPopup"
                            (click)="ShowTogglePopup(); this.showMyPay = false"
                        >
                            Оплатить Абонемент
                        </button>
                    </div>
                </div>
            </app-panel>

            <div *ngIf="showMyContainer" class="cls-block-pay-anime">
                <div class="button--closeWrap">
                    <span class="title" *ngIf="showMyPay"
                        >Пополнить баланс</span
                    >
                    <span class="title" *ngIf="!showMyPay"
                        >Оплатить Абонемент</span
                    >

                    <button
                        mat-icon-button
                        (click)="ShowTogglePopup()"
                        style="z-index: 99"
                        aria-label="icon-button with a menu"
                        class="cls-btn-menu cls-dev-phone cls-btn-menu--inOpenMenu"
                    >
                        <div class="svg-menu-close">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.6739 10L19.6439 17.97C20.1187 18.4447 20.1187 19.1943 19.6439 19.6439C19.1692 20.1187 18.4197 20.1187 17.97 19.6439L10 11.6739L2.03001 19.6439C1.55526 20.1187 0.805733 20.1187 0.35606 19.6439C-0.118687 19.1692 -0.118687 18.4197 0.35606 17.97L8.32605 10L0.35606 2.03001C-0.118687 1.55526 -0.118687 0.805733 0.35606 0.35606C0.830806 -0.118687 1.58034 -0.118687 2.03001 0.35606L10 8.32605L17.97 0.35606C18.4447 -0.118687 19.1943 -0.118687 19.6439 0.35606C20.1187 0.830806 20.1187 1.58034 19.6439 2.03001L11.6739 10Z"
                                    fill="#B7C9DF"
                                />
                            </svg>
                        </div>
                        <!-- {{menuTrigger.menuOpen ? 'expand_less' : 'expand_more'}} -->
                    </button>
                </div>

                <ng-container
                    *ngIf="showMyPay; else showMyAboniment"
                    [ngSwitch]="paySystemField"
                >
                    <div class="row cls-h-block paySystemField--wrap">
                        <div class="finanse-header">
                            <div class="finanse-header__item">
                                <div class="cls-balans-text">Баланс</div>
                                <div class="cls-balans-sum">
                                    {{ balans | priceSpace }}
                                    {{ balans | i18nPlural : priceMapper }}
                                </div>
                            </div>
                            <div class="finanse-header__item">
                                <button
                                    class="btn cls-btn-second cls-btn-pay"
                                    (click)="ShowTogglePopup()"
                                >
                                    Отменить
                                </button>
                            </div>
                        </div>
                    </div>

                    <ng-template ngSwitchCase="email_and_phone">
                        <mat-form-field class="w-100">
                            <mat-label>Сумма к зачислению</mat-label>
                            <input
                                matInput
                                type="number"
                                [(ngModel)]="mdBalansPay.sum"
                                required
                                class="cls-input-sum"
                            />
                            <mat-hint align="start"
                                >Только целое число</mat-hint
                            >
                        </mat-form-field>

                        <div class="row">
                            <mat-form-field class="col-md-7">
                                <mat-label>Адрес почты</mat-label>
                                <input
                                    matInput
                                    [(ngModel)]="mdBalansPay.email"
                                />
                                <mat-hint align="start"
                                    >Почта для получения чека</mat-hint
                                >
                            </mat-form-field>

                            <mat-form-field class="col-md-5">
                                <mat-label>Телефон</mat-label>
                                <input
                                    matInput
                                    placeholder="(999) 999-99-99"
                                    [(ngModel)]="mdBalansPay.phone"
                                />
                                <mat-hint align="start"
                                    >Телефон для получения чека</mat-hint
                                >
                            </mat-form-field>
                        </div>
                    </ng-template>

                    <ng-template ngSwitchCase="email">
                        <div class="row over--input">
                            <mat-form-field class="col-md-7">
                                <mat-label>Адрес почты</mat-label>
                                <input
                                    matInput
                                    [(ngModel)]="mdBalansPay.email"
                                />
                                <mat-hint align="start"
                                    >Почта для получения чека</mat-hint
                                >
                            </mat-form-field>

                            <mat-form-field
                                class="col-md-5"
                                (keydown.enter)="canselFocused2(mdAbonPay)"
                            >
                                <mat-label>Сумма к зачислению</mat-label>
                                <input
                                    matInput
                                    #inputFocus
                                    type="number"
                                    [(ngModel)]="mdBalansPay.sum"
                                    required
                                    class="cls-input-sum"
                                />
                                <mat-hint align="start"
                                    >Только целое число</mat-hint
                                >
                                <span
                                    *ngIf="mdBalansPay.sum"
                                    class="inputspan--rub"
                                    matSuffix
                                >
                                    {{
                                        mdBalansPay.sum
                                            | i18nPlural : priceMapper
                                    }}
                                </span>
                            </mat-form-field>
                        </div>
                    </ng-template>

                    <ng-template ngSwitchCase="phone">
                        <div class="row">
                            <mat-form-field class="col-md-7">
                                <mat-label>Телефон</mat-label>
                                <input
                                    matInput
                                    placeholder="+7(999) 999-99-99"
                                    [(ngModel)]="mdBalansPay.phone"
                                />
                                <mat-hint align="start">
                                    Телефон для получения чека
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field class="col-md-5">
                                <mat-label>Сумма к зачислению</mat-label>
                                <input
                                    matInput
                                    type="number"
                                    [(ngModel)]="mdBalansPay.sum"
                                    required
                                    class="cls-input-sum"
                                />
                                <mat-hint align="start"
                                    >Только целое число</mat-hint
                                >
                            </mat-form-field>
                        </div>
                    </ng-template>

                    <ng-template ngSwitchDefault>
                        <div class="w-100">
                            Не определен тип платежной системы!
                        </div>
                    </ng-template>
                </ng-container>

                <ng-template #showMyAboniment>
                    <app-panel
                        class="col-md-12 aboniment-wrapper"
                        classSrt="cls-position-relative"
                        classSrt="h-100"
                    >
                        <mat-form-field
                            class="matformfieldSelect"
                            appearance="fill"
                            style="z-index: 100"
                        >
                            <mat-select
                                (selectionChange)="changeAbon($event)"
                                #mymyselect
                                placeholder="Выберите Абонемент"
                            >
                                <div class="button--closeWrap inSelect">
                                    <span class="title" *ngIf="!showMyPay">
                                        Оплатить Абонемент
                                    </span>

                                    <button
                                        mat-icon-button
                                        (click)="ShowTogglePopup()"
                                        style="z-index: 99999999999999"
                                        aria-label="icon-button with a menu"
                                        class="cls-btn-menu cls-dev-phone cls-btn-menu--inOpenMenu"
                                    >
                                        <div class="svg-menu-close">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M11.6739 10L19.6439 17.97C20.1187 18.4447 20.1187 19.1943 19.6439 19.6439C19.1692 20.1187 18.4197 20.1187 17.97 19.6439L10 11.6739L2.03001 19.6439C1.55526 20.1187 0.805733 20.1187 0.35606 19.6439C-0.118687 19.1692 -0.118687 18.4197 0.35606 17.97L8.32605 10L0.35606 2.03001C-0.118687 1.55526 -0.118687 0.805733 0.35606 0.35606C0.830806 -0.118687 1.58034 -0.118687 2.03001 0.35606L10 8.32605L17.97 0.35606C18.4447 -0.118687 19.1943 -0.118687 19.6439 0.35606C20.1187 0.830806 20.1187 1.58034 19.6439 2.03001L11.6739 10Z"
                                                    fill="#B7C9DF"
                                                />
                                            </svg>
                                        </div>
                                        <!-- {{menuTrigger.menuOpen ? 'expand_less' : 'expand_more'}} -->
                                    </button>
                                </div>
                                <div class="options-wrapper">
                                    <mat-option
                                        *ngFor="let abonement of abonList"
                                        [value]="abonement.id"
                                    >
                                        {{ abonement.name }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                        <div
                            [@topButtonAnimation]="animStateAbonement"
                            (@topButtonAnimation.done)="
                                whenAnimateAbonement($event)
                            "
                            class="cls-block-pay-anime cls-block-pay-anime--bottom"
                        >
                            <div
                                class="container"
                                style="padding-right: 0; padding-left: 0"
                            >
                                <div class="row">
                                    <mat-form-field>
                                        <mat-label>Адрес почты</mat-label>
                                        <input
                                            matInput
                                            [(ngModel)]="mdAbonPay.email"
                                        />
                                        <mat-hint align="start">
                                            Почта для получения чека
                                        </mat-hint>
                                    </mat-form-field>

                                    <mat-form-field
                                        (keydown.enter)="
                                            canselFocused(mdAbonPay)
                                        "
                                    >
                                        <mat-label
                                            >Сумма к зачислению
                                        </mat-label>
                                        <input
                                            matInput
                                            #inputFocus2
                                            type="number"
                                            [(ngModel)]="mdAbonPay.sum"
                                            required
                                            class="cls-input-sum"
                                        />
                                        <mat-hint>
                                            Только целое число
                                        </mat-hint>
                                        <span
                                            *ngIf="mdAbonPay.sum"
                                            class="inputspan--rub"
                                            matSuffix
                                        >
                                            {{
                                                mdAbonPay.sum
                                                    | i18nPlural : priceMapper
                                            }}
                                        </span>
                                    </mat-form-field>
                                </div>
                                <div
                                    class="row cls-block-img-pay paySystemField--wrap paySystemField--wrap--bottom"
                                >
                                    <div class="imgs row">
                                        <div
                                            class="col-md-2 cards--logo"
                                            style="
                                                width: fit-content;
                                                margin-bottom: 10px;
                                            "
                                        >
                                            <img
                                                src="./assets/img/pay/mir.png"
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            class="col-md-2 cards--logo"
                                            style="
                                                width: fit-content;
                                                margin-bottom: 10px;
                                            "
                                        >
                                            <img
                                                src="./assets/img/pay/maestro.png"
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            class="col-md-2 cards--logo"
                                            style="
                                                width: fit-content;
                                                margin-bottom: 10px;
                                            "
                                        >
                                            <img
                                                src="./assets/img/pay/visa.png"
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            class="col-md-2 cards--logo"
                                            style="
                                                width: fit-content;
                                                margin-bottom: 10px;
                                            "
                                        >
                                            <img
                                                src="./assets/img/pay/mastercard.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <button
                                        class="btn col-md-4 cls-btn-second cls-btn-goPay"
                                        type="submit"
                                        (click)="goPay(mdAbonPay)"
                                        [disabled]="!this.mdAbonPay.sum"
                                    >
                                        Внести
                                    </button>
                                </div>
                            </div>
                        </div>
                    </app-panel>
                </ng-template>

                <div
                    *ngIf="showMyPay"
                    class="row cls-block-img-pay paySystemField--wrap paySystemField--wrap--bottom"
                >
                    <div class="imgs">
                        <div class="col-md-2 cards--logo">
                            <img src="./assets/img/pay/mir.png" alt="" />
                        </div>
                        <div class="col-md-2 cards--logo">
                            <img src="./assets/img/pay/maestro.png" alt="" />
                        </div>
                        <div class="col-md-2 cards--logo">
                            <img src="./assets/img/pay/visa.png" alt="" />
                        </div>
                        <div class="col-md-2 cards--logo">
                            <img src="./assets/img/pay/mastercard.png" alt="" />
                        </div>
                    </div>
                    <button
                        class="btn col-md-4 cls-btn-second cls-btn-goPay"
                        type="submit"
                        (click)="goPay(mdBalansPay)"
                    >
                        Внести
                    </button>
                </div>
            </div>
        </div>

        <!-- <span class="grey--title"> Список платежей </span> -->

        <div class="payments-page__body">
            <app-mobile-feed-page-template
                [count-of-pages]="countOfPages"
                [loaded-pages]="loadedPages"
                [is-loading]="loadingMoreContent"
                [root-url]="'home/payments'"
                [view-page]="viewPage"
                [pages]="this.payPages"
                [no-more-content-text]="'Платежей больше нет.'"
                [is-feed-empty]="servicesListLength === 0"
                [feed-empty-text]="
                    'На выбранные дни финансовых операций или услуг на оплату нет'
                "
                (load-next-page)="loadNextPage()"
                class="app-payments-feed-page-template"
                *ngIf="!isLoading; else loader"
            >
                <feed-header-period
                    header
                    [periodText]="periodText"
                    (onClick)="showPeriodDialog()"
                    [canBeClear]="periodText != 'Платежи за всё время'"
                    (onClear)="btnFilterByDate(null, null)"
                ></feed-header-period>

                <section
                    page
                    [id]="'page' + (pageIndex + 1)"
                    class="services-mobile__page payments-mobile__page"
                    *ngFor="let payPage of payPages; let pageIndex = index"
                >
                    <app-feed-page-anchor
                        class="finance-page--anchor"
                        [is-hidden]="pageIndex === 0"
                        (intersected)="viewPage = pageIndex + 1"
                    >
                        {{ pageIndex + 1 }} из {{ countOfPages }}
                    </app-feed-page-anchor>

                    <ng-container *ngIf="payPage === null">
                        <div class="no--operation">
                            Здесь появятся финансовые<br />
                            операции и услуги на оплату
                        </div>
                    </ng-container>

                    <ng-container *ngIf="payPage != null">
                        <div
                            class="card--pay"
                            *ngFor="let pay of payPage; let i = index"
                        >
                            <ng-container>
                                <div
                                    class="finanse-content__year"
                                    [class.hidden]="i < 1"
                                    (intersected)="viewPage = i + 1"
                                    *ngIf="isBlockYeer(i)"
                                >
                                    <span>{{ getYeerPc(i) }}</span>
                                </div>
                            </ng-container>

                            <div
                                class="finance-content__item"
                                (click)="showDetailsDialog(pay)"
                            >
                                <div class="finance-content__item-head">
                                    <div class="day">
                                        {{ getNameDay(pay.dtSort) }}
                                    </div>
                                    <div
                                        class="cls-table-td-event"
                                        *ngIf="pay.pt_dat_str"
                                    >
                                        {{ getTekDay(pay.dtSort) }} в
                                        {{ getTime(pay.dtSort) }}
                                    </div>
                                </div>
                                <div class="finance-content__item-title">
                                    {{ pay.pay_type }}
                                </div>
                                <div class="finance-content__item-bottom">
                                    <div>
                                        <div
                                            class="line-limit-length"
                                            *ngIf="!isPay(pay) && !isRefund"
                                        >
                                            <span
                                                *ngIf="pay.services.length > 1"
                                            >
                                                {{ pay.services.length }}
                                                {{
                                                    pay.services.length
                                                        | i18nPlural
                                                            : servicesMapper
                                                }}
                                            </span>
                                            <span
                                                *ngIf="pay.services.length == 1"
                                            >
                                                {{ pay.services[0].name }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="price">
                                        <div
                                            *ngIf="pay.paystatus == 1"
                                            class="finance-content__item-price"
                                        >
                                            {{
                                                pay.all_amount
                                                    | priceSpace : ".2"
                                            }}
                                            рублей
                                        </div>
                                        <div
                                            *ngIf="
                                                pay.paystatus != 1 &&
                                                pay.pay_card != 0 &&
                                                pay.confirmation_url != null
                                            "
                                            class="finance-content__item-price finance-content__item-price-nopay"
                                        >
                                            {{
                                                pay.pay_card | priceSpace : ".2"
                                            }}
                                            рублей
                                        </div>
                                        <div
                                            *ngIf="
                                                pay.paystatus != 1 &&
                                                pay.pay_card != 0 &&
                                                pay.confirmation_url == null
                                            "
                                            class="finance-content__item-price finance-content__item-price-nopay finance-content__item-price--grey"
                                        >
                                            {{
                                                pay.pay_card | priceSpace : ".2"
                                            }}
                                            рублей
                                        </div>
                                        <div
                                            *ngIf="
                                                pay.paystatus != 1 &&
                                                pay.pay_card == 0
                                            "
                                            class="finance-content__item-price finance-content__item-price-nopay"
                                        >
                                            {{
                                                pay.all_amount
                                                    | priceSpace : ".2"
                                            }}
                                            рублей
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div
                                *ngIf="isPay(pay) && isNotRefundPayment(pay)"
                                class="finance-content__item-abs"
                            > -->
                            <!-- <button
                                    (click)="toPay(pay)"
                                    class="finance-content__item-btnPay"
                                >
                                    <mat-icon svgIcon="creditCard"></mat-icon>
                                </button> -->
                            <!-- </div> -->
                            <div class="finance-content__item-abs">
                                <button
                                    (click)="toPay(pay)"
                                    class="finance-content__item-btnPay"
                                    *ngIf="
                                        isPay(pay) && isNotRefundPayment(pay)
                                    "
                                >
                                    <mat-icon svgIcon="creditCard"></mat-icon>
                                </button>
                                <button
                                    (click)="toRefund(pay)"
                                    class="finance-content__item-btnRefund"
                                    *ngIf="pay | isRefund"
                                >
                                    <mat-icon
                                        svgIcon="creditCard"
                                        style="fill: #ffffff"
                                    ></mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </section>

                <div fab class="calendar-btn" (click)="showPeriodDialog()">
                    <mat-icon svgIcon="calendar"></mat-icon>
                </div>
            </app-mobile-feed-page-template>
        </div>
    </div>

    <ng-template #loader>
        <div class="loader">
            <loader type="page" message="Загрузка списка платежей"> </loader>
        </div>
    </ng-template>
</div>

<div class="wide">
    <div
        class="payments-page"
        [ngClass]="{ 'payments-page_with-info': showAbonement || showDeposit }"
    >
        <div class="cls-block-header payments-page__header">
            <span>Финансы</span>
            <button
                class="btn cls-btn-second cls-btn-filter"
                (click)="btnFiltrTek()"
            >
                за сегодня
            </button>
            <button
                class="btn cls-btn-second cls-btn-filter"
                (click)="btnFiltr3Day()"
            >
                за 3 дня
            </button>
        </div>

        <ng-container *ngIf="!loadingPayAvans; else payLoader">
            <div
                class="payments-page__info"
                *ngIf="showAbonement || showDeposit"
                [ngClass]="{
                    'payments-page__info_with-abonement': paySystem !== null
                }"
            >
                <div
                    class="payments-page__deposit deposit panel"
                    *ngIf="showDeposit"
                >
                    <div class="deposit__wrapper">
                        <div class="deposit__info">
                            <div class="deposit__title">
                                {{ WEB_LK_FIN_BALANS_TXT }}
                            </div>
                            <div class="deposit__balans">
                                {{ balans | priceSpace }} рублей
                            </div>
                        </div>
                        <button
                            class="btn cls-btn-second payments-page__button deposit__button"
                            type="submit"
                            (click)="showPayPc()"
                            *ngIf="showBalance"
                        >
                            <mat-icon
                                *ngIf="!isShowAvans"
                                svgIcon="arrow_bottom"
                                class="cls-btn-icon"
                            ></mat-icon>
                            <mat-icon
                                *ngIf="isShowAvans"
                                svgIcon="arrow_top"
                                class="cls-btn-icon"
                            ></mat-icon>
                            Пополнить баланс
                        </button>
                    </div>

                    <div @onIfAnimation *ngIf="isShowAvans" class="animation">
                        <ng-container [ngSwitch]="paySystemField">
                            <ng-template ngSwitchCase="email_and_phone">
                                <mat-form-field class="w-100">
                                    <mat-label>Сумма к зачислению</mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        [(ngModel)]="mdBalansPay.sum"
                                        required
                                        class="cls-input-sum"
                                    />
                                    <mat-hint align="start"
                                        >Только целое число</mat-hint
                                    >
                                </mat-form-field>

                                <div class="row">
                                    <mat-form-field class="col-md-7">
                                        <mat-label>Адрес почты</mat-label>
                                        <input
                                            matInput
                                            placeholder="guest@mail.ru"
                                            [(ngModel)]="mdBalansPay.email"
                                        />
                                        <mat-hint align="start"
                                            >Почта для получения чека</mat-hint
                                        >
                                    </mat-form-field>

                                    <mat-form-field class="col-md-5">
                                        <mat-label>Телефон</mat-label>
                                        <input
                                            matInput
                                            placeholder="(999) 999-99-99"
                                            [(ngModel)]="mdBalansPay.phone"
                                        />
                                        <mat-hint align="start"
                                            >Телефон для получения
                                            чека</mat-hint
                                        >
                                    </mat-form-field>
                                </div>
                            </ng-template>

                            <ng-template ngSwitchCase="email">
                                <div class="row">
                                    <mat-form-field class="col-md-7">
                                        <mat-label>Адрес почты</mat-label>
                                        <input
                                            matInput
                                            [(ngModel)]="mdBalansPay.email"
                                        />
                                        <mat-hint align="start"
                                            >Почта для получения чека</mat-hint
                                        >
                                    </mat-form-field>

                                    <mat-form-field class="col-md-5">
                                        <mat-label
                                            >Сумма к зачислению</mat-label
                                        >
                                        <input
                                            matInput
                                            type="number"
                                            [(ngModel)]="mdBalansPay.sum"
                                            required
                                            class="cls-input-sum"
                                        />
                                        <mat-hint align="start"
                                            >Только целое число</mat-hint
                                        >
                                    </mat-form-field>
                                </div>
                            </ng-template>

                            <ng-template ngSwitchCase="phone">
                                <div class="row">
                                    <mat-form-field class="col-md-7">
                                        <mat-label>Телефон</mat-label>
                                        <input
                                            matInput
                                            placeholder="+7(999) 999-99-99"
                                            [(ngModel)]="mdBalansPay.phone"
                                        />
                                        <mat-hint align="start"
                                            >Телефон для получения
                                            чека</mat-hint
                                        >
                                    </mat-form-field>

                                    <mat-form-field class="col-md-5">
                                        <mat-label
                                            >Сумма к зачислению</mat-label
                                        >
                                        <input
                                            matInput
                                            type="number"
                                            [(ngModel)]="mdBalansPay.sum"
                                            required
                                            class="cls-input-sum"
                                        />
                                        <mat-hint align="start"
                                            >Только целое число</mat-hint
                                        >
                                    </mat-form-field>
                                </div>
                            </ng-template>

                            <ng-template ngSwitchDefault>
                                <div class="w-100">
                                    Не определен тип платежной системы!
                                </div>
                            </ng-template>
                        </ng-container>

                        <div class="new-payment-form">
                            <div class="new-payment-form__img">
                                <img src="./assets/img/pay/mir.png" alt="Мир" />
                            </div>
                            <div class="new-payment-form__img">
                                <img
                                    src="./assets/img/pay/maestro.png"
                                    alt="Maestro"
                                />
                            </div>

                            <button
                                class="btn cls-btn-second new-payment-form__btn"
                                type="submit"
                                (click)="goPay(mdBalansPay)"
                            >
                                Внести
                            </button>

                            <div class="new-payment-form__img">
                                <img
                                    src="./assets/img/pay/mastercard.png"
                                    alt="mastercard"
                                />
                            </div>
                            <div class="new-payment-form__img">
                                <img
                                    src="./assets/img/pay/visa.png"
                                    alt="Visa"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="payments-page__abonement panel"
                    *ngIf="paySystem != null && showAbonement"
                >
                    <div class="abonement">
                        <mat-form-field
                            class="abonement__select"
                            appearance="fill"
                        >
                            <mat-label>Выберите Абонемент</mat-label>
                            <mat-select
                                (selectionChange)="changeAbonPc($event)"
                            >
                                <mat-option
                                    *ngFor="let abonement of abonList"
                                    [value]="abonement.id"
                                >
                                    {{ abonement.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button
                            class="btn cls-btn-second payments-page__button abonement__button"
                            type="submit"
                            (click)="showPayAbonementPc()"
                            [disabled]="!this.mdAbonPay.abon_id"
                        >
                            <mat-icon
                                *ngIf="!isShowAbonement"
                                svgIcon="arrow_bottom"
                                class="cls-btn-icon"
                            ></mat-icon>
                            <mat-icon
                                *ngIf="isShowAbonement"
                                svgIcon="arrow_top"
                                class="cls-btn-icon"
                            ></mat-icon>
                            Оплатить абонемент
                        </button>
                    </div>

                    <div
                        @onIfAnimation
                        *ngIf="isShowAbonement"
                        class="animation"
                    >
                        <div
                            class="container"
                            style="padding-right: 0; padding-left: 0"
                        >
                            <div class="row">
                                <mat-form-field class="col-md-5">
                                    <mat-label>Сумма к зачислению</mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        [(ngModel)]="mdAbonPay.sum"
                                        required
                                        class="cls-input-sum"
                                    />
                                </mat-form-field>

                                <mat-form-field class="col-md-7">
                                    <mat-label>Адрес почты</mat-label>
                                    <input
                                        matInput
                                        [(ngModel)]="mdAbonPay.email"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="new-payment-form">
                                <div class="new-payment-form__img">
                                    <img
                                        src="./assets/img/pay/mir.png"
                                        alt="Мир"
                                    />
                                </div>
                                <div class="new-payment-form__img">
                                    <img
                                        src="./assets/img/pay/maestro.png"
                                        alt="Maestro"
                                    />
                                </div>

                                <button
                                    class="btn cls-btn-second new-payment-form__btn"
                                    type="submit"
                                    (click)="goPayPc(mdAbonPay)"
                                    [disabled]="!this.mdAbonPay.sum"
                                >
                                    Внести
                                </button>

                                <div class="new-payment-form__img">
                                    <img
                                        src="./assets/img/pay/mastercard.png"
                                        alt="mastercard"
                                    />
                                </div>
                                <div class="new-payment-form__img">
                                    <img
                                        src="./assets/img/pay/visa.png"
                                        alt="Visa"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div
            *ngIf="!loadingPaymentsList; else listLoader"
            class="payments-page__list panel"
        >
            <app-panel-table-pagination
                nameBlock="Список платежей"
                (onChangePeriod)="changePeriodPc($event)"
                [page]="page"
                [dtBegin]="dtBegins"
                [dtEnd]="dtEnds"
                textItogo="платежей"
                [CountRec]="paymentsListLength"
                [CountRecPage]="countRectoPage"
                (onChangedPage)="changedPage($event)"
                [ngClass]="{ opacity0_5: isShowAbonement }"
            >
                <ng-template
                    *ngIf="paymentsListLength > 0; else showNotRec"
                    [ngTemplateOutlet]="showTable"
                ></ng-template>

                <ng-template #showNotRec>
                    <div class="cls-not-rec">Нет платежей</div>
                </ng-template>

                <ng-template #showTable>
                    <!-- TODO переписать на grid style-->
                    <table
                        class="table table-borderless table-hover"
                        style="height: 95%; display: block; overflow: auto"
                    >
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    class="cls-header-table cls-header-table-dt"
                                    style="width: 10%"
                                >
                                    Дата платежа
                                </th>
                                <th
                                    scope="col"
                                    class="cls-header-table"
                                    style="width: 20%"
                                >
                                    Тип
                                </th>
                                <th
                                    scope="col"
                                    class="cls-header-table"
                                    style="width: 13%"
                                >
                                    Дни оказания услуг
                                </th>
                                <th
                                    scope="col"
                                    class="cls-header-table"
                                    style="width: 40%"
                                >
                                    Платеж включает
                                </th>
                                <!--<th scope="col" class="cls-header-table" style="width: 23%;">Врач</th>-->
                                <th
                                    scope="col"
                                    class="cls-header-table cls-table-td-price"
                                    style="width: 10%"
                                >
                                    Всего к оплате
                                </th>
                                <th
                                    scope="col"
                                    class="cls-header-table"
                                    style="width: 10%; min-width: 125px"
                                ></th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container
                                *ngFor="
                                    let pay of paymentsList;
                                    let iPay = index
                                "
                            >
                                <tr *ngIf="isBlockYeerPc(iPay)">
                                    <td
                                        class="cls-table-td-event"
                                        colspan="7"
                                        class="cls-td-yeer"
                                    >
                                        {{ getYeer(iPay) }}
                                    </td>
                                </tr>

                                <tr
                                    [ngClass]="{
                                        'cls-bg-services':
                                            animateStateServices[iPay] == 'on'
                                    }"
                                >
                                    <td class="cls-table-td-event">
                                        {{ getTekDayPc(pay.dtSort) }} в
                                        {{ getTimePc(pay.dtSort) }}
                                        <div style="color: #c5c5c5">
                                            {{ getNameDayPc(pay.dtSort) }}
                                        </div>
                                    </td>
                                    <td class="cls-table-td-event">
                                        {{ pay.pay_type }}
                                    </td>

                                    <ng-container
                                        *ngIf="
                                            pay.services.length > 1;
                                            then showServices;
                                            else showNotServices
                                        "
                                    ></ng-container>
                                    <ng-template #showServices>
                                        <!--Дата услуг-->
                                        <td
                                            class="cls-table-td-event"
                                            *ngIf="pay.services[0].dt"
                                        >
                                            <span>{{
                                                getPeriodServicesStartEnd(
                                                    pay.services
                                                )
                                            }}</span>
                                            <span>
                                                -
                                                {{
                                                    getPeriodServicesStartEnd(
                                                        pay.services,
                                                        false
                                                    )
                                                }}</span
                                            >
                                        </td>
                                        <!--Наименование услуг-->
                                        <td class="cls-table-td-event">
                                            <button
                                                *ngIf="pay.services.length > 1"
                                                class="btn cls-btn-second cls-btn-filter cls-button-n-services"
                                                style="margin: 0px"
                                                (click)="
                                                    showHideListservicesPc(iPay)
                                                "
                                            >
                                                {{ pay.services.length }}
                                                {{
                                                    getStrEndServ(
                                                        pay.services.length
                                                    )
                                                }}
                                            </button>
                                        </td>
                                    </ng-template>

                                    <ng-template #showNotServices>
                                        <!--Дата услуг-->
                                        <td class="cls-table-td-event">
                                            {{ getTekDay(pay.services[0].dt) }}
                                            в
                                            {{ getTime(pay.services[0].dt) }}
                                        </td>
                                        <!--Наименование услуг-->
                                        <td class="cls-table-td-event">
                                            {{ pay.services[0].name }}
                                            <span
                                                *ngIf="
                                                    pay.services[0].spec_name
                                                "
                                                style="margin-right: 5px"
                                                >-
                                                {{
                                                    pay.services[0].spec_name
                                                }}</span
                                            >
                                            <span *ngIf="pay.services[0].doc"
                                                >(
                                                {{ pay.services[0].doc }}
                                                )</span
                                            >
                                        </td>
                                        <td
                                            class="cls-table-td-event"
                                            *ngIf="pay.services.length == 0"
                                        >
                                            НЕТ
                                        </td>
                                    </ng-template>

                                    <td
                                        class="cls-table-td-event cls-table-td-price"
                                        [ngClass]="{
                                            'cls-table-td-priceToPay':
                                                isPayPc(pay)
                                        }"
                                        (click)="actionClick(pay)"
                                    >
                                        {{ pay.all_amount | priceSpace : ".2" }}
                                        руб
                                    </td>

                                    <td
                                        class="cls-table-td-event cls-td-to-pay"
                                        *ngIf="isNotRefundPayment(pay)"
                                        (click)="actionClick(pay)"
                                    >
                                        <div *ngIf="isPay(pay)">
                                            <mat-icon
                                                svgIcon="pay"
                                                class="cls-btn-icon cls-btn-icon-pay"
                                                style="fill: #3ad0ff"
                                            ></mat-icon>
                                            Оплатить
                                        </div>
                                        <button
                                            class="refund"
                                            *ngIf="pay | isRefund"
                                        >
                                            <mat-icon
                                                svgIcon="refresh-single"
                                                class="refund__icon"
                                            ></mat-icon>
                                            Вернуть
                                        </button>
                                    </td>
                                </tr>

                                <ng-container
                                    *ngIf="animateStateServices[iPay] == 'on'"
                                >
                                    <tr
                                        class="cls-bg-services"
                                        [@heightAnimation]="
                                            animateStateServices[iPay]
                                        "
                                        *ngFor="
                                            let ps of pay.services;
                                            let is = index
                                        "
                                        [ngClass]="{
                                            'tr-serv-end':
                                                is == pay.services.length - 1
                                        }"
                                    >
                                        <td class="cls-table-td-event"></td>
                                        <td class="cls-table-td-event"></td>
                                        <td class="cls-table-td-event">
                                            {{ getTekDay(ps.dt) }} в
                                            {{ getTime(ps.dt) }}
                                        </td>
                                        <td class="cls-table-td-event">
                                            {{ ps.name
                                            }}<span
                                                *ngIf="ps.spec_name"
                                                style="margin-right: 5px"
                                                >- {{ ps.spec_name }}</span
                                            >
                                            <span *ngIf="ps.doc"
                                                >( {{ ps.doc }} )</span
                                            >
                                        </td>
                                        <td
                                            class="cls-table-td-event cls-table-td-ser-price"
                                        >
                                            {{ ps.price | priceSpace : ".2" }}
                                            руб
                                        </td>
                                        <td class="cls-table-td-event"></td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-template>
            </app-panel-table-pagination>
        </div>
    </div>

    <ng-template #listLoader>
        <div class="loader panel">
            <loader
                type="page"
                [isFullscreen]="true"
                message="Загрузка списка платежей..."
            ></loader>
        </div>
    </ng-template>

    <ng-template #payLoader>
        <div class="loader panel">
            <loader
                type="page"
                [isFullscreen]="true"
                message="Сейчас вы будете перенаправлены в платежную систему"
            ></loader>
        </div>
    </ng-template>
</div>
