import { Component, OnInit } from '@angular/core';
import { Size } from '../../services/size';
import { IDateRange } from 'src/app/interfaces/date-range.interface';
import { ServicesService } from 'src/app/services/services.service';
import { LoadService } from 'src/app/services/application/load.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/lib/ariadna/alert';
import * as moment from 'moment';
import { IService } from 'src/app/interfaces/services';
import { ServicesDetailsDialogComponent } from './dialogs/services-details-dialog/services-details-dialog.component';
import { PayPeriodDialogComponent } from '../payments/dialogs/pay-period-dialog/pay-period-dialog.component';

import {
    getTekDay,
    getNameDay,
    getTime,
    getDayWithYear,
} from '../application/global.function';
import { IPeriod } from 'src/app/interfaces/period.interface';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';
    loadedPages = 1;
    viewPage = 1;
    periodText = '';
    date: IDateRange;
    countOfPages = 1;
    servicesPages: Array<IService[]> = [];
    servicesListLength: number = 0; // Количество записей
    countRecToPage = 15; // Количетсво записей на странице
    loadingMoreContent = false;
    isLoading = false;
    private _setting: any;

    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUPTextPC: string = 'Сначала последние';
    page = 1;
    width = 0;
    servicesList: IService[] | null = null;
    countRectoPage = 5; // Количетсво записей на странице
    loadingServicesList = false; // Загрузка

    constructor(
        private ss: ServicesService,
        private size: Size,
        private load: LoadService,
        private title: Title,
        private dialog: MatDialog,
        private alert: AlertService
    ) {
        this._setting = load.configData.pages.services;
        if (this._setting?.title) {
            this.title.setTitle(this._setting.title);
        }

        this.date = { dt_begin: new Date(), dt_end: new Date() };
    }

    ngOnInit(): void {
        moment.locale('ru');
        this.date.dt_begin?.setDate(this.date.dt_begin.getDate() - 31);

        this.isLoading = true;
        this.changePeriod({
            dt_begin: this.date.dt_begin,
            dt_end: this.date.dt_end,
        }).then(() => (this.isLoading = false));

        this.formatDateToString(this.date);

        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 31);
        this.dtEnd = new Date();
        this.changePeriodPC({ begin: this.dtBegin, end: this.dtEnd });
        this.width = window.innerWidth;
        this.setPeriodTextPC();
    }

    /** Подгрузка услуг следующей страницы */
    loadNextPage() {
        if (
            this.loadedPages / this.countOfPages !== 1 &&
            !this.loadingMoreContent
        ) {
            this.loadedPages++;
            this.loadMoreServices(
                this.loadedPages * this.countRecToPage -
                    this.countRecToPage +
                    1,
                this.loadedPages * this.countRecToPage
            );
        }
    }

    /** Изменение периода для вывода записей*/
    async changePeriod(dt: IDateRange) {
        this.loadingMoreContent = true;
        this.date = dt;
        this.servicesPages = [];
        await this.getServicesSize();
        this.formatDateToString(this.date);
        this.periodText = this.getPeriodText(this.date);
        this.loadedPages = 1;
        this.viewPage = 1;
        this.loadingMoreContent = false;
    }

    /** Получение количества услуг */
    async getServicesSize() {
        try {
            const res = await lastValueFrom(
                this.ss.getServicesSize(this.date.dt_begin, this.date.dt_end)
            );

            if (res.size) {
                this.servicesListLength = res.size;
                await this.loadMoreServices(1, this.countRecToPage);
                this.setCountOfPages();
            } else {
                this.servicesListLength = 0;
            }
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка услуг');
        }
    }

    /** Подгрузка дополнительного кал-ва услуг */
    async loadMoreServices(pStart?: number, pEnd?: number) {
        const order = this.orderUP ? 'desc' : 'acs';

        try {
            const res = await lastValueFrom(
                this.ss.getServices(
                    this.date.dt_begin,
                    this.date.dt_end,
                    pStart,
                    pEnd,
                    order
                )
            );

            res.forEach((item) => {
                item.dtSort = new Date(
                    item.ps_dat.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')
                );
            });

            if (res) {
                this.servicesPages.push(res);
            }
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка услуг');
        }
    }

    /**
     * Фильтрация записей по дате
     * @param {number | null} startOffset Сдвиг даты С
     * @param {number | null} endOffset Сдвиг даты ПО
     */
    btnFilterByDay(
        startOffset: number | null = 0,
        endOffset: number | null = 0
    ) {
        if (startOffset !== null && endOffset !== null) {
            this.date.dt_begin = new Date();
            this.date.dt_begin.setDate(
                this.date.dt_begin.getDate() - startOffset
            );
            this.date.dt_end = new Date();
            this.date.dt_end.setDate(this.date.dt_end.getDate() - endOffset);
            this.changePeriod({
                dt_begin: this.date.dt_begin,
                dt_end: this.date.dt_end,
            });
        } else {
            this.changePeriod({
                dt_begin: new Date(2000, 0, 1),
                dt_end: new Date(),
            });
        }
    }

    /** Получение дня недели */
    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    /** Получение времени формата "D MM YYYY" */
    getDayWithYear(dtSort: Date) {
        return getDayWithYear(dtSort);
    }

    /** Получение времени формата "HH:mm" */
    getTime(dt: Date): string {
        return getTime(dt);
    }

    /** Получение периода текстом в формате "D MMM YYYY"*/
    formatDateToString(dt: IDateRange) {
        if (dt.dt_begin) {
            dt.str_begin = moment(new Date(dt.dt_begin)).format('D MMM YYYY');
        }
        if (dt.dt_end) {
            dt.str_end = moment(new Date(dt.dt_end)).format('D MMM YYYY');
        }
    }

    /** Установка количества страниц*/
    setCountOfPages() {
        this.countOfPages = Math.ceil(
            this.servicesListLength / this.countRecToPage
        );
    }

    /** Вызов диалога для выбора периода */
    showPeriodDialog() {
        const periodDialog = this.dialog.open(PayPeriodDialogComponent, {
            maxWidth: '650px',
            width: '100%',
            height: '100%',
            data: {
                dateRange: {
                    dt_begin: this.date.dt_begin,
                    dt_end: this.date.dt_end,
                } as IDateRange,
            },
        });

        periodDialog.afterClosed().subscribe((result) => {
            if (result?.date) {
                this.isLoading = true;
                this.changePeriod(result.date).then(
                    () => (this.isLoading = false)
                );
            }
        });
    }

    /** Вызов диалога с деталями услуги */
    showDetailsDialog(serv: IService) {
        const detailsDialog = this.dialog.open(ServicesDetailsDialogComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: 'no-border-radius',
            data: {
                service: serv,
            },
        });

        detailsDialog.afterClosed().subscribe((result) => {});
    }

    /** Полученеи текста из периода */
    getPeriodText(dt: IDateRange) {
        let periodText = '';
        let isSelectedOneDay = dt.str_begin === dt.str_end;

        if (isSelectedOneDay) {
            if (dt.dt_begin == null && dt.dt_end == null)
                periodText = 'Услуги за всё время';
            else if (dt.dt_begin?.toDateString() === new Date().toDateString())
                periodText = 'Последние услуги';
            else if (dt.str_begin) periodText = dt.str_begin;
        } else if (dt.str_begin && dt.str_end) {
            periodText = `${dt.str_begin} - ${dt.str_end}`;
        } else if (dt.str_begin) {
            periodText = dt.str_begin;
        } else if (dt.str_end) {
            periodText = dt.str_end;
        }
        return periodText;
    }

    /******************************************* PC ***********************************/

    changePeriodPC(dt: IPeriod) {
        //
        this.dtBegin = dt.begin;
        this.dtEnd = dt.end;
        this.getServicesSizePC();
        this.setPeriodTextPC();
    }

    // Количество записей
    getServicesSizePC() {
        this.loadingServicesList = true;
        this.servicesList = null;
        this.ss.getServicesSize(this.dtBegin, this.dtEnd).subscribe(
            (res) => {
                if (res.size) {
                    this.servicesListLength = res.size;
                    this.getServicesPC(1, this.countRectoPage);
                    this.setCountOfPagesPC();
                } else {
                    this.servicesListLength = 0;
                    this.loadingServicesList = false;
                }
            },
            (err) => {
                console.error('Error=', err);
                this.loadingServicesList = false;
            }
        );
    }

    // TODO: Сделать подгрузку записей при помощи Lazy-loading
    getServicesPC(pStart?: number, pEnd?: number) {
        this.loadingServicesList = true;
        this.servicesList = null;
        let order = 'acs';
        if (this.orderUP) {
            order = 'desc';
        } else {
            order = 'acs';
        }
        this.ss
            .getServices(this.dtBegin, this.dtEnd, pStart, pEnd, order)
            .subscribe(
                (res) => {
                    this.servicesList = res;
                    this.servicesList.forEach((item) => {
                        item.dtSort = new Date(
                            item.ps_dat.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')
                        );
                    });
                    this.loadingServicesList = false;
                },
                (err) => {
                    console.error('Error=', err);
                    this.loadingServicesList = false;
                }
            );
    }

    // За сегодня
    btnFilterTekPC() {
        this.dtBegin = new Date();
        this.dtEnd = new Date();
        this.changePeriodPC({ begin: this.dtBegin, end: this.dtEnd });
    }

    // За 3 дня
    btnFilter3DayPC() {
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 3);
        this.dtEnd = new Date();
        this.changePeriodPC({ begin: this.dtBegin, end: this.dtEnd });
    }

    // С начала последние
    btnFilterOrderUpPC() {
        if (this.servicesList && this.servicesList.length > 0) {
            this.orderUP = !this.orderUP;
            if (this.orderUP) {
                this.orderUPText = 'Сначала последние';
                //        this.sortUp();
                this.getServicesPC(1, this.countRectoPage);
            } else {
                this.orderUPText = 'Сначала первые';
                this.getServicesPC(1, this.countRectoPage);
                //        this.sortAp()
            }
        }
    }

    isBlockYearPC(index: number): boolean {
        let f = false;
        if (
            this.servicesList &&
            this.servicesList[index] &&
            this.servicesList[index - 1]
        ) {
            if (
                this.servicesList[index].dtSort.getFullYear() !==
                this.servicesList[index - 1].dtSort.getFullYear()
            ) {
                f = true;
            }
        }
        if (index === 0) {
            f = true;
        }
        return f;
    }

    getYearPC(index: number): string {
        if (this.servicesList && this.servicesList[index]) {
            return this.servicesList[index].dtSort.getFullYear() + '';
        } else {
            return '';
        }
    }

    getDateTimeFullPC(dt: Date): string {
        let tmp = '';
        //
        moment.locale('ru');
        tmp =
            moment(dt).format('D MMM YYYY') +
            ' в ' +
            moment(dt).format('HH:mm');
        return tmp;
    }

    getTekDayPC(dt: Date): string {
        return getTekDay(dt);
    }

    getNameDayPC(dt: Date): string {
        return getNameDay(dt);
    }

    getTimePC(dt: Date): string {
        return getTime(dt);
    }

    /* Событие выбора страницы */
    changedPagePC(page: any) {
        //
        if (page === 1) {
            this.getServicesPC(1, this.countRectoPage);
        } else {
            this.getServicesPC(
                page * this.countRectoPage - this.countRectoPage + 1,
                page * this.countRectoPage
            );
        }
    }

    nextPagePC() {
        this.page++;
        if (this.page === 1) {
            this.getServicesPC(1, this.countRectoPage);
        } else {
            this.getServicesPC(
                this.page * this.countRectoPage - this.countRectoPage + 1,
                this.page * this.countRectoPage
            );
        }
    }

    prevPagePC() {
        this.page--;
        if (this.page === 1) {
            this.getServicesPC(1, this.countRectoPage);
        } else {
            this.getServicesPC(
                this.page * this.countRectoPage - this.countRectoPage + 1,
                this.page * this.countRectoPage
            );
        }
    }

    // Получение периода текстом
    setPeriodTextPC() {
        let strBegin = '';
        let strEnd = '';
        if (this.dtBegin) {
            strBegin = moment(new Date(this.dtBegin)).format('D MMM YYYY');
        }
        if (this.dtEnd) {
            strEnd = moment(new Date(this.dtEnd)).format('D MMM YYYY');
        }

        if (strBegin && strEnd) {
            this.periodText = `${strBegin} - ${strEnd}`;
        } else if (strBegin) {
            this.periodText = strBegin;
        } else if (strEnd) {
            this.periodText = strEnd;
        } else {
            this.periodText = 'Последние услуги';
        }
    }

    setCountOfPagesPC() {
        this.countOfPages = Math.ceil(
            this.servicesListLength / this.countRectoPage
        );
    }
}
