<div *ngIf="loading" class="loading">
    <loader type="page"> </loader>
    <!-- <img src="./assets/loading-rec.gif" /> -->
</div>

<div *ngIf="!loading" class="talonpicker">
    <button class="btn btn-dialog-close" (click)="closeCalendar()">
        <img src="./assets/img/svg/cross.svg" alt="Закрыть" />
    </button>
    <mat-select
        [(value)]="selectedDep"
        placeholder="Выберите адрес"
        class="dep__select talonpicker__dep-select"
    >
        <mat-option
            [value]="null"
            (click)="selectDep(null)"
            class="dep-select__option"
            >Все</mat-option
        >
        <mat-option
            *ngFor="let dep of depList"
            [value]="dep.keyid"
            (click)="selectDep(dep)"
            class="dep-select__option"
        >
            {{ dep.text }}
        </mat-option>
    </mat-select>

    <mat-calendar
        *ngIf="talonListAll.length !== 0"
        class="calendar talonpicker__calendar"
        #calendar
        [dateFilter]="dateFilter"
        [(selected)]="selectedDay"
        (selectedChange)="selectDay($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [headerComponent]="header"
    ></mat-calendar>

    <div class="record talonpicker__record">
        <div class="record-empty" *ngIf="talonListFiltered.length === 0">
            Нет доступных номерков
        </div>
        <div
            class="record__title"
            *ngIf="
                !(
                    talonListFiltered.length !== 0 &&
                    talonListFiltered[0].rnumbid === null &&
                    talonListFiltered[0].interval_id === null
                )
            "
        >
            Свободно на <br />
            <span class="record__title-date"
                >{{ selectedDay?.locale("ru")?.format("DD MMMM YYYY") }}
            </span>
        </div>
        <div
            class="record__title"
            *ngIf="
                talonListFiltered.length !== 0 &&
                talonListFiltered[0].rnumbid === null &&
                talonListFiltered[0].interval_id === null
            "
        >
            Вы записываетесь на <br />
            <span class="record__title-date">{{
                selectedDay?.locale("ru")?.format("DD MMMM YYYY")
            }}</span>
        </div>
        <!-- <div
            class="record-on-day"
            *ngIf="
                talonListFiltered.length !== 0 &&
                talonListFiltered[0].rnumbid === null &&
                talonListFiltered[0].interval_id === null
            "
        >
            <button
                class="record-on-day__btn"
                mat-flat-button
                (click)="openTalonModal($event, talonListFiltered[0])"
            >
                Продолжить
            </button>
        </div> -->

        <div
            class="talonlist record__talonlist"
            *ngIf="
                (talonListFiltered.length !== 0 &&
                    talonListFiltered[0].interval_id !== null) ||
                talonListFiltered[0].rnumbid !== null
            "
        >
            <div
                class="talon talonlist__item"
                [class.active]="talon === pickedTalon"
                *ngFor="let talon of talonListFiltered"
                (click)="clickTalon($event, talon)"
                [ngClass]="{
                    disabled: !talonDisable(talon)
                }"
            >
                {{ talon.dtBegin | date : "HH:mm" }}
            </div>
        </div>
    </div>

    <!-- <button
        class="btn btn-dialog-accept"
        (click)="openTalonModal($event, null)"
        [disabled]="!pickedTalon"
    >
     
        {{ pickedTalon ? "Выбрать" : "Выберите время" }}
    </button> -->
    <ng-container
        *ngIf="
            talonListFiltered.length !== 0 &&
            talonListFiltered[0].rnumbid === null &&
            talonListFiltered[0].interval_id === null
        "
    >
        <button
            class="btn btn-dialog-accept"
            (click)="openTalonModal($event, talonListFiltered[0])"
        >
            Продолжить
        </button>
    </ng-container>

    <ng-container
        *ngIf="
            !(
                talonListFiltered.length !== 0 &&
                talonListFiltered[0].rnumbid === null &&
                talonListFiltered[0].interval_id === null
            )
        "
    >
        <button
            class="btn btn-dialog-accept"
            (click)="openTalonModal($event, null)"
            [disabled]="!pickedTalon"
        >
            {{ pickedTalon ? "Выбрать" : "Выберите время" }}
        </button>
    </ng-container>
</div>
