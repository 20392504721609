<form [formGroup]="refundForm" class="refund" (ngSubmit)="onSend($event)">
    <div class="wrapper">
        <div class="head">
            <div class="title">Заявление на возврат</div>
            <button
                class="close"
                type="button"
                (click)="openCloseModal()"
            ></button>
        </div>
        <div *ngIf="!isLoading; else loader" class="content">
            <ng-template [ngIf]="services" [ngIfElse]="ops">
                <div class="content__wrapper">
                    <div class="field">
                        <text theme="label" [required]="true"
                            >Укажите причину возврата</text
                        >
                        <app-select
                            formControlName="reason"
                            [options]="reasons"
                            theme="flat"
                            class="input"
                        ></app-select>
                        <text-input
                            class="input"
                            placeholder="Комментарий"
                            formControlName="comment"
                        ></text-input>
                    </div>
                    <div
                        class="field"
                        *ngIf="services.length > 1; else serviceToRefund"
                    >
                        <text theme="label" [required]="true"
                            >Выберите услуги на возврат</text
                        >
                        <div class="checkbox-list">
                            <checkbox
                                *ngFor="let srv of services"
                                [text]="srv.text"
                                [note]="(srv.amount | priceSpace) + ' рублей'"
                                [value]="srv"
                                [checked]="true"
                                (onChangeEmitter)="onChangeService($event)"
                            ></checkbox>
                        </div>
                    </div>

                    <ng-template #serviceToRefund>
                        <div class="field">
                            <text theme="label">Услуга на возврат</text>
                            <div class="service" *ngFor="let srv of services">
                                <div class="service__name">{{ srv.text }}</div>
                                <div class="service__price">
                                    {{ (srv.amount | priceSpace) + " рублей" }}
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <div class="field field_with-subform">
                        <text theme="label" [required]="true"
                            >Способ возврата</text
                        >
                        <div class="chip-list">
                            <chip
                                [stratch]="true"
                                text-align="start"
                                text="Наличными деньгами"
                                hint="В случае оплаты услуг наличными"
                                [selected]="refundType === refundEnum.cash"
                                (click)="refundType = refundEnum.cash"
                            ></chip>
                            <chip
                                [stratch]="true"
                                text-align="start"
                                text="На банковскую карту"
                                hint="Подготовьте дату оплаты"
                                [selected]="refundType === refundEnum.card"
                                (click)="refundType = refundEnum.card"
                            ></chip>
                            <chip
                                [stratch]="true"
                                text-align="start"
                                text="На реквизиты"
                                hint="ИНН, БИК, Корр.счет, р/сч"
                                [selected]="refundType === refundEnum.requisits"
                                (click)="refundType = refundEnum.requisits"
                            ></chip>
                        </div>
                        <div
                            class="subform"
                            [ngClass]="{
                                'area-card': refundType === refundEnum.card,
                                'area-requisits':
                                    refundType === refundEnum.requisits
                            }"
                        >
                            <ng-template
                                *ngIf="refundType === refundEnum.card"
                                [ngTemplateOutlet]="subformCard"
                            ></ng-template>

                            <ng-template
                                *ngIf="refundType === refundEnum.requisits"
                                [ngTemplateOutlet]="subformRequisits"
                            ></ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div *ngIf="!isLoading && services" class="actions">
            <div class="actions__text">
                Сумма к возврату: {{ refundCost | priceSpace }} рублей
            </div>
            <button class="button" type="submit" [disabled]="!refundForm.valid">
                Отправить
            </button>
        </div>
    </div>
</form>

<ng-template #subformCard>
    <form [formGroup]="subform" class="subform__wrapper card">
        <div class="subform__title">Последние 4 цифры банковской карты</div>
        <text-input
            [maxLength]="4"
            [mask]="'0000'"
            formControlName="number"
            class="input"
        ></text-input>
        <mat-form-field class="input">
            <mat-label>Дата платежа</mat-label>
            <input
                type="date"
                matInput
                formControlName="date"
                required
                [max]="getMaxDate()"
                [readonly]="isLoading"
                (keydown.space)="$event.preventDefault()"
            />
            <mat-error *ngIf="subf('date')?.hasError('invalidDate')"
                >Выбрана неверная дата</mat-error
            >
        </mat-form-field>
    </form>
</ng-template>

<ng-template #subformRequisits>
    <form [formGroup]="subform" class="subform__wrapper requisits">
        <div class="subform__title">Наименование банка</div>
        <div class="form">
            <text-input formControlName="bankName" class="input"></text-input>
            <text-input
                formControlName="inn"
                class="input"
                mask="000000000099"
                placeholder="ИНН"
            ></text-input>
            <text-input
                formControlName="bik"
                class="input"
                mask="000000000"
                placeholder="БИК"
            ></text-input>
            <text-input
                formControlName="corr"
                class="input"
                mask="00000000000000000000"
                placeholder="Корр.счет"
            ></text-input>
            <text-input
                formControlName="rsc"
                class="input"
                mask="00000000000000000000"
                placeholder="Р/сч"
            ></text-input>
        </div>
    </form>
</ng-template>

<ng-template #closeModal>
    <div class="refund-modal">
        <div>
            <div class="refund-modal__title">Возврат не оформлен</div>
            <div class="refund-modal__text">
                Заполните, пожалуйста, все обязательные поля<span
                    class="refund-modal__text_red"
                    >&nbsp;*
                </span>
            </div>
            <div class="refund-modal__actions">
                <button
                    class="button button_secondary"
                    type="button"
                    (click)="close()"
                >
                    Пропустить
                </button>
                <button class="button" type="button" (click)="onCloseModal()">
                    К заявке
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ops>
    <div class="ops">Услуг нет.</div>
</ng-template>

<ng-template #loader>
    <loader type="page" color="#1A8DCC" message="Загрузка..."> </loader>
</ng-template>
