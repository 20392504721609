import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectOption } from 'src/app/interfaces/select';

@Component({
    selector: 'chip-list',
    templateUrl: 'chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements OnInit {
    @Input() options?: SelectOption[];
    @Input() label?: string;
    @Input() required = false;
    @Input() value: SelectOption[] = [];
    @Input('multi-select') multi_select = false;

    @Output() onSelectionChange = new EventEmitter<SelectOption[]>();

    constructor() {}

    ngOnInit() {}

    onSelectionChangeHendler(e: SelectOption) {
        if (!this.multi_select && this.options) {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].value === e.value) {
                    this.options[i].selected = e.selected;
                    this.onSelectionChange.emit([this.options[i]]);
                }
            }
        } else {
            const opt = this.options?.find((opt) => opt.value === e.value);
            if (opt) opt.selected = e.selected;

            this.onSelectionChange.emit(
                this.options?.filter((opt) => opt.selected)
            );
        }
    }
}
