import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SelectOption } from 'src/app/interfaces/select';
import { ProtocolControl } from 'src/app/interfaces/protocol';

@Component({
    selector: 'protocol-control',
    templateUrl: 'protocol-control.component.html',
    styleUrls: ['./protocol-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ProtocolControlComponent),
        },
    ],
})
export class ProtocolControlComponent implements OnInit, ControlValueAccessor {
    @Input() control?: ProtocolControl;
    // Устанавливает корректное значение в зависимости от типа данных
    @Input() set value(value: SelectOption | SelectOption[]) {
        this.textValue = typeof value === 'string' ? value : '';
        this.selectValue = value || undefined;
    }
    @Input() set options(options: SelectOption[] | undefined | null) {
        if (options) {
            this.selectOptions =
                this.control?.type === 'select' ||
                this.control?.type === 'multi-select'
                    ? (options as SelectOption[])
                    : [];
        }
    }
    @Input() required = false;

    onChange: any = () => {};
    selectOptions: SelectOption[] = [];

    selectValue!: any;
    textValue = '';

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    onTouched = (value: boolean) => {};
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    writeValue(value: any): void {
        // console.log(this.value);
        // this.control!.item.form_item_val_text = value;
        // this.control.
    }

    constructor() {}

    ngOnInit() {}

    onTouchHandler() {
        this.onTouched(true);
    }

    onChangeHandler(e: Event) {
        const target = e.target as HTMLInputElement;
        this.control!.item.form_item_val_text = target.value;
        this.onChange(target.value);
    }

    onListSelectionHandler(e: SelectOption) {
        const value = this.control!.item.form_item_vals?.find(
            (item) => item.id === Number(e.value)
        );
        if (value) this.control!.item.form_result_vals = [value];
        this.onChange(e);
    }

    onEnumSelectionHandler(e: SelectOption[]) {
        this.control!.item.form_result_vals = e.map((item) => {
            return { id: Number(item.value), text: item.text as string };
        });
        // console.log(this.control?.item.form_result_vals);
        this.onChange(e);
    }
}
