import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    HostListener,
} from '@angular/core';
import * as moment from 'moment';
import {
    expandedAnimation,
    rightPanelAnimation,
} from '../../animations/height.animation';
import { IPeriod } from '../../interfaces/period.interface';
import {
    IDoctor,
    IRnumbDate,
    IServ,
    ISpec,
    IStaticFilter,
    Doctor,
} from '../../interfaces/record.interface';
import { IService } from '../../interfaces/services';
import { ConfigService } from '../../services/application/config.service.';
import { RecordService } from '../../services/record.service';
import { strToDate } from '../application/global.function';
import { DoctorService } from 'src/app/services/doctor.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocInfoModalComponent } from 'src/app/modals/doc-info-modal/doc-info-modal.component';
import { lastValueFrom } from 'rxjs';
import { ServModalComponent } from 'src/app/modals/service-modal/service-modal.component';
import { Utils } from 'src/app/services/utils';

@Component({
    selector: 'app-rec-pc',
    templateUrl: './rec.component.html',
    styleUrls: ['./rec.component.scss'],
    animations: [expandedAnimation, rightPanelAnimation],
})
export class RecComponent implements OnInit {
    loadingListSpec = false; // Загрузка специальностей
    loadingListDoc = false; // Загрузка докторов

    @ViewChild('InputBlocSpec') InputBlocSpecEL: ElementRef | undefined;

    @HostListener('document:click', ['$event'])
    _specList: ISpec[] = []; // полный список
    specList: ISpec[] = []; // Отфильтрованный
    searchStr: string = ''; // Строка поиска
    searchAmine = 'off';
    panelRnumbAmine = 'off';
    showCalendar = false;
    staticFilterBTNs: IStaticFilter[] = []; // Список кнопок фильтра сверху страницы
    staticFilterSelected: any; // выбраные фильтр
    selectedDoctor: Doctor | undefined;
    selectDoc: IDoctor | null = null;
    //  selectedSpec: number | null = null;
    periodStart: Date = new Date();
    periodEnd: Date = new Date();
    talonPickerAnime = 'off';
    //  _searchList: ISearchServ[] = [];
    //  searchList: ISearchServ[] = [];
    selectedSpec: ISpec | null = null;

    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';

    recList: IService[] | null = null;
    //  recDoctors: IDocRec[] | null = null;
    recDoctors: IDoctor[] | null = null;
    // selectedSrv: IServ | null = null; // Выбранная услуга
    selectedSrv: IServ | undefined | null;
    recListLength: number = 0; // Количество записей

    loadingRecList = false; // Загрузка
    loadingSpecList = false; // Загрузка специальностей

    paramsTalonpicker!: IRnumbDate;
    showTalonPicker = false;
    //мобильный фильтр
    isDropdownOpen = false;
    isMobileFlilterOpen = false;
    activeItem: any;
    openMobileTemplate = false;

    constructor(
        private configS: ConfigService,
        private ds: DoctorService,
        private rs: RecordService,
        private modalService: NgbModal,
        private cdref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        //
        // Установка периуда
        this.periodStart = new Date();
        this.periodEnd = moment(this.periodStart).add(30, 'days').toDate();
        this.btnFiltrTek();
        this.getStaticfilterBTN();
        this.getSpecList();
        this.selectedSpec = null;
        this.selectedSrv = null;
        this.searchClick();
    }
    // setDoctorsExp() {
    //     this.recDoctors?.forEach((item, index) => {
    //         if (this.recDoctors)
    //             this.recDoctors[index].workExp =
    //                 item.info.find((value) =>
    //                     value.queston.toLowerCase().includes('cтаж')
    //                 )?.answer || '';
    //     });
    // }
    experienceText(exp: string): string {
        return Utils.experienceText(exp);
    }
    setDoctorsExpAndSpec() {
        this.recDoctors?.forEach((item, index) => {
            if (this.recDoctors) {
                this.recDoctors[index].workExp =
                    item?.info?.find((value) =>
                        value.queston.toLowerCase().includes('стаж')
                    )?.answer || '';
                this.recDoctors[index].workSpec =
                    item?.info?.find(
                        (value) =>
                            value.queston
                                .toLowerCase()
                                .includes('направление') ||
                            value.queston
                                .toLowerCase()
                                .includes('специальность') ||
                            value.queston
                                .toLowerCase()
                                .includes('специализация')
                    )?.answer || '';
            }
        });
    }

    ngAfterViewInit() {
        if (this.InputBlocSpecEL) {
            this.InputBlocSpecEL.nativeElement.focus();
        }
    }

    // Получить список специальностей
    getSpecList() {
        //    this.selectedSpec = null;
        this.loadingListSpec = true;
        this.specList = [];
        this._specList = [];
        let staticId: number | null = null;
        if (this.staticFilterSelected) {
            staticId = this.staticFilterSelected;
        }
        this.rs
            .getSpecList(this.periodStart, this.periodEnd, staticId)
            .subscribe(
                (val) => {
                    //

                    this.specList = val.sort((a, b) => {
                        const aText = a.text.toUpperCase(),
                            bText = b.text.toUpperCase();
                        if (aText < bText) {
                            return -1;
                        }
                        if (aText > bText) {
                            return 1;
                        }
                        return 0;
                    });
                    this.specList = val;
                    // this.specList = [
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    //     ...this.specList,
                    // ];
                    this._specList = this.specList;

                    this.loadingListSpec = false;
                },
                (error) => {
                    console.error(error);
                    this.loadingListSpec = false;
                }
            );
    }

    // Получить список специальностей
    getDocList(p_SpecID: number) {
        //    this.selectedSpec = p_SpecID;
        this.recDoctors = [];
        this.loadingListDoc = true;

        let staticId: number | null = null;
        if (this.staticFilterSelected && this.staticFilterSelected.keyid) {
            staticId = this.staticFilterSelected.keyid;
        }

        this.rs
            .getDocList(p_SpecID, this.periodStart, this.periodEnd, staticId)
            .subscribe(
                (val) => {
                    this.recDoctors = val;
                    this.recDoctors.forEach((item) => {
                        item.rdatemin = strToDate(item.dat_bgn);
                        item.rdatemax = strToDate(item.dat_end);
                    });
                    // this.recDoctors = [
                    //     ...this.recDoctors,
                    //     ...this.recDoctors,
                    //     ...this.recDoctors,
                    //     ...this.recDoctors,
                    // ];
                    const docInfoPromises = this.recDoctors.map((doc) =>
                        lastValueFrom(this.ds.getDocAboutInfo(doc.doctorid))
                    );
                    Promise.allSettled(docInfoPromises)
                        .then((res) => {
                            res.forEach((info, i) => {
                                if (
                                    this.recDoctors !== null &&
                                    info.status === 'fulfilled'
                                ) {
                                    this.recDoctors[i].info = info.value;
                                }
                            });
                            // this.setDoctorsExp();
                        })
                        .catch(console.log)
                        .finally(() => {
                            this.setDoctorsExpAndSpec();
                            this.loadingListDoc = false;
                        });
                },
                (error) => {
                    console.error(error);
                    this.loadingListDoc = false;
                }
            );
    }

    selectSpec(srvID: number) {
        this.selectedSpec = this.specList.filter(
            (item) => item.keyid == srvID
        )[0];
        this.searchAmine = 'off';
        this.searchStr = this.selectedSpec.text;
        this.getDocList(srvID);

        this.paramsTalonpicker = {
            doctorId: 0,
            srvId: srvID,
            periodStart: this.periodStart,
            periodEnd: this.periodEnd,
            specId: this.selectedSpec.keyid,
            srv: this.selectedSrv ? this.selectedSrv : undefined,
        };

        if (window.innerWidth < 500) {
            // this.selectedDoctor = doctor;
            this.openMobileTemplate = true;
        } else {
            this.openMobileTemplate = false;
        }
        this.talonPickerAnime = 'on';
    }

    onSearchChange(searchValue: string): void {}

    changeSearch(str: string) {
        if (str.length > 0) {
            this.specList = this._specList.filter(
                (item) =>
                    item.text.toUpperCase().indexOf(str.toUpperCase()) !== -1
            );
        } else {
            this.specList = this._specList;
        }
    }

    searchClick() {
        this.panelRnumbAmine = 'off';
        if (this.searchAmine === 'off') {
            this.searchAmine = 'on';
        } else {
            this.searchAmine = 'off';
        }

        // Если специальность не меняется
        if (this.searchAmine === 'off' && this.selectDoc) {
            if (this.selectDoc.srvlist && this.selectDoc.srvlist.length > 0) {
                if (this.selectedSrv) {
                    this.panelRnumbAmine = 'on';
                    this.showCalendar = true;
                }
            } else {
                this.panelRnumbAmine = 'on';
            }
        }
    }

    changePeriod(dt: IPeriod) {
        //
        this.dtBegin = dt.begin;
        this.dtEnd = dt.end;
        //    this.getServicesSize();
    }

    // За сегодня
    btnFiltrTek() {
        this.dtBegin = new Date();
        this.dtEnd = new Date();
        this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
    }

    // За 3 дня
    btnFiltr3Day() {
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 3);
        this.dtEnd = new Date();
        this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
    }

    getPeriodStr(dt1?: Date, dt2?: Date): string {
        let tmp = '';
        if (dt1 && dt2 && dt1.getMonth() == dt2.getMonth()) {
            // Одинаковый месяц
            tmp =
                '(' +
                moment(dt1).format('D') +
                ' - ' +
                moment(dt2).format('D MMM') +
                ')';
        } else {
            tmp =
                '(' +
                moment(dt1).format('D MMM') +
                ' - ' +
                moment(dt2).format('D MMM') +
                ')';
        }
        return tmp;
    }

    getImgSrcSpec(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/spec/${id}.png`;
    }

    getImgSrcDoc(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getImgSrcErr() {
        return `${this.configS.getValue('hostBackend')}/img/doc/not.png`;
    }

    getDocImgDefault(event: Event) {
        const elem = event.target as HTMLImageElement;
        elem.src = `${this.configS.getValue('hostBackend')}/img/doc/not.png`;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param event
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }

    /* Выбор доктора */
    selectedDoc(doc: IDoctor) {
        this.selectDoc = doc;
        this.selectedSrv = null;
        // this.RnumbDateSetParams();
    }

    /* передача параметров в номерки */
    RnumbDateSetParams() {
        this.paramsTalonpicker = {
            doctorId: this.selectDoc?.doctorid!,
            specId: this.selectedSpec?.keyid,
            periodStart: this.periodStart,
            periodEnd: this.periodEnd,
            srv: this.selectedSrv!,
            staticFilterSelected: this.staticFilterSelected!,
            infoDoc: this.selectDoc?.info,
        };

        if (
            this.selectDoc &&
            this.selectDoc.srvlist &&
            this.selectDoc.srvlist.length > 0
        ) {
            // c услугами , this.selectedSrv определяеться при выборе услуги из выпадающего списка список модалка
            if (this.selectedSrv) {
                this.panelRnumbAmine = 'on'; // Включить анимацию

                let param: IRnumbDate = {
                    doctorId: 0,
                    specId: 0,
                    periodStart: new Date(),
                    periodEnd: new Date(),
                    srv: this.selectedSrv,
                };
                param.doctorId = this.selectDoc.doctorid;
                if (this.selectedSpec) {
                    param.specId = this.selectedSpec.keyid;
                }
                param.periodStart = this.periodStart;
                param.periodEnd = this.periodEnd;
                if (this.staticFilterSelected) {
                    param.staticFilterSelected = this.staticFilterSelected;
                }
                this.rs.RnumbDateSetParams(param);
                this.showCalendar = true;
            } else {
                this.panelRnumbAmine = 'off';
            }
        } else {
            // Без услуг
            this.panelRnumbAmine = 'on'; // Включить анимацию
            let param: IRnumbDate = {
                doctorId: 0,
                specId: 0,
                periodStart: new Date(),
                periodEnd: new Date(),
            };
            // @ts-ignore
            param.doctorId = this.selectDoc.doctorid;
            if (this.selectedSpec) {
                param.specId = this.selectedSpec.keyid;
            }
            param.periodStart = this.periodStart;
            param.periodEnd = this.periodEnd;
            if (this.staticFilterSelected) {
                param.staticFilterSelected = this.staticFilterSelected;
            }
            this.rs.RnumbDateSetParams(param);
        }
        if (window.innerWidth < 500) {
            // this.selectedDoctor = doctor;
            this.openMobileTemplate = true;
        } else {
            this.showCalendar = true;
        }
        // this.showCalendar = true;
    }
    /* Список кнопок */
    getStaticfilterBTN() {
        this.staticFilterBTNs = [];
        this.staticFilterSelected = null;
        this.rs.getStacfilterList().subscribe(
            (val) => {
                this.staticFilterBTNs = val;
            },
            (error) => {
                console.error(error);
            }
        );
    }

    /* фильтр */

    btnStaticFiltr(btnST: IStaticFilter) {
        if (this.staticFilterSelected !== btnST.keyid) {
            this.staticFilterSelected = btnST.keyid;
        } else {
            this.staticFilterSelected = null;
        }

        this.getSpecList();
        this.recDoctors = [];
        this.searchStr = '';
        this.searchAmine = 'on';
    }

    onClosePanelTalon(event: number) {
        this.selectDoc = null;
        this.panelRnumbAmine = 'off';
    }

    isShowSrvList(doc: IDoctor): boolean {
        if (doc && doc.srvlist && doc.srvlist.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    invalidSrv(doctorid: number): boolean {
        let f: boolean = false;
        if (
            this.selectDoc &&
            this.selectDoc.doctorid == doctorid &&
            !this.selectedSrv
        ) {
            f = true;
        }
        return f;
    }
    openDocInfoModal(doc: IDoctor, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(DocInfoModalComponent);
        modal.componentInstance.doc = doc;
        modal.componentInstance.spec = this.selectedSpec?.text;
    }

    openServDescModal(docSrv: IServ, event: Event) {
        event.stopPropagation();
        // const modal = this.modalService.open(ServDescModalComponent);
        // modal.componentInstance.docSrv = docSrv;
        // modal.componentInstance.spec = this.selectedSpec?.text;
        const modal = this.modalService.open(ServModalComponent);
        modal.componentInstance.serv = docSrv;
    }

    onTalonPickerClosed() {
        // this.showTalonPicker = false;
        this.showCalendar = false;
    }
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    onFilterChanged(event: any) {
        switch (event.typeEvent) {
            case 'clearFilter':
                this.staticFilterSelected = null;
                this.getSpecList();
                this.toggleDropdown();
                this.recList = [];
                this.searchStr = '';
                this.searchAmine = 'on';

                break;
            case 'getStacFilters':
                this.staticFilterBTNs = event.data;
                break;

            case 'filterListUpdated':
                this.cdref.detectChanges();
                this.staticFilterSelected = event.data;
                this.getSpecList();
                this.recDoctors = [];
                this.searchStr = '';
                this.toggleDropdown();
                break;
        }
    }

    rcountText(count: number): string {
        if (!count) return 'Нет номерков';

        const lastDigit = count % 10;
        const lastTwoDigits = count % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return `Осталось ${count} номерков`;
        }

        switch (lastDigit) {
            case 1:
                return `Остался ${count} номерок`;
            case 2:
            case 3:
            case 4:
                return `Осталось ${count} номерка`;
            default:
                return `Осталось ${count} номерков`;
        }
    }

    formatDoctorName(fullName: string): string {
        const nameParts = fullName.split(' ');
        if (nameParts.length >= 2) {
            const lastName = nameParts[0];
            const initials = nameParts
                .slice(1)
                .map((part) => part.charAt(0) + '.')
                .join('');
            return `${lastName} ${initials}`;
        }
        return fullName;
    }
    getFioDoc(doctor: IDoctor): string {
        if (!doctor || !doctor.l_name || !doctor.f_name) return '';
        return `${doctor.l_name} ${doctor.f_name} ${doctor.s_name || ''}`;
    }
    openSelectStates: { [key: number]: boolean } = {};

    toggleSelect(doctorId: number) {
        if (this.openSelectStates.hasOwnProperty(doctorId)) {
            if (this.openSelectStates[doctorId]) {
                // Close the dropdown menu if it's already open
                this.openSelectStates[doctorId] = false;
            } else {
                // Open the dropdown menu for the selected doctor
                Object.keys(this.openSelectStates).forEach((key) => {
                    this.openSelectStates[Number(key)] = false;
                });
                this.openSelectStates[doctorId] = true;
            }
        } else {
            // Initialize the dropdown menu state for the selected doctor
            this.openSelectStates[doctorId] = true;
        }
    }
    selectedSrvMap: { [key: number]: IServ } = {};
    loadingCalendar = false;
    selectService(service: IServ, doctor: IDoctor) {
        this.selectDoc = doctor;

        this.showCalendar = true;
        this.loadingCalendar = true;
        if (doctor) {
            this.selectedSrv = service;
            this.selectedSrvMap[doctor.doctorid] = service;
            this.openSelectStates[doctor.doctorid] = false;

            this.paramsTalonpicker = {
                doctorId: doctor.doctorid,
                srvId: service.keyid,
                periodStart: this.periodStart,
                periodEnd: this.periodEnd,
                specId: this.selectedSpec?.keyid,
                infoDoc: doctor.info,
            };

            this.RnumbDateSetParams();
            setTimeout(() => {
                this.loadingCalendar = false;
            }, 1000);
        }
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        if (!clickedElement.closest('.service-select')) {
            Object.keys(this.openSelectStates).forEach((key) => {
                this.openSelectStates[Number(key)] = false;
            });
        }
    }

    clearSearch() {
        this.selectedSpec = null;
        this.searchStr = '';
        this.searchAmine = 'on';
        // this.getSpecList();
        this.changeSearch(this.searchStr);
        this.openMobileTemplate = false;
    }

    mobileFilterHandler() {
        this.isMobileFlilterOpen = !this.isMobileFlilterOpen;
    }
    closeRegistrationPicker() {
        // this.selectedDoctor = undefined;

        this.openMobileTemplate = false;
    }
}
