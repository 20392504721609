import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
} from '@angular/core';
import { ConfigService } from 'src/app/services/application/config.service.';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocInfoModalComponent } from 'src/app/modals/doc-info-modal/doc-info-modal.component';
import {
    ISpec,
    Doctor,
    IRnumbDate,
    IDep,
    IRecTalon,
    ITalonModal,
    ITalonsDaySetParams,
    IRecTalonInit,
    IDoctor,
} from '../../../interfaces/record.interface';
import { RecordService } from 'src/app/services/record.service';
import { log } from 'console';
import { strToDate } from '../../application/global.function';
import { lastValueFrom } from 'rxjs';
import moment, { Moment } from 'moment';
import { AlertService } from 'src/lib/ariadna/alert';
import { NewTalonComponentMobile } from 'src/app/modals/new-talon-mobile/talon.component';
import { MatDialog } from '@angular/material/dialog';
import { CalendarService } from '../../../services/calendar.service';
import { CalendarHeader } from '../../talonpicker/header/calendar-header.component';
import { Router } from '@angular/router';

interface Department {
    keyid: number;
    text: string;
}

@Component({
    selector: 'rec-services-mobile',
    templateUrl: './rec-services-mobile.component.html',
    styleUrls: ['./rec-services-mobile.component.scss'],
})
export class RecServicesMobileComponent implements OnInit, OnChanges {
    @Input() selectedDoctor: Doctor | undefined;
    @Input() params!: IRnumbDate;
    @Output() closePicker: EventEmitter<void> = new EventEmitter<void>();

    doctors: Doctor[] = [];
    selectedSpec: ISpec | null = null;
    openMobileTemplate: boolean = false;
    loading: boolean = true;
    talonListAll: IRecTalon[] = [];
    talonList: IRecTalon[] = []; // Массов дней - активный
    paramsTalonPicker!: IRnumbDate;
    selectedDep: IDep | Department | null = null;
    showDropdown = false;
    depList: Department[] = [];
    talonListFiltered: IRecTalon[] = [];
    activeDays: Moment[] = []; // NOT sorted by date
    selectedDay: Moment | null = moment();
    currentDay: Date | null | undefined = null;
    selectedDate: Date | null = new Date(); // Выбрана дата
    showCalendarIcon: boolean = false;

    // minDate: Moment = moment();
    // maxDate: Moment = moment().add(1, 'month');
    minDate: Moment = moment().startOf('year');
    maxDate: Moment = moment().endOf('year');
    pickedTalon: IRecTalon | null = null;
    headerTxt = ''; // Заголовок
    isSlideRight = false;
    isSlideLeft = false;

    header = CalendarHeader;
    isCalendarActive: boolean = false;
    depPicked: boolean = false;
    paramsData: IRecTalonInit | null = null; // паарметры инициализации
    dateFilter = (date: Moment) => this.isActiveDay(date);
    emptyDep: IDep = {
        keyid: 0,
        text: '',
    };

    constructor(
        private rs: RecordService,
        private config: ConfigService,
        private modalService: NgbModal,
        private alertService: AlertService,
        private modal: MatDialog,
        private cs: CalendarService,
        private router: Router // public dialogRef: MatDialogRef<NewTalonComponentMobile>
    ) {}

    ngOnInit(): void {}
    prevDate(): void {
        const currentDate = moment(this.selectedDate).startOf('day');

        // собираем уникальные даты из talonListAll
        const uniqueDates = [
            ...new Set(
                this.talonListAll.map((talon) =>
                    moment(talon.dtBegin).startOf('day').valueOf()
                )
            ),
        ].sort((a, b) => a - b);

        const currentIndex = uniqueDates.findIndex(
            (date) => date === currentDate.valueOf()
        );

        if (currentIndex > 0) {
            const prevDate = moment(uniqueDates[currentIndex - 1]);
            this.clickDate(prevDate);
            this.talonListFiltered = this.filterTalons();
        }
        //Анимация
        this.isSlideLeft = true;
        setTimeout(() => {
            this.isSlideLeft = false;
        }, 1000);
    }

    nextDate(): void {
        const currentDate = moment(this.selectedDate).startOf('day');

        // собираем уникальные даты из talonListAll
        const uniqueDates = [
            ...new Set(
                this.talonListAll.map((talon) =>
                    moment(talon.dtBegin).startOf('day').valueOf()
                )
            ),
        ].sort((a, b) => a - b);

        const currentIndex = uniqueDates.findIndex(
            (date) => date === currentDate.valueOf()
        );

        if (currentIndex < uniqueDates.length - 1) {
            const nextDate = moment(uniqueDates[currentIndex + 1]);
            this.clickDate(nextDate);
            this.talonListFiltered = this.filterTalons();
        }
        //Анимация
        this.isSlideRight = true;
        setTimeout(() => {
            this.isSlideRight = false;
        }, 1000);
    }

    changeDate(direction: number): void {
        if (this.selectedDate) {
            this.selectedDate = new Date(
                this.selectedDate.setDate(
                    this.selectedDate.getDate() + direction
                )
            );
        }
    }

    isFirstDate(): boolean {
        if (!this.selectedDate || !this.talonListAll.length) return true;

        const uniqueDates = [
            ...new Set(
                this.talonListAll.map((talon) =>
                    moment(talon.dtBegin).startOf('day').valueOf()
                )
            ),
        ].sort((a, b) => a - b);

        return (
            moment(this.selectedDate).startOf('day').valueOf() ===
            uniqueDates[0]
        );
    }

    isLastDate(): boolean {
        if (!this.selectedDate || !this.talonListAll.length) return true;

        const uniqueDates = [
            ...new Set(
                this.talonListAll.map((talon) =>
                    moment(talon.dtBegin).startOf('day').valueOf()
                )
            ),
        ].sort((a, b) => a - b);

        return (
            moment(this.selectedDate).startOf('day').valueOf() ===
            uniqueDates[uniqueDates.length - 1]
        );
    }

    onDateSelected(date: Moment) {
        this.clickDate(date);
        this.talonListFiltered = this.filterTalons();
    }

    /* ПОлучение списка талонов */
    initTalons(t: IRecTalonInit) {
        this.paramsData = t;
        this.talonListAll = this.paramsData.talons;
    }

    async ngOnChanges(changes: any) {
        if (changes.params) {
            this.loading = true;
            this.maxDate = moment(changes.params.currentValue.periodEnd);
            await Promise.all([
                await this.getTalonList(changes.params.currentValue),
                await this.getDepList(changes.params.currentValue),
            ]).catch((e) => {
                this.alertService.error('Не удалось получить список талонов');
                console.error(e);
            });
            this.activeDays = this.filterActiveDays();
            this.selectedDay = this.findFirstActiveDay();

            this.talonListFiltered = this.filterTalons();

            this.updateCalendar();
            this.loading = false;
            this.pickedTalon = null;
            this.currentDay = this.selectedDay.toDate();
            const data: ITalonsDaySetParams = {
                dt: this.currentDay ? this.currentDay : new Date(),
                dep: this.selectedDep ? this.selectedDep : undefined,
            };
            this.initDay(data);
        }
    }

    private isActiveDay(day: Moment) {
        return this.activeDays.some(
            (activeDay) =>
                day.date() === activeDay.date() &&
                day.month() === activeDay.month() &&
                day.year() === activeDay.year()
        );
    }

    async openTalonModal(event: MouseEvent, talon: IRecTalon | null) {
        event.stopPropagation();
        if (this.pickedTalon) {
            talon = this.pickedTalon;
        }

        if (this.params?.srv?.is_telemed !== talon?.is_telemed) {
            this.loading = false;
            return;
        }

        if (!talon) return;
        let data: ITalonModal = {
            rnumbID: null,
            info: {
                docdepID: talon.docdepid,
                beginDate: talon.dtBegin,
            },
            srv: this.params.srv,
            paramCansel: false,
            infoDoc: this.params.infoDoc,
        };
        try {
            if (
                talon.is_interval &&
                talon.is_interval > 0 &&
                talon.interval_id
            ) {
                const { guid, err_code, err_text } = await lastValueFrom(
                    this.rs.getRnumbCreateInterval(
                        talon.interval_id,
                        talon.dat_begin_str,
                        talon.dat_end_str
                    )
                );
                if (err_code !== 0 || !guid) throw new Error(err_text);
                await lastValueFrom(
                    this.rs.setRnumbBlStatus(data.rnumbID as number)
                );
                data.rnumbID = guid;
            } else if (talon.rnumbid) {
                data.rnumbID = talon.rnumbid;
            }

            await lastValueFrom(
                this.rs.setRnumbBlStatus(data.rnumbID as number)
            );

            this.alertService.success(
                `Талон успешно зарезервирован, № талона: ${data.rnumbID}`
            );
            let dialogRefOptions = {
                width: 'auto',
                height: 'auto',
                maxWidth: 'auto',
                minWidth: 'auto',
                closeOnNavigation: true,
                data,
            };

            // Check screen width and adjust dialog options if needed
            if (window.matchMedia('(max-width: 768px)').matches) {
                dialogRefOptions.width = '90vw';
                dialogRefOptions.maxWidth = '100vw';
                dialogRefOptions.minWidth = '90vw';
                // dialogRefOptions.position = {
                //     position: 'absolute',
                //     top: '25%',
                //     left: '5%',
                //     bottom: '25%',
                // };
            }

            const modalRef = this.modal.open(
                NewTalonComponentMobile,
                dialogRefOptions
            );
            modalRef.afterClosed().subscribe((result) => {
                // console.log(result);
                if (result && result.unlockTalon) {
                    lastValueFrom(this.rs.getRnumbUnlock(result.rnumbID))
                        .then((value) => {
                            this.alertService.success(
                                `Талон успешно разблокирован, № талона: ${result.rnumbID}`
                            );
                        })
                        .catch((error: any) => {
                            this.alertService.error(
                                `Не удалось разблокировать талон с id ${result.rnumbID}: ${error.msg}`
                            );
                        });
                }
            });
        } catch (error: any) {
            console.error(`Не удалось создать интервал: ${error.msg}`);
            this.alertService.error(
                `Не удалось записаться на номерок: ${error.msg}`
            );
        }
    }

    clickTalon(event: Event, talon: IRecTalon) {
        event.stopPropagation();

        if (talon.rnumbid !== this.pickedTalon?.rnumbid) {
            this.pickedTalon = talon;
        } else {
            this.pickedTalon = null;
        }
    }

    findFirstActiveDay() {
        let firstActiveDay = this.activeDays[0];
        this.activeDays.forEach((day) => {
            if (firstActiveDay.valueOf() > day.valueOf()) firstActiveDay = day;
        });
        return firstActiveDay;
    }

    updateCalendar() {
        // if (this.calendar) this.calendar.updateTodaysDate();
    }

    private async getTalonList(params: IRnumbDate) {
        this.talonListAll = await lastValueFrom(this.rs.getRnumbListv2(params));
        this.talonListAll.forEach((talon, i) => {
            this.talonListAll[i].dtBegin = strToDate(talon.dat_begin_str);
        });
    }

    private async getDepList(params: IRnumbDate) {
        this.headerTxt = `${moment(this.selectedDay).format('DD MMMM YYYY')}`;
        this.depList = await lastValueFrom(this.rs.getDepListv2(params));

        this.depList = this.depList.filter((dep) => {
            const deps = new Set();
            this.talonListAll.forEach((talon) => deps.add(talon.depid));
            return deps.has(dep.keyid);
        });
        this.depList.unshift({ keyid: -1, text: 'Все' });
        this.loading = false;
    }

    private filterActiveDays() {
        return this.talonListAll
            .map((talon) => moment(strToDate(talon.dat_begin_str)))
            .filter((day, index, self) => self.indexOf(day) === index);
    }

    getDocImg(id: number | undefined) {
        return `${this.config.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getDocImgDefault(event: Event) {
        const elem = event.target as HTMLImageElement;
        elem.src = `${this.config.getValue('hostBackend')}/img/doc/not.png`;
    }
    openDocInfoModal(doc: Doctor | undefined, event: Event) {
        event.stopPropagation();

        const doctor: Partial<IDoctor> = {
            doctorid: doc?.docid as number,
            l_name: doc?.text.split(' ')[0] as string,
            f_name: doc?.text.split(' ')[1] as string,
            s_name: doc?.text.split(' ')[2] as string,
            info: doc?.info,
        };

        const modal = this.modalService.open(DocInfoModalComponent);
        modal.componentInstance.doc = doctor;
        modal.componentInstance.spec = doc?.specid_1;
    }
    closeComponent() {
        this.openMobileTemplate = false;
        this.closePicker.emit();
    }
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    selectDep(dep: Department | null) {
        this.showCalendarIcon = true;
        this.headerTxt = `${moment(this.selectedDay).format('DD MMMM YYYY')}`;
        if (dep?.keyid === -1) {
            this.selectedDep = null;

            this.depPicked = true;
            this.showDropdown = false;
            this.talonListFiltered = this.filterTalons();
        } else {
            this.selectedDep = dep;
            this.showDropdown = false;
            this.talonListFiltered = this.filterTalons();
            // this.talonListFiltered = [
            //     ...this.talonListFiltered,
            //     ...this.talonListFiltered,
            //     ...this.talonListFiltered,
            //     ...this.talonListFiltered,
            //     ...this.talonListFiltered,
            //     ...this.talonListFiltered,
            // ];
        }
    }

    placeholderDep() {
        return this.selectedDep && this.selectedDep !== null
            ? this.selectedDep?.text
            : this.depPicked
            ? 'Все'
            : 'Выберите филиал';
    }

    selectDay(day: Moment | null) {
        this.talonListFiltered = this.filterTalons();
    }

    filterTalons() {
        return this.talonListAll.filter((talon) => {
            return (
                talon.dtBegin?.getFullYear() === this.selectedDay?.year() &&
                talon.dtBegin?.getMonth() === this.selectedDay?.month() &&
                talon.dtBegin?.getDate() === this.selectedDay?.date() &&
                (this.selectedDep
                    ? talon.depid === this.selectedDep.keyid
                    : true)
            );
        });
    }
    /* Выбор даты в календаре талонов */
    depId: number | 0 = 0;
    clickDate(date: Moment) {
        this.selectedDate = date.clone().toDate();

        if (!date) {
            return;
        }
        this.selectedDay = moment(this.selectedDate);

        this.loadDate(this.depId);
        // this.selectedDate = this.selectedDate
        //     ? moment(this.selectedDate).add(1, 'day').toDate()
        //     : null;
        // this.selectedDay = this.selectedDay.clone().add(1, 'day');
    }
    talonListDay: IRecTalon[] = []; // Массов дней - активный
    calendarEnabled: boolean = true;
    classApplied = false;
    /* Получение текущей даты в фильтре */
    // initDay(data: ITalonsDaySetParams) {
    //     // Фильтр по дню
    //     // this.selectedDay = data.dt;
    //     const newData: ITalonsDaySetParams = {
    //         dt: this.selectedDay ? this.selectedDay.toDate() : new Date(),
    //         dep: this.selectedDep ? this.selectedDep : undefined,
    //     };
    //     data = newData;
    //     console.log('5555', data);

    //     this.selectedDay = moment(data?.dt);
    //     // const depId = data?.dep && data.dep.keyid > 0 ? data.dep.keyid : 0;
    //     const depId = data?.dep && 'keyid' in data.dep ? data.dep.keyid : 0;
    //     this.loadDate(depId);
    //     console.log('depIdinitDay', depId);
    //     this.rs.TalonsDaySetParams(data);
    // }
    /* Получение текущей даты в фильтре */
    initDay(data: ITalonsDaySetParams) {
        // Фильтр по дню
        this.selectedDate = data.dt;
        const depId = data.dep && data.dep.keyid > 0 ? data.dep.keyid : 0;

        this.loadDate(depId);
    }

    loadDate(depId: number) {
        const dateFilter = Number(moment(this.selectedDate).format('YYYYMMDD'));
        this.headerTxt = `${moment(this.selectedDay).format('DD MMMM YYYY')}`;

        this.talonListDay = this.talonList.filter(
            (item) =>
                item.dtFilter === dateFilter &&
                (item.depid === depId || depId === 0)
        );

        const prevDateDay = this.talonListAll.filter(
                (item) =>
                    Number(item.dtFilter) < dateFilter &&
                    (item.depid === depId || depId === 0)
            ),
            nextDateDay = this.talonListAll.filter(
                (item) =>
                    Number(item.dtFilter) > dateFilter &&
                    (item.depid === depId || depId === 0)
            );

        // this.prevDate = prevDateDay.length
        //     ? prevDateDay[prevDateDay.length - 1].dtBegin
        //     : null;
        // this.nextDate = nextDateDay.length ? nextDateDay[0].dtBegin : null;

        const dates = this.talonListAll
            .map((item) => item.dtFilter)
            .filter((date, index, self) => {
                return self.indexOf(date) === index;
            });

        const months = this.talonListAll
            .map((item) => String(item.dtFilter).substr(0, 6))
            .filter((month, index, self) => {
                return self.indexOf(month) === index;
            });

        this.calendarEnabled = dates.length > 1;

        this.cs.setParams({
            year_begin: Number(moment().format('YYYY')),
            mounth_begin: Number(moment().format('MM')),
            count: months.length,
            cdActive: dates.map((date) => ({
                date: date !== undefined ? date : 0,
            })),
            orderByStart: true,
            MinDate: {
                m: Number(moment().format('MM')),
                y: Number(moment().format('YYYY')),
            },
            selectDate: '',
        });
        this.cs.setSelectedDate(moment(this.selectedDate).format('YYYY-MM-DD'));
    }

    /* Открытие/скрытие календаря */
    toggleClass() {
        if (!this.calendarEnabled) {
            return;
        }
        this.classApplied = !this.classApplied;
        const element =
            document.getElementsByClassName('calendar-container')[0];
        if (this.classApplied === true && element) {
            element.classList.add('calendar-active');
        } else if (element) {
            element.classList.remove('calendar-active');
        }
    }

    calendarHandler() {
        this.isCalendarActive = !this.isCalendarActive;
    }

    toHistory(event: MouseEvent) {
        event.stopPropagation();
        this.router.navigate([`/home/history`]);
        // this.dialogRef.close();
    }

    toRecord(event: MouseEvent) {
        event.stopPropagation();
        this.router.navigate([`/home/record-services`]);
        // this.dialogRef.close();
    }
    /* Блокировка выбора талона */
    talonDisable(talon: IRecTalon): boolean {
        // if (!this.params?.srv) return false;
        return this.params?.srv?.is_telemed === talon.is_telemed;
    }
    buttonAcceptplaceholder() {
        if (this.pickedTalon) {
            if (this.params?.srv?.is_telemed !== this.pickedTalon?.is_telemed) {
                return 'Это номерок для другой услуги';
            }
            return 'Выбрать';
        }
        return 'Выберите время';
    }
}
