import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment, { Moment } from 'moment';
@Component({
    selector: 'app-custom-calendar',
    templateUrl: './custom-calendar.component.html',
    styleUrls: ['./custom-calendar.component.scss'],
})
export class CustomCalendarComponent implements OnInit {
    @Input() activeDates: Moment[] = [];
    @Input() selectedDate: Moment | null = null;
    @Output() dateSelected = new EventEmitter<Moment>();

    weeks: Moment[][] = [];
    weekDays: string[] = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    currentMonth: Moment = moment();
   

    ngOnInit() {
        this.generateCalendar();
    }

    generateCalendar() {
        const start = this.currentMonth
            .clone()
            .startOf('month')
            .startOf('week');
        const end = this.currentMonth.clone().endOf('month').endOf('week');

        const days: Moment[] = [];
        let day = start.clone();

        while (day.isSameOrBefore(end)) {
            days.push(day.clone());
            day.add(1, 'day');
        }

        this.weeks = [];
        while (days.length) {
            this.weeks.push(days.splice(0, 7));
        }
    }

    isActiveDate(date: Moment): boolean {
        return this.activeDates.some((activeDate) =>
            date.isSame(activeDate, 'day')
        );
    }

    selectDate(date: Moment) {
        if (this.isActiveDate(date)) {
            this.selectedDate = date;
            this.dateSelected.emit(date);
        }
    }

    prevMonth(): void {
        this.currentMonth = this.currentMonth.clone().subtract(1, 'month');
        this.generateCalendar();
    }

    nextMonth(): void {
        this.currentMonth = this.currentMonth.clone().add(1, 'month');
        this.generateCalendar();
    }

    formatMonth(): string {
        const month = this.currentMonth.format('MMMM YYYY');
        return month.charAt(0).toUpperCase() + month.slice(1);
    }
}
