import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectOption } from 'src/app/interfaces/select';
@Component({
    selector: 'chip',
    templateUrl: 'chip.component.html',
    styleUrls: ['chip.component.scss'],
})
export class ChipComponent implements OnInit {
    @Input() value: string | number = '';
    @Input() text?: string;
    @Input('text-align') textAlign: 'start' | 'center' | 'end' = 'center';
    @Input() disabled = false;
    @Input() selected = false;
    @Input() stratch = false;
    @Input() hint?: string;

    @Output() onSelectionChange = new EventEmitter<SelectOption>();

    constructor() {}

    ngOnInit() {}

    onSelectionHendler(e: MouseEvent) {
        // console.log('chip', this.value, this.selected, this.text);
        if (!this.disabled) {
            this.onSelectionChange.emit({
                value: this.value,
                text: this.text,
                selected: !this.selected,
            });
        }
    }
}
