<div class="mobile-template">
    <div class="loader" *ngIf="loadingListDoc">
        <loader type="page"> </loader>
    </div>
    <div class="mobile-template_content" *ngIf="!loadingListDoc">
        <div class="doclist" *ngIf="!selectedSrv">
            <div class="doctor__card" *ngFor="let doctor of recDoctors">
                <div class="doctor__card-top">
                    <img
                        class="doctor__img"
                        src="{{ getDocImg(doctor.doctorid) }}"
                        (error)="getDocImgDefault($event)"
                        alt="doc"
                        width="72"
                        height="72"
                        border-radius="5px"
                    />
                    <div class="doctor__desc">
                        <div class="doctor__name">
                            {{ getFIODoc(doctor) }}
                        </div>
                        <div class="doctor__desc-rcount">
                            {{ rcountText(doctor.rcount) }}
                        </div>
                    </div>
                </div>
                <button
                    class="doctor__serv-button"
                    (click)="showSrvHandler($event, doctor)"
                >
                    Выберите <br />
                    услугу
                </button>
            </div>

            <rec-mobile-docsrv-list
                *ngIf="showSrv && selectedDoctor"
                class="doctor__srvlist"
                [staticSrvSelected]="staticSrvSelected"
                [srvList]="selectedDoctor.srvlist || []"
                (srvChanged)="onFilterChanged($event)"
                (closeSrvList)="showSrv = false"
            >
            </rec-mobile-docsrv-list>
        </div>
    </div>

    <div class="doctor__recording" *ngIf="selectedSrv">
        <div class="mobile-template__header">
            <div class="mobile-template__header__icon">
                <img
                    src="./assets/img/svg/arrow_back.svg"
                    alt="arrow_back"
                    (click)="closeComponent()"
                />
            </div>
            <span class="mobile-template__header__span">Запись к врачу</span>
            <div
                class="mobile-template__header__icon"
                (click)="calendarHandler()"
            >
                <img
                    src="./assets/img/svg/calendar.svg"
                    alt="calendar"
                    *ngIf="showCalendarIcon"
                />
            </div>
        </div>
        <div
            class="mobile-template_content__top"
            *ngIf="!isCalendarActive && selectedSrv"
        >
            <div class="doctor__depscheck">
                <div class="doctor__depscheck-top">
                    <img
                        class="doctor__img"
                        src="{{ getDocImg(selectedDoctor?.doctorid) }}"
                        (error)="getDocImgDefault($event)"
                        alt="doc"
                        width="70"
                        height="70"
                        border-radius="5px"
                    />
                    <div class="doctor__desc">
                        <div class="doctor__name">
                            {{ getFullName(this.selectedDoctor) }}
                        </div>
                        <div class="doctor__spec">
                            {{ this.selectedSpec?.text }}
                        </div>
                    </div>
                    <button
                        type="button"
                        class="doctor__info"
                        (click)="openDocInfoModal(this.selectedDoctor, $event)"
                    >
                        <img src="./assets/img/svg/info.svg" alt="info" />
                    </button>
                </div>
                <div class="selected-srv">
                    <span class="selected-srv-text">{{
                        selectedSrv.text
                    }}</span>
                    <span class="selected-srv-price"
                        >{{ selectedSrv.price }} рублей</span
                    >
                </div>

                <div
                    class="deps_wrapper"
                    *ngIf="this.talonListFiltered.length > 0"
                >
                    <button class="deps_button" (click)="toggleDropdown()">
                        <span>{{ placeholderDep() }}</span>
                    </button>
                    <div class="deps_list" *ngIf="showDropdown">
                        <div
                            class="dropdown__item"
                            *ngFor="let dep of depList"
                            (click)="selectDep(dep)"
                        >
                            {{ dep.text }}
                        </div>
                    </div>
                    <div
                        class="talons__empty"
                        *ngIf="this.talonListFiltered.length === 0"
                    >
                        Свободных номерков нет
                    </div>
                </div>
            </div>
        </div>
        <div
            class="mobile-calendar"
            *ngIf="isCalendarActive"
            [class.slide-down]="isCalendarActive"
            [class.slide-up]="!isCalendarActive"
        >
            <app-custom-calendar
                [activeDates]="activeDays"
                [selectedDate]="selectedDay"
                (dateSelected)="onDateSelected($event)"
            ></app-custom-calendar>
        </div>
        <div
            class="talons"
            *ngIf="depPicked || selectedDep"
            [class.isCalendarActive]="isCalendarActive"
        >
            <div
                class="time-choice__nav"
                *ngIf="this.talonListFiltered.length > 0"
            >
                <div class="button__prev">
                    <button
                        (click)="prevDate()"
                        class="buttons"
                        [disabled]="isFirstDate()"
                        [class.arrow__disable]="isFirstDate()"
                    >
                        <img src="./assets/img/svg/arrow_r.svg" alt="prev" />
                    </button>
                </div>

                <div
                    class="time-choice__info"
                    (click)="toggleClass()"
                    *ngIf="
                        (talonListFiltered.length !== 0 &&
                            talonListFiltered[0].interval_id !== null) ||
                        talonListFiltered[0].rnumbid !== null
                    "
                >
                    <span class="time-choice__label">Свободно на</span>
                    <span class="time-choice__date">{{ headerTxt }}</span>
                </div>
                <div
                    class="time-choice__info"
                    *ngIf="
                        talonListFiltered.length !== 0 &&
                        talonListFiltered[0].rnumbid === null &&
                        talonListFiltered[0].interval_id === null
                    "
                >
                    Вы записываетесь на
                    <span class="time-choice__date">
                        <br />
                        {{ headerTxt }}</span
                    >
                </div>
                <div class="button__next">
                    <button
                        (click)="nextDate()"
                        class="buttons"
                        [disabled]="isLastDate()"
                        [class.arrow__disable]="isLastDate()"
                    >
                        <img src="./assets/img/svg/arrow_r.svg" alt="next" />
                    </button>
                </div>
            </div>
            <div
                class="talons__list"
                [class.slide-right]="isSlideRight"
                [class.slide-left]="isSlideLeft"
                *ngIf="
                    (talonListFiltered.length !== 0 &&
                        talonListFiltered[0].interval_id !== null) ||
                    talonListFiltered[0].rnumbid !== null
                "
            >
                <div
                    class="talons__list-item"
                    *ngFor="let talon of talonListFiltered"
                    (click)="clickTalon($event, talon)"
                    [class.active]="talon === pickedTalon"
                    [ngClass]="{
                        disabled: !talonDisable(talon)
                    }"
                >
                    {{ talon.dtBegin | date : "HH:mm" }}
                </div>
            </div>

            <ng-container
                *ngIf="
                    talonListFiltered.length !== 0 &&
                    talonListFiltered[0].rnumbid === null &&
                    talonListFiltered[0].interval_id === null
                "
            >
                <button
                    class="btn btn-dialog-accept"
                    (click)="openTalonModal($event, talonListFiltered[0])"
                >
                    Продолжить
                </button>
            </ng-container>

            <ng-container
                *ngIf="
                    !(
                        talonListFiltered.length !== 0 &&
                        talonListFiltered[0].rnumbid === null &&
                        talonListFiltered[0].interval_id === null
                    )
                "
            >
                <button
                    class="btn btn-dialog-accept"
                    (click)="openTalonModal($event, null)"
                    [disabled]="!pickedTalon"
                >
                    <!-- {{ pickedTalon ? "Выбрать" : "Выберите время" }} -->
                    {{ buttonAcceptplaceholder() }}
                </button>
            </ng-container>
        </div>
    </div>
</div>
