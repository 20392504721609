<!-- <div class="select-wrapper">
    <text theme="label" *ngIf="label" [required]="required">{{ label }}</text>

    <select
        class="select"
        [ngClass]="[theme]"
        (ngModelChange)="onSelectionChangeHandler($event)"
        [ngModel]="value"
    >
        <option [ngValue]="undefined" [disabled]="true">
            {{ placeholder }}
        </option>
        <option class="option" *ngFor="let opt of options" [ngValue]="opt">
            {{ opt.text }}
        </option>
    </select>
</div> -->

<div class="select-wrapper">
    <text theme="label" *ngIf="label" [required]="required">{{ label }}</text>

    <div class="select" [ngClass]="[theme]" (click)="toggleDropdown()">
        <div class="selected-option">{{ selectedOptionText }}</div>
        <img
            class="arrow-icon"
            [src]="
                isDropdownOpen
                    ? './assets/img/svg/arrow_up.svg'
                    : './assets/img/svg/arrow_down.svg'
            "
            alt="Arrow Icon"
        />
        <div class="dropdown" *ngIf="isDropdownOpen">
            <div
                class="option"
                *ngFor="let opt of options"
                (click)="selectOption(opt); $event.stopPropagation()"
            >
                {{ opt.text }}
            </div>
        </div>
    </div>
</div>
