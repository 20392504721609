import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NgxCookieConsentComponent,
    NgxCookieConsentModule,
} from '@localia/ngx-cookie-consent';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import {
    LottieComponent,
    LottieDirective,
    provideCacheableAnimationLoader,
    provideLottieOptions,
} from 'ngx-lottie';
import {
    NgxMaskDirective,
    NgxMaskPipe,
    provideEnvironmentNgxMask,
} from 'ngx-mask';
import { lastValueFrom } from 'rxjs';
import { AlertModule } from 'src/lib/ariadna/alert';
import { AlertRenderDirective } from './alertRender.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountComponent } from './components/account/account.component';
import { FooterComponent } from './components/application/footer/footer.component';
import { HeaderComponent } from './components/application/header/header.component';
import { MenuLeftComponent } from './components/application/menu-left/menu-left.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { DicomViewModalComponent } from './components/history/dicom-view-modal/dicom-view-modal.component';
import { HistoryComponent } from './components/history/history.component';
import { ListCompletedComponent } from './components/history/list-completed/list-completed.component';
import { VisitCardComponent } from './components/history/list-completed/visit-card/visit-card.component';
import { ListUpcomingComponent } from './components/history/list-upcoming/list-upcoming.component';
import { HomeComponent } from './components/home/home.component';
import { IndexComponent } from './components/home/index/index.component';
import { FormaBiomaterialMobileComponent } from './components/labs/labs-mobile/forma-biomaterial/forma-biomaterial.component';
import { FormaMaterialMobileComponent } from './components/labs/labs-mobile/forma-material/forma-material.component';
import { LabsMobileComponent } from './components/labs/labs-mobile/labs-mobile.component';
import { FormaBiomaterialComponent } from './components/labs/labs-pc/forma-biomaterial/forma-biomaterial.component';
import { FormaMaterialComponent } from './components/labs/labs-pc/forma-material/forma-material.component';
import { LabsPcComponent } from './components/labs/labs-pc/labs-pc.component';
import { LabsComponent } from './components/labs/labs.component';
import { AuthPhoneComponent } from './components/login/auth-phone/auth-phone.component';
import { AuthComponent } from './components/login/auth/auth.component';
import { DogListComponent } from './components/login/dog-list/dog-list.component';
import { LoginComponent } from './components/login/login.component';
import { RecoveryComponent } from './components/login/recovery/recovery.component';
import { RegComponent } from './components/login/reg/reg.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PatientFilesComponent } from './components/patient-files/patient-files.component';
import { PayPeriodDialogComponent } from './components/payments/dialogs/pay-period-dialog/pay-period-dialog.component';
import { PayDetailsDialogComponent } from './components/payments/dialogs/pays-details-dialog/pay-details-dialog.component';
import { IsRefundPipe } from './components/payments/is-refund.pipe';
import { PaymentsComponent } from './components/payments/payments.component';
import { FilterComponent } from './components/rec-services/filter-component/filter-component';
import { RecMobileComponent } from './components/rec/rec-mobile/rec-mobile.component';
import { RecServicesMobileComponent } from './components/rec-services/rec-services-mobile/rec-services-mobile.component';
import { CustomCalendarComponent } from './components/rec-services/custom-calendar/custom-calendar.component';
import { RecServicesComponent } from './components/rec-services/rec-services.component';
import { recMobileDocsrvList } from './components/rec/rec-mobile/rec-mobile-docsrv-list/rec-mobile-docsrv-list';

import { RecCalendaryComponent } from './components/rec/rnumb-selected/rec-calendary/rec-calendary.component';
import { RecDepListComponent } from './components/rec/rnumb-selected/rec-dep-list/rec-dep-list.component';
import { RnumbSelectedComponent } from './components/rec/rnumb-selected/rnumb-selected.component';
// import { TalonsComponent } from './components/rec/rnumb-selected/talons/talons.component';
import { RecComponent } from './components/rec/rec.component';
import { ServicesDetailsDialogComponent } from './components/services/dialogs/services-details-dialog/services-details-dialog.component';
import { ServicesPeriodDialogComponent } from './components/services/dialogs/services-period-dialog/services-period-dialog.component';
import { ServicesComponent } from './components/services/services.component';
import { BarcodeComponent } from './components/shared/barcode/barcode.component';
import { BlockPromoCarouselComponent } from './components/shared/block-promo-carousel/block-promo-carousel.component';
import { BlockPromoComponent } from './components/shared/block-promo/block-promo.component';
import { BlockTalonsComponent } from './components/shared/block-talons/block-talons.component';
import { CalendarComponentMobile } from './components/shared/calendar-mobile/calendar.component';
import { ItemCalendarComponentMobile } from './components/shared/calendar-mobile/item-calendar/item-calendar.component';
import { CalendarComponent } from './components/shared/calendar/calendar.component';
import { ItemCalendarComponent } from './components/shared/calendar/item-calendar/item-calendar.component';
import { CheckboxListComponent } from './components/shared/checkbox-list/checkbox-list.component';
import { CheckboxComponent } from './components/shared/checkbox/checkbox.component';
import { ChipListComponent } from './components/shared/chip-list/chip-list.component';
import { IsChipSelectedPipe } from './components/shared/chip-list/is-chip-selected.pipe';
import { ChipComponent } from './components/shared/chip/chip.component';
import { CalendaryComponent } from './components/shared/date-range/calendary/calendary.component';
import { DateRangeComponent } from './components/shared/date-range/date-range.component';
import { MobileDateRangeComponent } from './components/shared/date-range/mobile-date-range/mobile-date-range.component';
import { TableDayComponent } from './components/shared/date-range/table-day/table-day.component';
import { LineLabResMobileComponent } from './components/shared/line-lab-res-mobile/line-lab-res.component';
import { LineLabResComponent } from './components/shared/line-lab-res/line-lab-res.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { FeedHeaderPeriodComponent } from './components/shared/mobile-feed-page-template/feed-header-period/feed-header-period.component';
import { FeedPageAnchorComponent } from './components/shared/mobile-feed-page-template/feed-page-anchor/feed-page-anchor.component';
import { MobileFeedPageTemplateComponent } from './components/shared/mobile-feed-page-template/mobile-feed-page-template.component';
import { PaginationComponent } from './components/shared/pagination/pagination.component';
import { PaginationMobileComponent } from './components/shared/pagination/paginationmobile/pagination.component';
import { PanelTablePaginationComponent } from './components/shared/panel-table-pagination/panel-table-pagination.component';
import { PanelComponent } from './components/shared/panel/panel.component';
import { PeriodComponent } from './components/shared/period/period.component';
import { ProtocolControlComponent } from './components/shared/protocol-control/protocol-control.component';
import { RecCalendarComponentMobile } from './components/shared/rec-calendar-mobile/calendar.component';
import { ScrollViewComponent } from './components/shared/scroll-view/scroll-view.component';
import { SelectComponent } from './components/shared/select/select.component';
import { TextInputComponent } from './components/shared/text-input/text-input.component';
import { TextComponent } from './components/shared/text/text.component';
import { UserpicComponent } from './components/shared/userpic/component';
import { FilterTalonsByDayPipe } from './components/talonpicker/filter-by-day.pipe';
import { CalendarHeader } from './components/talonpicker/header/calendar-header.component';
import { TalonpickerComponent } from './components/talonpicker/talonpicker.component';
import { ProtocolFormComponent } from './components/visit-protocol/protocol-form/protocol-form.component';
import { VisitProtocolComponent } from './components/visit-protocol/visit-protocol.component';
import { datePipe } from './directives/date.pipe';
import { evenoddPipe } from './directives/evenodd.pipe';
import { isNotDatePipe } from './directives/isNotDate';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { phoneMaskDirective } from './directives/phoneMask.directive';
import { PriceSpacePipe } from './directives/price-space.pipe';
import { SafeHtmlPipe } from './directives/safe-html.pipe';
import { SearchFilterPipe } from './directives/searchFilter.pipe';
import { snilsMaskDirective } from './directives/snilsMask.directive';
import { SortByPipe } from './directives/sortBy.pipe';
import { getBaseHref } from './helpers/help';
import { ApiResponseInterceptor } from './interceptors/ApiResponseInterceptor';
import { ChangePwComponent } from './modals/change-pw/change-pw.component';
import { CommissionComponent } from './modals/commission/commission.component';
import { CreatePwComponent } from './modals/create-pw/create-pw.component';
import { DocImgComponent } from './modals/doc-img/doc-img.component';
import { DocInfoModalComponent } from './modals/doc-info-modal/doc-info-modal.component';
import { FamilyAccessComponent } from './modals/family-access/family-access.component';
import { HistoryItemComponentMobile } from './modals/history-item-mobile/history-item.component';
import { HistoryItemComponent } from './modals/history-item/history-item.component';
import { HistoryPdfModal } from './modals/history-pdf-modal/history-pdf.component';
import { NewTalonComponentMobile } from './modals/new-talon-mobile/talon.component';
import { RefundComponent } from './modals/refund/refund.component';
import { StatementComponent } from './modals/statement/statement.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { ConfigService } from './services/application/config.service.';
import { HttpNewService } from './services/application/http-new.service';
import { LoadService } from './services/application/load.service';
import { AuthService } from './services/auth.service';
import { compToPagination } from './services/compToPagination';
import {
    CookieConsentService,
    serviceObject,
} from './services/cookie-consent-service';
import { Size } from './services/size';
import { RecDoctorService } from './services/to-doctor/rec-doctor.service';
import { I18n } from './shared/i18n/models';

import { SmsComponent } from './components/account/sms/sms.component';

export function playerFactory() {
    return import('lottie-web');
}

@NgModule({
    declarations: [
        NotFoundComponent,
        AppComponent,
        LoginComponent,
        FooterComponent,
        HeaderComponent,
        HomeComponent,
        AuthComponent,
        RegComponent,
        snilsMaskDirective,
        phoneMaskDirective,
        datePipe,
        isNotDatePipe,
        PriceSpacePipe,
        SortByPipe,
        evenoddPipe,
        MenuLeftComponent,
        AccountComponent,
        IndexComponent,
        PanelComponent,
        PaginationComponent,
        StatementComponent,
        ServicesComponent,
        PanelTablePaginationComponent,
        PeriodComponent,
        PaymentsComponent,
        LabsComponent,
        LineLabResComponent,
        FormaMaterialComponent,
        FormaBiomaterialComponent,
        RecComponent,
        HistoryComponent,
        CalendarComponent,
        ItemCalendarComponent,
        HistoryItemComponent,
        RecDepListComponent,
        RecCalendaryComponent,
        RnumbSelectedComponent,
        DateRangeComponent,
        CalendaryComponent,
        ChangePwComponent,
        RecoveryComponent,
        TableDayComponent,
        PaginationMobileComponent,
        HistoryItemComponentMobile,
        ItemCalendarComponentMobile,
        CalendarComponentMobile,
        AuthPhoneComponent,
        DogListComponent,
        ObserveVisibilityDirective,
        MobileFeedPageTemplateComponent,
        FeedPageAnchorComponent,
        MobileDateRangeComponent,
        ServicesDetailsDialogComponent,
        ServicesPeriodDialogComponent,
        LabsPcComponent,
        LabsMobileComponent,

        DocImgComponent,
        CreatePwComponent,
        CommissionComponent,
        PayPeriodDialogComponent,
        PayDetailsDialogComponent,
        FormaBiomaterialMobileComponent,
        FormaMaterialMobileComponent,
        LineLabResMobileComponent,
        RecCalendarComponentMobile,
        NewTalonComponentMobile,

        ProtocolFormComponent,
        ProtocolControlComponent,
        VisitProtocolComponent,
        TextComponent,
        SelectComponent,
        ChipComponent,
        ChipListComponent,
        SafeHtmlPipe,
        LoaderComponent,
        IsChipSelectedPipe,
        RefundComponent,
        IsRefundPipe,
        CheckboxComponent,
        CheckboxListComponent,
        TextInputComponent,
        FeedHeaderPeriodComponent,
        ScrollViewComponent,
        BlockPromoComponent,
        BlockPromoCarouselComponent,
        ListUpcomingComponent,
        ListCompletedComponent,
        BarcodeComponent,
        ServiceUnavailableComponent,
        DicomViewModalComponent,
        VisitCardComponent,
        UserpicComponent,
        SearchFilterPipe,
        DocInfoModalComponent,
        RecServicesComponent,
        HistoryPdfModal,
        FeedbackComponent,
        PatientFilesComponent,
        TalonpickerComponent,
        CalendarHeader,
        FilterTalonsByDayPipe,
        FamilyAccessComponent,
        FilterComponent,
        RecMobileComponent,
        RecServicesMobileComponent,
        CustomCalendarComponent,
        SmsComponent,
        recMobileDocsrvList,
        // SmsCodeComponent
    ],
    bootstrap: [AppComponent],
    exports: [],
    imports: [
        NgxCookieConsentModule.forRoot(serviceObject),
        LottieComponent,
        LottieDirective,
        AlertRenderDirective,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NgbTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDialogModule,
        MatTabsModule,
        MatSelectModule,
        MatListModule,
        MatExpansionModule,
        AlertModule,
        BrowserAnimationsModule,
        BlockTalonsComponent,
        NgxMaskDirective,
        NgxMaskPipe,
        CommonModule,
        BlockTalonsComponent,
        MatAutocompleteModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatMomentDateModule,
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation],
        },
        // { provide: JsonParser, useClass: CustomJsonParser },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiResponseInterceptor,
            multi: true,
        },
        provideLottieOptions({ player: playerFactory }),
        provideCacheableAnimationLoader(),
        NgxCookieConsentComponent,
        CookieConsentService,
        HttpNewService,
        LoadService,
        ConfigService,
        AuthService,
        RecDoctorService,
        Size,
        compToPagination,
        CookieService,
        I18n,
        { provide: MAT_DATE_LOCALE, useValue: 'ru' },
        provideEnvironmentNgxMask(),
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ConfigService, LoadService],
            useFactory: (configS: ConfigService, loadS: LoadService) => {
                return () => {
                    return lastValueFrom(configS.loadConfig()).then(() => {
                        return loadS.load().then(() => {
                            return loadS.loadLpu();
                        });
                    });
                };
            },
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor(
        private iconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.iconRegistry.addSvgIcon(
            'visibility',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/auth/ic_visibility_48px.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'visibility_off',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/auth/ic_visibility_off_48px.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_right',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/auth/arrow_right.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_left',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/auth/arrow_left.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_lab',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_lab.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_fin',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_fin.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_abon',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_abon.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_history',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_history.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_rec',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_rec.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_serv',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_serv.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_home',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_home.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'm_home_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_home_new.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'm_rec_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_rec_new.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_lab_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_lab_new.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_history_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_history_new.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'm_recService',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_recService.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'm_serv_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_serv_new.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'm_fin_new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/m_fin_new.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'arrow_down',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/arrow_down.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_up',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/arrow_up.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'nalog',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/nalog.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'exit',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/exit.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'lk',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/lk.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'menu',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/menu.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'mail',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/mail.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'phone',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/phone.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'phone-notColor',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/phone-notColor.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'refresh',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/refresh.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'contract',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/account/contract.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'logInfo',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/account/logInfo.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'krest',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/krest.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'pay',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/pay/pay.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_bottom',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/arrow_bottom.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_top',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/arrow_top.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'point',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/point.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'date_range',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/date_range.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'question',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/question.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'top',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/top.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'bottom',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/bottom.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'eye',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/eye.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'download',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/download.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'location',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/location.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'panel-close',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/panel-close.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'rec_check',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/rec_check.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'rec-krest',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/rec-krest.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'print',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/print.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'print',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/print.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'is-online-pay',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/is_online_pay.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'is-telemed',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/is_telemed.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'null',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/null.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_right_old',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/arrow_right.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'arrow_left_old',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/arrow_left.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'search',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/search.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'doc_add',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/doc_add.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'protocol',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/protocol.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'protocol-checked',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/protocol-checked.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'tick',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/tick.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'back',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/back.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'refresh-single',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/refresh-single.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'calendar',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/calendar-icon.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'creditCard',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/creditCard.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'pdf-view',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/pdf-view.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'dicom-view',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/dicom-view.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'dicom-download',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/dicom-download.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'dicom-menu',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/dicom-menu.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'pdf-download-mobile',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/pdf-download-mobile.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'search-new',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/search-new.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'attach',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/attach.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'loader',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/loader.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'delete',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/delete.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'feedback',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/feedback.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'm_files',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/home/documents-outline.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'family',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                './assets/img/svg/family.svg'
            )
        );
    }
}
