import {
    Component,
    OnInit,
    ChangeDetectorRef,
    HostListener,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { HttpNewService } from '../../services/application/http-new.service';
import { AlertService } from '../../../lib/ariadna/alert';
import { RecordService } from '../../services/record.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { DocInfoModalComponent } from 'src/app/modals/doc-info-modal/doc-info-modal.component';
import { FormControl } from '@angular/forms';
import {
    IRnumbDate,
    IServ,
    IStaticFilter,
    IDoctor,
    ISpec,
} from '../../interfaces/record.interface';

import * as moment from 'moment';
import { ConfigService } from 'src/app/services/application/config.service.';
import { rightPanelAnimation } from 'src/app/animations/height.animation';
import { firstValueFrom, last, lastValueFrom, BehaviorSubject } from 'rxjs';
import { ServModalComponent } from 'src/app/modals/service-modal/service-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { strToDate } from '../application/global.function';
import { Utils } from 'src/app/services/utils';

interface Services {
    code: string;
    is_online_pay: 1 | 0;
    is_telemed: 1 | 0;
    keyid: number;
    price: number;
    text: string;
}

interface Doctor {
    docdepid: number;
    docid: number;
    specid: number;
    specid_1: string;
    workExp: string;
    workSpec: string;
    text: string;
    info?: any;
    rcount?: any;
}

@Component({
    selector: 'rec-services',
    templateUrl: './rec-services.component.html',
    styleUrls: ['./rec-services.component.scss'],
    animations: [rightPanelAnimation],
    // template: `
    //     <app-filter-component
    //         [filterList]="filterList"
    //         [selectedItem]="selectedFilter"
    //         (toggleDropdown)="toggleDropdown($event)"
    //     ></app-filter-component>
    // `,
})
export class RecServicesComponent implements OnInit {
    constructor(
        private httpService: HttpNewService,
        private alertService: AlertService,
        private rs: RecordService,
        private config: ConfigService,
        private modalService: NgbModal,
        private recordService: RecordService,
        private doctorInfoService: DoctorService,
        private cdref: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {}

    filterSearchText: string = '';
    panelSetStyleRnum = {
        height: '100%',
        'box-shadow': '-7px 3px 4px 0px rgb(212 212 212 / 50%)',
    };
    panelSetStyle = { height: '100%' };
    loadingRecList = false; // Загрузка
    loadingSpecList = false; // Загрузка специальностей
    loadingServices = true;
    loadingImgUrl = './assets/loading-rec.gif';
    filterList: IStaticFilter[] = [];

    selectedSrv: IServ | undefined;
    selectedDoctor: Doctor | undefined;
    // filterSearchText = '';
    services: IServ[] = [];
    doctors: Doctor[] | undefined;
    paramsTalonPicker!: IRnumbDate;
    staticFilterBTNs: IStaticFilter[] = []; // Список кнопок
    selectedFilter: IStaticFilter | null = null; //Список сервисов
    isDropdownOpen = false;
    isMobileFlilterOpen = false;
    showDoctors = true;
    showTalonPicker = false;
    talonPickerAnime = 'off';
    staticFilterSelected: any;
    searchAmine: any;
    activeItem: any;
    selectedService: ISpec | null = null; // для плейсхолдера
    selectedSpec: ISpec | null = null;

    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';

    // recDoctors: IDoctor[] | null = null;
    recDoctorsFullList: IDoctor[] | null = null;
    loadingListDoc: boolean = false;
    recListLength: number = 0; // Количество записей

    periodStart: Date = new Date();
    // periodEnd: Date = new Date();
    periodEnd = moment(this.periodStart).add(30, 'days').toDate();
    filterSearchControl = new FormControl();

    @HostListener('document:click', ['$event'])
    @ViewChild('filterSearchText')
    filterSearchInput: ElementRef | undefined;
    filteredServices: any[] = [];
    window = window;
    openMobileTemplate = false;

    ngOnInit(): void {
        this.filteredServices = this.services;
        moment.locale('ru');
        this.getServicesFullList(null);
        lastValueFrom(this.recordService.getStacfilterList())
            .then((data: IStaticFilter[]) => {
                this.filterList = data;
            })
            .catch((error) => {
                this.alertService.error(
                    error.msg ||
                        'Не удалось загрузить список услуг по данному фильтру.'
                );
            });
        this.filterSearchControl.valueChanges.subscribe((value) => {
            this.filterSearchText = value;
            this.filteredServices = this.services.filter((serv) =>
                serv.text
                    .toString()
                    .toLowerCase()
                    .includes(this.filterSearchText.toString().toLowerCase())
            );
        });
        if (this.filterSearchInput) {
            this.filterSearchInput.nativeElement.addEventListener(
                'input',
                () => {
                    this.onFilterInput();
                }
            );
        }
    }
    // setDoctorsExp() {
    //     this.doctors?.forEach((item, index) => {
    //         // console.log(this.doctors);
    //         if (this.doctors && this.doctors?.length > 0) {
    //             // console.log('find?');
    //             // console.log(this.doctors[index], item);
    //             this.doctors[index].workExp =
    //                 item?.info?.find((value: any) => {
    //                     return value.queston.toLowerCase().includes('cтаж');
    //                 })?.answer || '';
    //         }
    //     });
    // }

    onFilterInput(event?: Event): void {
        if (this.filterSearchInput) {
            this.filterSearchText = this.filterSearchInput.nativeElement.value;
            this.filteredServices = this.services.filter((service) =>
                service.text
                    .toLowerCase()
                    .includes(this.filterSearchText.toLowerCase())
            );
        }
        const input = event?.target as HTMLInputElement;
        if (input.value === '') {
            this.clearFilterSearchText();
        }
    }
    experienceText(exp: string): string {
        return Utils.experienceText(exp);
    }

    filterServices() {
        if (!this.filterSearchText) {
            return this.services;
        }

        return this.services.filter((service) =>
            service.text
                .toLowerCase()
                .includes(this.filterSearchText.toLowerCase())
        );
    }

    ngAfterViewInit(): void {
        if (this.filterSearchInput) {
            this.filterSearchInput.nativeElement.addEventListener(
                'input',
                () => {
                    this.onFilterInput();
                }
            );
        }
        const options = document.querySelectorAll('.filter__option');
        options.forEach((option, index) => {
            (option as HTMLElement).style.setProperty(
                '--item-index',
                String(index + 1)
            );
        });
    }

    async getServicesFullList(value: number | null) {
        this.loadingServices = true;
        this.staticFilterSelected = value;
        this.showTalonPicker = false;

        try {
            const res: IServ[] = await lastValueFrom(
                this.httpService.post('/record/rnumb/services-full-list', {
                    stacId: value ?? null,
                })
            );

            if (!res) {
                this.alertService.error(
                    'Нет свободных врачей на даннную услугу'
                );
            }

            this.services = res.sort((a, b) => {
                const aText = a.text.toUpperCase(),
                    bText = b.text.toUpperCase();
                if (aText < bText) {
                    return -1;
                }
                if (aText > bText) {
                    return 1;
                }
                return 0;
            });

            this.loadingServices = false;
        } catch (e) {
            console.log(e);
        } finally {
            this.loadingServices = false; //скрываем loader
        }
    }

    isEmptyObject(obj: Object) {
        return JSON.stringify(obj) === '{}';
    }

    async pickDoctor(doctor: Doctor) {
        try {
            this.selectedDoctor = doctor;
            this.selectedSpec = this.selectedSpec ?? {
                keyid: doctor.specid,
                text: doctor.specid_1,
            };

            const periodStart = new Date();
            const periodEnd = moment(periodStart).add(30, 'days').toDate();

            this.paramsTalonPicker = {
                doctorId: this.selectedDoctor.docid,
                specId: this.selectedDoctor.specid,
                periodStart,
                periodEnd,
                srv: this.selectedSrv,
                infoDoc: doctor.info,
            };

            if (window.innerWidth < 500) {
                this.selectedDoctor = doctor;
                this.openMobileTemplate = true;
            } else {
                this.showTalonPicker = true;
            }

            this.talonPickerAnime = 'on';
        } catch (e) {
            console.log(e);
        }
        this.activeItem = doctor;
    }

    async pickService(service: IServ) {
        try {
            this.selectedSrv = service;
            this.loadingServices = true;
            const res = await lastValueFrom(
                this.httpService.get(
                    `/doctor/findByServices/${service.keyid}`,
                    null
                )
            );

            this.doctors = res;

            //проверка данных
            // this.doctors = res.reduce((acc: Doctor[], doctor: Doctor) => {
            //     return [...acc, doctor, doctor, doctor, doctor, doctor, doctor, doctor];
            // }, []);

            this.doctors?.forEach(async (item, index) => {
                const result = await firstValueFrom(
                    this.doctorInfoService.getDocAboutInfo(item.docid)
                );

                if (this.doctors && result.length > 0) {
                    this.doctors[index] = {
                        ...this.doctors[index],
                        info: result,
                    };
                    console.log(result);
                    this.doctors[index].workExp =
                        result.find((value) => {
                            // console.log(
                            //     value.queston.toLowerCase(),
                            //     value.queston.toLowerCase().includes('стаж')
                            // );
                            return value.queston.toLowerCase().includes('стаж');
                        })?.answer || '';
                }
                // this.setDoctorsExp();
            });

            this.loadingServices = false;
            this.showDoctors = true;
        } catch (e: any) {
            this.loadingServices = false;
            if (e.status === 400) {
                return this.alertService.error(e.error.msg);
            }
        }

        const specList: number[] =
            this.doctors?.map((doctor) => doctor.specid) ?? [];

        if (specList) {
            specList.forEach((spec) => {
                this.getDocList(spec);
            });
        }

        this.isDropdownOpen = false;
        this.selectedService = service;
        this.filterSearchText = this.selectedService?.text ?? '';
        this.filterSearchControl.setValue(this.selectedService?.text ?? '');
    }

    getDocImg(id: number | undefined) {
        return `${this.config.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getDocImgDefault(event: Event) {
        const elem = event.target as HTMLImageElement;
        elem.src = `${this.config.getValue('hostBackend')}/img/doc/not.png`;
    }

    openServModal(serv: IServ, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(ServModalComponent);
        modal.componentInstance.serv = serv;
    }
    openDocInfoModal(doc: Doctor | undefined, event: Event) {
        console.log(doc);
        this.selectedDoctor = doc;
        const Idoc: Partial<IDoctor> = {
            doctorid: doc?.docid as number,
            l_name: doc?.text.split(' ')[0] as string,
            f_name: doc?.text.split(' ')[1] as string,
            s_name: doc?.text.split(' ')[2] as string,
            info: doc?.info,
        };
        event.stopPropagation();
        if (this.selectedDoctor) {
            const modal = this.modalService.open(DocInfoModalComponent);
            modal.componentInstance.doc = Idoc;
            modal.componentInstance.spec = this.selectedDoctor?.specid_1;
        }
    }

    selectFilter(filter: IStaticFilter): void {
        this.doctors = [];
        if (this.selectedFilter !== filter) {
            this.selectedFilter = filter;
            this.doctors = [];
            this.filterSearchText = '';

            this.getServicesFullList(this.selectedFilter.keyid);
        } else {
            this.selectedFilter = null;
            this.doctors = [];
            this.filterSearchText = '';
            this.getServicesFullList(null);
        }
        this.toggleDropdown();
    }

    toggleDropdown(): void {
        this.cdref.detectChanges();
        this.searchAmine = true;
    }

    onTalonPickerClosed() {
        this.showTalonPicker = false;
    }

    rcountText(count: number): string {
        if (!count) return 'Нет номерков';

        const lastDigit = count % 10;
        const lastTwoDigits = count % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return `Осталось ${count} номерков`;
        }

        switch (lastDigit) {
            case 1:
                return `Остался ${count} номерок`;
            case 2:
            case 3:
            case 4:
                return `Осталось ${count} номерка`;
            default:
                return `Осталось ${count} номерков`;
        }
    }
    // Получить список специальностей
    getDocList(p_SpecID: number) {
        // this.recDoctors = [];
        this.loadingListDoc = true;

        let staticId: number | null = null;
        if (this.staticFilterSelected && this.staticFilterSelected.keyid) {
            staticId = this.staticFilterSelected.keyid;
        }
        //чтобы заполнить количество номерков, вызываем getDocList из сервиса:
        lastValueFrom(
            this.rs.getDocList(
                p_SpecID,
                this.periodStart,
                this.periodEnd,
                staticId
            )
        )
            .then((val) => {
                const newDocs = val.filter(
                    (doc) =>
                        !this.recDoctorsFullList?.some(
                            (fullDoc) => fullDoc.doctorid === doc.doctorid
                        )
                );

                this.recDoctorsFullList = [
                    ...(this.recDoctorsFullList ? this.recDoctorsFullList : []),
                    ...newDocs,
                ];

                this.recDoctorsFullList.forEach((item) => {
                    item.rdatemin = strToDate(item.dat_bgn);
                    item.rdatemax = strToDate(item.dat_end);
                });
                this.doctors?.forEach((item, index) => {
                    item.rcount =
                        this.recDoctorsFullList?.find(
                            (doc) => doc.doctorid === item.docid
                        )?.rcount ?? null;
                });
                // this.setDoctorsExp();
            })
            .catch((error) => {
                console.error('Error fetching doctor list:', error);
                this.alertService.error(
                    'Не удалось загрузить список врачей на данную услугу.'
                );
            })
            .finally(() => {
                this.loadingListDoc = false;
            });
    }

    onFilterChanged(event: any) {
        switch (event.typeEvent) {
            case 'clearFilter':
                this.filterList = event.data;
                this.staticFilterSelected = null;
                this.toggleDropdown();
                this.getServicesFullList(null);
                this.filterSearchText = '';
                this.doctors = [];
                break;

            case 'getStacFilters':
                this.filterList = event.data;
                break;

            case 'filterListUpdated':
                this.getServicesFullList(event.data || null);
                this.cdref.detectChanges();
                this.staticFilterSelected = event.data;
                this.filterSearchText = '';
                this.doctors = [];
                // this.toggleDropdown();
                this.isMobileFlilterOpen = false;
                break;
        }
    }
    formatDoctorName(fullName: string): string {
        const nameParts = fullName.split(' ');
        if (nameParts.length >= 2) {
            const lastName = nameParts[0];
            const initials = nameParts
                .slice(1)
                .map((part) => part.charAt(0) + '.')
                .join('');
            return `${lastName} ${initials}`;
        }
        return fullName;
    }
    selectService(service: any): void {
        this.isDropdownOpen = false;
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        const targetElement = event.target as HTMLElement;

        if (
            // !this.elementRef.nativeElement.contains(targetElement) &&
            !targetElement.classList.contains('filter__dropdown-list') &&
            !targetElement.classList.contains('filterbar__filter')
        ) {
            this.isDropdownOpen = false;
        }
    }

    mobileFilterHandler() {
        this.isMobileFlilterOpen = !this.isMobileFlilterOpen;
    }
    closeRegistrationPicker() {
        // this.selectedDoctor = undefined;

        this.openMobileTemplate = false;
    }

    clearFilterSearchText() {
        this.filterSearchText = '';
        this.filterSearchControl.setValue('');
        this.doctors = [];
        this.showDoctors = false;
        this.cdref.detectChanges();
    }
}
