<section class="modal__serv">
    <div class="modal__serv__header">
        <h5 class="info__name">
            {{ serv.text }}
        </h5>

        <button class="modal__close" (click)="closeModal($event)">
            <img src="./assets/img/svg/cross.svg" alt="Закрыть" />
        </button>
    </div>

    <div class="modal__body">
        {{ serv.description }}
    </div>
</section>
