<!-- statement.component.html -->
<div class="wrapper">
    <div class="cls-modal-block-header">
        Заявление для налоговой справки
        <div class="btn-dialog-close" [mat-dialog-close]="true">
            <img src="./assets/img/svg/cross.svg" alt="close" />
            <!-- <mat-icon svgIcon="krest" [mat-dialog-close]="true"></mat-icon> -->
        </div>
    </div>
    <loader *ngIf="loading || !loadingFrm" type="page"> </loader>

    <div>
        <form
            class="cls-statement-form"
            [formGroup]="frmStatement"
            *ngIf="loadingFrm && !showFormOk"
            [ngClass]="{ loader: loading }"
        >
            <!-- Налогоплательщик -->
            <h2 class="cls-block-header-stat">Налогоплательщик*</h2>
            <div class="form-group">
                <input
                    [type]="'text'"
                    required
                    [readonly]="loading"
                    formControlName="nalog_fio"
                    class="form-statement-field col-50"
                    placeholder="ФИО"
                />

                <input
                    type="text"
                    required
                    maxlength="12"
                    (keypress)="numberOnly($event)"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    formControlName="nalog_inn"
                    class="form-statement-field col-30"
                    placeholder="ИНН"
                />
            </div>

            <!-- Пациент -->
            <h2 class="cls-block-header-stat">Пациент*</h2>
            <div class="form-group">
                <input
                    [type]="'text'"
                    required
                    [readonly]="loading"
                    formControlName="patient_fio"
                    class="form-statement-field col-50"
                    placeholder="ФИО"
                />

                <input
                    type="date"
                    [placeholder]="patientDrPlaceholder()"
                    required
                    [max]="maxDate"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    (input)="validateDate($event)"
                    formControlName="patient_dr"
                    class="form-statement-field col-30"
                />
                <!-- <div class="">
                    <input
                        matInput
                        [matDatepicker]="picker"
                        placeholder="Дата рождения"
                        formControlName="patient_dr"
                        class="form-statement-field col-30"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div> -->
                <div
                    *ngIf="
                        frmStatement.get('patient_dr')?.hasError('invalidDate')
                    "
                    class="mat-error"
                >
                    Выбрана неверная дата
                </div>
            </div>
            <!-- Добавить пациента -->
            <div class="add-patient__button">
                <button
                    class="cls-add-patient"
                    type="submit"
                    [disabled]="disableAddPatient()"
                    (click)="addPatient()"
                    *ngIf="patient1 === false"
                >
                    + Добавить пациента
                </button>
            </div>

            <!-- Второй пациент (показывается только при наличии второго пациента) -->
            <div *ngIf="patient1">
                <div class="second__patient">
                    <h2 class="cls-block-header-stat">ФИО второго пациента</h2>
                    <h2 class="cls-block-header-stat">
                        Дата рождения второго пациента
                    </h2>
                    <div class="btn-dialog-close" (click)="delPatient(1)">
                        <img src="./assets/img/svg/cross.svg" alt="close" />
                    </div>
                    <!-- <span class="cls-icon-krest">
                        <mat-icon
                            svgIcon="krest"
                            class="cls-icon-color"
                            (click)="delPatient(1)"
                        >
                        </mat-icon>
                    </span> -->
                </div>
                <div class="form-group">
                    <input
                        [type]="'text'"
                        required
                        [readonly]="loading"
                        formControlName="patient_fio1"
                        class="form-statement-field col-50"
                        placeholder="ФИО"
                    />

                    <input
                        type="date"
                        required
                        [max]="maxDate"
                        [readonly]="loading"
                        (keydown.space)="$event.preventDefault()"
                        (input)="validateDate($event)"
                        formControlName="patient_dr1"
                        class="form-statement-field col-30"
                    />
                    <div
                        *ngIf="
                            frmStatement
                                .get('patient_dr1')
                                ?.hasError('invalidDate')
                        "
                        class="mat-error"
                    >
                        Выбрана неверная дата
                    </div>
                </div>
            </div>

            <!-- Год -->
            <div class="cls-block-header-stat">
                За какой год нужна справка?*
            </div>
            <div class="cls-block-btn-year">
                <button
                    class="form-statement-field years"
                    type="button"
                    *ngFor="let btnY of btnYearTxt; let i = index"
                    [disabled]="loading"
                    (click)="clicBtn(i)"
                    [class.active]="selectedIndexYear === i"
                >
                    {{ btnYearTxt[i] }}
                </button>
            </div>

            <!-- Требуется ли восстановление -->
            <div class="add-patient__button">
                Требуется ли восстановление договора?
                <mat-checkbox
                    class="cls-ch-recovery"
                    color="primary"
                    formControlName="is_recovery"
                ></mat-checkbox>
            </div>

            <h2 class="cls-block-header-stat">Доверенное лицо</h2>
            <div class="form-group">
                <input
                    [type]="'text'"
                    required
                    [readonly]="loading"
                    formControlName="dov_fio"
                    class="form-statement-field col-50"
                    placeholder="ФИО"
                />

                <!-- <span>+7 &nbsp;</span> -->
                <div class="phone-number">
                    <select class="form-statement-field">
                        <option value="+7" style="text-align: center">
                            +7
                        </option>
                        <option value="+1">+996</option>
                    </select>
                    <input
                        [type]="'text'"
                        placeholder="(999) 999-99-99"
                        phoneMask
                        [preValue]="frmStatement.get('dov_phone')!.value"
                        required
                        maxlength="14"
                        [readonly]="loading"
                        (keydown.space)="$event.preventDefault()"
                        formControlName="dov_phone"
                        class="form-statement-field col-30"
                    />
                </div>
            </div>
            <!-- Отправить -->
            <button
                class="btn btn-lg w-100 cls-btn-second"
                [ngClass]="{
                    'cls-btn-send': isFormValid(),
                    'cls-btn-send-disable': !isFormValid()
                }"
                type="button"
                [disabled]="loading || !isFormValid()"
                (click)="save()"
                style="margin-bottom: 20px; margin-top: 50px"
            >
                Отправить
            </button>
        </form>

        <div *ngIf="showFormOk">
            <h1
                class="tab-success"
                style="font-size: 29px; margin-bottom: 20px; margin-top: 20px"
            >
                Ваше заявление отправлено на рассмотрение.
            </h1>
            <h3
                class="tab-success"
                style="font-size: 19px"
                *ngIf="checkValue(INFO_HEADER_STATEMENT)"
            >
                {{ INFO_HEADER_STATEMENT }}
            </h3>
            <p
                class="tab-success"
                style="font-size: 19px"
                *ngIf="checkValue(INFO_BODY_STATEMENT)"
            >
                {{ INFO_BODY_STATEMENT }}
            </p>
        </div>
    </div>
</div>
