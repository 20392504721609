<ng-container [ngSwitch]="page">
    <div *ngIf="error !== null" class="err --active" style="bottom: 90px">
        <p class="err__descr">{{ error }}</p>
        <button class="err__cloce reset" (click)="hideError()">
            <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.0492 9.44691L18.1713 16.569C18.5956 16.9933 18.5956 17.6631 18.1713 18.0649C17.7471 18.4891 17.0773 18.4891 16.6755 18.0649L9.55336 10.9428L2.43124 18.0649C2.007 18.4891 1.3372 18.4891 0.935369 18.0649C0.511127 17.6407 0.511127 16.9709 0.935369 16.569L8.05749 9.44691L0.935369 2.32479C0.511127 1.90055 0.511127 1.23076 0.935369 0.828923C1.35961 0.404682 2.0294 0.404682 2.43124 0.828923L9.55336 7.95104L16.6755 0.828923C17.0997 0.404682 17.7695 0.404682 18.1713 0.828923C18.5956 1.25316 18.5956 1.92296 18.1713 2.32479L11.0492 9.44691Z"
                    fill="#ffffff"
                ></path>
            </svg>
        </button>
    </div>

    <div
        class="loader"
        *ngIf="loadingTalon || cancelingTalon || loadingPay || loadingTalonInfo"
    >
        <div class="loader__animation">
            <loader type="page"> </loader>
        </div>
        <div class="loader__text">
            <ng-container *ngIf="loadingTalon">
                Бронируем номерок
            </ng-container>
            <ng-container *ngIf="cancelingTalon">
                Отменяем номерок
            </ng-container>
            <ng-container *ngIf="loadingPay"> Загрузка платежа </ng-container>
            <ng-container *ngIf="loadingTalonInfo">
                Загружаем информацию о номерке
            </ng-container>
        </div>
    </div>

    <ng-template
        ngSwitchCase="talon"
        *ngIf="
            (talon && talon.beginDate) ||
            (params && params.info && params.info.beginDate)
        "
    >
        <div class="pay-err staps filters record">
            <div class="record-filter">
                <div class="record-filter__wrapper">
                    <div class="record__header record-top">
                        <div class="record-top__left">
                            <!-- <button
                                class="record-back reset"
                                (click)="closeExit()"
                            >
                                <img
                                    src="./assets/img/svg/back.svg"
                                    alt="Назад"
                                />
                            </button> -->
                            <div class="record-top__title record-title">
                                Запись на прием
                            </div>
                        </div>
                        <button
                            class="record-top__cloce list-cloce reset"
                            (click)="closeExit()"
                        >
                            <img
                                src="./assets/img/svg/cross.svg"
                                alt="Закрыть"
                            />
                        </button>
                    </div>
                    <div
                        class="record__body"
                        *ngIf="
                            getInfoErrorMessage.length === 0;
                            else showInfoError
                        "
                    >
                        <div class="record__top">
                            <div class="record__doctor doctor">
                                <img
                                    [src]="getImgSrcDoc(talon?.doctor_id)"
                                    class="doctor__image"
                                    (error)="errorHandlerIMG($event, 'doc')"
                                    alt="Доктор {{ getFIODoc(talon) }}"
                                />
                                <!-- <userpic
                                [image]="getImgSrcDoc(talon?.doctor_id)"
                                [size]="'4.5rem'"
                             ></userpic> -->
                                <!-- getFIODoc(talon) -->
                                <div class="doctor__info">
                                    <div class="doctor__name">
                                        {{
                                            talon?.lastname +
                                                " " +
                                                talon?.firstname +
                                                " " +
                                                talon?.secondname
                                        }}

                                        <span class="doctor__spec">
                                            {{ talon?.spec }}
                                        </span>
                                    </div>
                                    <button
                                        type="button"
                                        class="doctor__info-button"
                                        (click)="openDocInfoModal($event)"
                                    >
                                        <img
                                            src="./assets/img/svg/info.svg"
                                            alt="info"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div class="record__date date-info">
                                <div class="date-info__rezult">
                                    {{
                                        getTalonDateTime(
                                            talon?.beginDate ||
                                                params?.info?.beginDate,
                                            withTime()
                                        )
                                    }}
                                </div>
                                <div class="date-info__day">
                                    {{
                                        getNameDay(
                                            talon?.beginDate ||
                                                params?.info?.beginDate
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="record__address pay-err__addres addres">
                            <div class="addres__label">
                                Вы записываете на прием :
                            </div>
                            <div class="addres__location">
                                {{ patientName }}
                            </div>
                            <div class="addres__label">
                                Прием врача по адресу:
                            </div>
                            <div class="addres__location">
                                {{ talon?.addr }}
                                <a [href]="'tel:' + talon?.phone">{{
                                    formatPhone(talon?.phone)
                                }}</a>

                                <!-- talon?.phone  -->
                            </div>
                        </div>

                        <div class="record__result staps__box">
                            <div
                                class="record__payments staps__rezult filter-rezult"
                            >
                                <ng-template
                                    *ngIf="
                                        talon?.url_telemed;
                                        then showIconTelemed;
                                        else showIconNotPayTelemed
                                    "
                                ></ng-template>

                                <div
                                    class="record__service-name filter-rezult__name"
                                >
                                    <span class="record__service-text"
                                        ><p>
                                            {{
                                                params?.srv_text ??
                                                    params?.srv?.text ??
                                                    ""
                                            }}
                                        </p>
                                        <img
                                            *ngIf="params?.srv?.is_online_pay"
                                            src="./assets/img/svg/rub.svg"
                                            alt="info"
                                    /></span>
                                    <span class="record__service-desc">
                                        {{
                                            params?.srv_description ??
                                                params?.srv?.srv_description ??
                                                params?.srv?.description ??
                                                ""
                                        }}
                                    </span>
                                </div>
                                <div class="record__price filter-rezult__price">
                                    {{ setSumm() }}
                                </div>
                                <span
                                    class="filter-rezult__image"
                                    *ngIf="params?.srv?.is_online_pay"
                                    ><img
                                        src="./assets/img/svg/rub.svg"
                                        aria-hidden="true"
                                        alt="rub"
                                /></span>
                            </div>
                            <div
                                *ngIf="params?.srv?.is_online_pay"
                                class="pay-err__info"
                            >
                                Просьба оплатить в течение
                                {{ WEB_LK_PAY_LIFETIME }} минут. Иначе запись
                                будет отменена
                            </div>
                        </div>

                        <barcode
                            *ngIf="talon?.visitid"
                            class="record__barcode"
                            value="{{ talon?.visitid }}"
                        ></barcode>
                    </div>
                    <ng-template #showInfoError>
                        <div class="get-info-error">
                            {{ getInfoErrorMessage }}
                        </div>
                    </ng-template>
                </div>

                <div
                    class="droparea"
                    *ngIf="params?.withUpload"
                    (drop)="onDrop($event)"
                    (dragover)="onDragOver($event)"
                >
                    <label
                        class="droparea__label"
                        for="dropareaInput"
                        (click)="stopPropagation($event)"
                    >
                        <input
                            type="file"
                            [accept]="allowedExt"
                            multiple
                            id="dropareaInput"
                            class="droparea__input"
                            style="display: none"
                            (change)="onChangeInputUpload($event)"
                        />
                        <div
                            class="droparea__placeholder placeholder"
                            *ngIf="attachedFiles.size === 0"
                        >
                            <h5 class="placeholder__title">
                                Добавьте архив в формате .zip .rar или .iso
                            </h5>
                            <span class="placeholder__tip tip--desktop"
                                >или перетащите в область (объем не более 1
                                ГБ)</span
                            >
                            <span class="placeholder__tip tip--mobile"
                                >нажмите на область и выберите файл (объем не
                                более 1 ГБ)</span
                            >
                            <br />
                            <br />
                            <span class="placeholder__text">
                                Для МРТ, КТ и ПЭТ загрузите архив с DICOM<br />
                                Для Видео-ЭЭГ данные мониторинга и программу для
                                просмотра этих данных
                            </span>
                        </div>

                        <ul
                            class="filelist droparea__filelist"
                            *ngIf="attachedFiles.size"
                        >
                            <li
                                *ngFor="let file of attachedFiles | keyvalue"
                                class="file droparea__file"
                                (click)="stopPropagation($event)"
                            >
                                <span class="file__ext">{{
                                    file.value.ext
                                }}</span>
                                <span class="file__name">{{
                                    file.value.name
                                }}</span>

                                <mat-icon
                                    class="file__loader"
                                    svgIcon="loader"
                                    *ngIf="file.value.status === 0"
                                ></mat-icon>

                                <mat-icon
                                    class="file__delete"
                                    *ngIf="file.value.status !== 0"
                                    (click)="
                                        deleteFileHandler(
                                            $event,
                                            file.key,
                                            file.value.id!
                                        )
                                    "
                                    svgIcon="delete"
                                ></mat-icon>
                            </li>
                        </ul>
                    </label>
                </div>

                <div
                    class="pay-err__bottom bottom-apply"
                    *ngIf="!recToPay && params?.paramCansel == false"
                >
                    <button
                        [disabled]="getInfoErrorMessage.length > 0"
                        class="bottom-apply__btn use reset"
                        (click)="onRecord()"
                    >
                        Записаться
                    </button>
                </div>
                <div
                    class="pay-err__bottom bottom-apply"
                    *ngIf="!recToPay && params?.paramCansel == true"
                >
                    <button
                        class="bottom-apply__btn use reset"
                        (click)="onCanselPage()"
                    >
                        Отменить запись
                    </button>
                </div>
                <div class="pay-err__bottom bottom-apply" *ngIf="payable">
                    <button
                        *ngIf="debounced; else debouncedRef"
                        class="bottom-apply__btn use"
                        disabled
                    >
                        <mat-icon
                            class="file__loader"
                            svgIcon="loader"
                            style="margin-left: 0"
                        ></mat-icon>
                    </button>
                    <span
                        class="pay-later"
                        (click)="payLater()"
                        *ngIf="canPayLater && !params?.srv?.is_online_pay"
                        >Оплатить в регистратуре</span
                    >
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #debouncedRef>
        <button (click)="debouncePay()" class="bottom-apply__btn use">
            Оплатить
        </button>
    </ng-template>
    <ng-template ngSwitchCase="record">
        <div class="pay pay-err staps filters record">
            <div class="pay__top">
                <div class="pay-current__complite padtop complite">
                    <span class="pay__top-text"> Запись к врачу</span>
                    <div class="complite-text">
                        Вы успешно записаны на прием
                    </div>
                </div>
                <div class="record__top">
                    <div class="record__doctor doctor">
                        <img
                            [src]="getImgSrcDoc(talon?.doctor_id)"
                            class="doctor__image"
                            (error)="errorHandlerIMG($event, 'doc')"
                            alt="Доктор {{ getFIODoc(talon) }}"
                        />

                        <div class="doctor__info">
                            <div class="doctor__name">
                                {{
                                    talon?.lastname +
                                        " " +
                                        talon?.firstname +
                                        " " +
                                        talon?.secondname
                                }}

                                <span class="doctor__spec">
                                    {{ talon?.spec }}
                                </span>
                            </div>
                            <button
                                type="button"
                                class="doctor__info-button"
                                (click)="openDocInfoModal($event)"
                            >
                                <img
                                    src="./assets/img/svg/info.svg"
                                    alt="info"
                                />
                            </button>
                        </div>
                    </div>
                    <div class="record__date date-info">
                        <div class="date-info__rezult">
                            {{
                                getTalonDateTime(
                                    talon?.beginDate || params?.info?.beginDate,
                                    withTime()
                                )
                            }}
                        </div>
                        <div class="date-info__day">
                            {{
                                getNameDay(
                                    talon?.beginDate || params?.info?.beginDate
                                )
                            }}
                        </div>
                    </div>
                </div>
                <div class="record__address pay-err__addres addres">
                    <div class="addres__label">Вы записываете на прием :</div>
                    <div class="addres__location">
                        {{ patientName }}
                    </div>
                    <div class="addres__label">Прием врача по адресу:</div>
                    <div class="addres__location">
                        {{ talon?.addr }}
                        <a [href]="'tel:' + talon?.phone">{{
                            formatPhone(talon?.phone)
                        }}</a>

                        <!-- talon?.phone  -->
                    </div>
                </div>
            </div>

            <div class="pay-err__bottom pay__box">
                <div class="pay-current__buttons bottom-apply">
                    <button
                        class="bottom-apply__btn lose reset"
                        (click)="toHistory($event)"
                    >
                        Мои номерки
                    </button>
                    <button
                        class="bottom-apply__btn use reset"
                        (click)="toRecord($event)"
                    >
                        Новая запись
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template ngSwitchCase="pay">
        <div class="pay pay-err staps record">
            <div class="pay__top record-top">
                <div class="record-top__left">
                    <div class="record-top__title record-title">
                        Онлайн-оплата
                    </div>
                </div>
                <button class="list-cloce reset">
                    <img
                        src="./assets/img/svg/cloce.svg"
                        alt="Закрыть"
                        [mat-dialog-close]="true"
                    />
                </button>
            </div>

            <div class="pay__center">
                <div class="pay__mail mail" *ngIf="paySystem?.isEmail">
                    <input
                        id="mail"
                        class="mail__input reset"
                        placeholder="Адрес почты"
                        type="email"
                        [(ngModel)]="payParam.email"
                    />
                    <label for="mail" class="mail__label"
                        >Почта для получения чека</label
                    >
                </div>
                <div class="pay__total total">
                    <input
                        type="hidden"
                        [(ngModel)]="payParam.sum"
                        required
                        class="cls-input-sum"
                        readonly
                    />
                    <span class="total__label">Всего к оплате</span>
                    <strong class="total__price"
                        >{{ payParam.sum }} рублей</strong
                    >
                </div>
            </div>

            <div class="pay__nav">
                <div class="pay__row">
                    <div class="pay__image">
                        <img src="./assets/img/pay-1.png" alt="" />
                    </div>
                    <div class="pay__image">
                        <img src="./assets/img/pay-2.png" alt="" />
                    </div>
                    <div class="pay__image">
                        <img src="./assets/img/pay-3.png" alt="" />
                    </div>
                    <div class="pay__image">
                        <img src="./assets/img/pay-4.png" alt="" />
                    </div>
                </div>
                <div class="pay-err__bottom bottom-apply">
                    <button
                        class="bottom-apply__btn use reset"
                        (click)="goPay()"
                        [disabled]="validPay()"
                    >
                        <svg
                            width="25"
                            height="17"
                            viewBox="0 0 25 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.94424 0H21.9782C23.0772 0 24.007 0.929851 24.007 1.94424V14.1167C24.007 15.2156 23.0772 16.0609 21.9782 16.0609H1.94424C0.929851 16.0609 0 15.2156 0 14.1167V1.94424C0 0.929851 0.929851 0 1.94424 0ZM2.02877 7.43867H22.4853V9.89011H2.02877V7.43867ZM2.53597 2.02857H7.35413C7.60772 2.02857 7.86132 2.28217 7.86132 2.53577V5.49443C7.86132 5.74803 7.60772 5.91709 7.35413 5.91709H2.53597C2.28237 5.91709 2.02877 5.74802 2.02877 5.49443V2.53577C2.02877 2.28217 2.28237 2.02857 2.53597 2.02857Z"
                                fill="white"
                            />
                        </svg>
                        Оплатить
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template ngSwitchCase="canselOK">
        <div class="cancel-window">
            <div class="cancel-window_top">
                <button class="record-back reset" (click)="closeExit()">
                    <img src="./assets/img/svg/back.svg" alt="Назад" />
                </button>
                <div class="record-top__title record-title">Отмена записи</div>

                <button
                    class="record-top__cloce list-cloce reset"
                    (click)="closeExit()"
                >
                    <img src="./assets/img/svg/cross.svg" alt="Закрыть" />
                </button>
            </div>

            <div class="list-item">
                <div class="list-item__box">
                    <div class="">
                        <img
                            [src]="getImgSrcDoc(talon?.doctor_id)"
                            (error)="errorHandlerIMG($event, 'doc')"
                            alt="Доктор {{ getFIODoc(talon) }}"
                            class="doctor__image"
                        />
                    </div>
                    <div class="list-item__info">
                        <span class="doctor__name">{{ getFIODoc(talon) }}</span>
                        <span class="list-item__number">
                            {{ talon?.spec }}
                            <img
                                src="./assets/img/svg/info.svg"
                                aria-hidden="true"
                                alt="info"
                            />
                        </span>
                    </div>
                    <div class="record__date">
                        <div class="date-info__rezult">
                            {{ getTalonDateTime(talon?.beginDate, withTime()) }}
                        </div>
                        <div class="date-info__day">
                            {{ getNameDay(talon?.beginDate) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="staps__box">
                <div class="staps__rezult filter-rezult">
                    <div class="filter-rezult__name">
                        {{ params?.srv?.text }}
                    </div>
                    <div class="filter-rezult__price">
                        {{ setSumm() }}
                    </div>
                </div>
            </div>
            <div class="pay-err__bottom bottom-apply">
                <button
                    class="bottom-apply__btn use reset"
                    routerLink="/home/history"
                    (click)="onCanselRecord()"
                >
                    Отменить
                </button>
                <button
                    class="bottom-apply__btn lose reset"
                    routerLink="/home/history"
                    [mat-dialog-close]="true"
                >
                    Не отменять
                </button>
            </div>
        </div>
    </ng-template>

    <!-- <ng-template ngSwitchCase="canselOK">
        <div class="pay-err staps filters record">
            <div class="record-filter">
                <div class="record-filter__wrapper">
                    <div class="record-top">
                        <div class="record-top__left">
                            <button
                                class="record-back reset"
                                (click)="closeExit()"
                            >
                                <img
                                    src="./assets/img/svg/back.svg"
                                    alt="Назад"
                                />
                            </button>
                            <div class="record-top__title record-title">
                                Отмена записи
                            </div>
                        </div>
                        <button
                            class="record-top__cloce list-cloce reset"
                            (click)="closeExit()"
                        >
                            <img
                                src="./assets/img/svg/cross.svg"
                                alt="Закрыть"
                            />
                        </button>
                    </div>

                    <div class="date-info">
                        <div class="date-info__rezult">
                            {{ getTalonDateTime(talon?.beginDate, withTime()) }}
                        </div>
                        <div class="date-info__day">
                            {{ getNameDay(talon?.beginDate) }}
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="list-item__box">
                            <div class="list-item__image">
                                <img
                                    [src]="getImgSrcDoc(talon?.doctor_id)"
                                    (error)="errorHandlerIMG($event, 'doc')"
                                    alt="Доктор {{ getFIODoc(talon) }}"
                                />
                            </div>
                            <div class="list-item__info">
                                <span class="list-item__name">{{
                                    getFIODoc(talon)
                                }}</span>
                                <span class="list-item__number">
                                    {{ talon?.spec }}
                                    <img
                                        src="./assets/img/svg/info.svg"
                                        aria-hidden="true"
                                        alt="info"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="staps__box">
                        <div class="staps__rezult filter-rezult">
                            <div class="filter-rezult__name">
                                {{ params?.srv?.text }}
                            </div>
                            <div class="filter-rezult__price">
                                {{ setSumm() }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pay-err__bottom bottom-apply">
                    <button
                        class="bottom-apply__btn use reset"
                        routerLink="/home/history"
                        (click)="onCanselRecord()"
                    >
                        Отменить
                    </button>
                    <button
                        class="bottom-apply__btn lose reset"
                        routerLink="/home/history"
                        [mat-dialog-close]="true"
                    >
                        Не отменять
                    </button>
                </div>
            </div>
        </div>
    </ng-template> -->

    <ng-template ngSwitchCase="goToTeleComp">
        <div class="loading-group">
            <div *ngIf="loadingTalon" class="loading">
                <loader type="page"> </loader>
            </div>
            <div class="popup-bg-white">
                <div
                    mat-dialog-content
                    class="talon-content container-fluid talon-m"
                >
                    <div class="cls-blok-res-rec cls-cansel-text addToTele">
                        <p class="title">
                            <b>Подключиться к Телеконференции?</b>
                        </p>

                        <div class="cls-cansel-btn">
                            <button
                                class="btn cls-btn-second cls-btn-filter cls-cansel-btn-no"
                                routerLink="/home/history"
                                [mat-dialog-close]="true"
                            >
                                Нет
                            </button>
                            <button
                                class="btn cls-btn-second cls-btn-filter cls-cansel-btn-yes cls-cansel-btn-yes--orange"
                                routerLink="/home/history"
                                (click)="goToTelemed(talon)"
                            >
                                Да
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>

<ng-template #showIconTelemed>
    <ng-template #tipContentPay let-greeting="greeting">
        Подключиться к телеконференции.
    </ng-template>
    <mat-icon
        *ngIf="talon?.is_telemed == 1"
        svgIcon="is-telemed"
        class="telemed-icon cls-icon-is-telemed icon-srv-telemed"
        (click)="goToTelemed(talon)"
        placement="top"
        [ngbTooltip]="tipContentPay"
        tooltipClass="tooltipPanel"
        container="body"
    >
    </mat-icon>
</ng-template>

<ng-template #showIconNotPayTelemed>
    <ng-template #tipContentNotPay let-greeting="greeting">
        <div style="min-width: 225px">
            Ссылка на Телеконференцию появится только после оплаты.
        </div>
    </ng-template>
    <mat-icon
        *ngIf="talon?.is_telemed == 1"
        svgIcon="is-telemed"
        class="telemed-icon ls-icon-is-telemed icon-srv-telemed"
        placement="top"
        [ngbTooltip]="tipContentNotPay"
        tooltipClass="tooltipPanelTeleNotPay"
        container="body"
    >
    </mat-icon>
</ng-template>
