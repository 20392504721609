<div class="container">
    <div class="header__row">
        <div class="header_tags">
            <span class="header__label">Запись к врачу</span>
            <button
                class="cls-btn-filter"
                *ngFor="let fl of staticFilterBTNs"
                (click)="btnStaticFiltr(fl); $event.preventDefault()"
                [ngClass]="{
                    'cls-btn-filter-active': fl.keyid === staticFilterSelected
                }"
                [disabled]="loadingListSpec"
            >
                <span class="no-wrap">{{ fl.text }}</span>
            </button>
        </div>

        <div class="filter-wrapper">
            <mat-icon
                svgIcon="search"
                class="cls-btn-icon icon-search"
            ></mat-icon>
            <input
                type="text"
                class="rec-searsh-spec"
                placeholder="Выбор специализации"
                #InputBlocSpec
                [(ngModel)]="searchStr"
                (ngModelChange)="changeSearch($event)"
            />
            <button
                class="clear-filter-btn"
                *ngIf="searchStr"
                (click)="clearSearch()"
            >
                <img
                    src="./assets/img/svg/cross.svg"
                    alt="Clear"
                    width="16px"
                    height="16px"
                />
            </button>
        </div>

        <!-- //меню для телефона -->
        <!-- *ngIf="!loadingServices" -->
        <div class="mobile-dropdown">
            <button
                class="mobile-filter__button"
                (click)="mobileFilterHandler()"
                [ngClass]="{ 'border-active': staticFilterSelected }"
            >
                <img
                    src="./assets/img/svg/filters.svg"
                    alt="filter"
                    [ngClass]="{ 'border-active': staticFilterSelected }"
                    class="mobile-filter__button-swg"
                    *ngIf="!staticFilterSelected"
                />
                <img
                    src="./assets/img/svg/filtersAccept.svg"
                    alt="filterAccepted"
                    [ngClass]="{ 'border-active': staticFilterSelected }"
                    class="mobile-filter__button-swg"
                    *ngIf="staticFilterSelected"
                />
            </button>
            <div class="dropdown-menu" *ngIf="isMobileFlilterOpen">
                <app-filter-component
                    [staticFilterSelected]="staticFilterSelected"
                    (filterChanged)="onFilterChanged($event)"
                    (closeFilter)="isMobileFlilterOpen = false"
                    [searchAmine]="searchAmine"
                    class="dropdown-menu"
                ></app-filter-component>
            </div>
        </div>
    </div>
    <div class="loader-container" *ngIf="loadingListSpec">
        <div *ngIf="loadingListSpec">
            <loader type="page"> </loader>
        </div>
    </div>
    <div #mobileTemplate *ngIf="openMobileTemplate" class="mobile-template">
        <rec-mobile
            [selectedSpec]="selectedSpec"
            [params]="paramsTalonpicker"
            (closePicker)="closeRegistrationPicker()"
        ></rec-mobile>
    </div>

    <div class="rec-content" *ngIf="!openMobileTemplate && !loadingListSpec">
        <div class="search-list" [@expandedAnimation]="searchAmine">
            <div
                class="search-list-item"
                *ngFor="let sl of specList"
                (click)="selectSpec(sl.keyid)"
            >
                <img
                    class="search-list-item-img"
                    [src]="getImgSrcSpec(sl.keyid)"
                    (error)="errorHandlerIMG($event, 'spec')"
                />
                <span class="search-list-item-text">{{ sl.text }}</span>
            </div>
        </div>

        <div class="loader-container" *ngIf="loadingListDoc">
            <div *ngIf="loadingListDoc">
                <loader type="page"> </loader>
            </div>
        </div>

        <!-- *ngIf="showDoctors" -->
        <!-- (click)="selectedDoc(doctor)" -->
        <div class="doctors" *ngIf="selectedSpec && !loadingListDoc">
            <ng-container *ngFor="let doctor of recDoctors">
                <button
                    class="doctors__item"
                    [class.active]="doctor === activeItem"
                    *ngIf="!isDropdownOpen"
                >
                    <img
                        class="doctors__img"
                        src="{{ getImgSrcDoc(doctor.doctorid) }}"
                        (error)="getDocImgDefault($event)"
                        alt="doc"
                        width="70"
                        height="70"
                        border-radius="5px"
                    />
                    <div class="doctors__item-container">
                        <div class="doctors__item-info">
                            <div class="doctors__name">
                                {{ getFioDoc(doctor) }}
                                <button
                                    type="button"
                                    class="doctors__info"
                                    (click)="openDocInfoModal(doctor, $event)"
                                    title="Нажмите для получения информации о враче"
                                >
                                    <img
                                        src="./assets/img/svg/info.svg"
                                        alt="info"
                                    />
                                </button>
                            </div>

                            <div
                                class="doctors__description"
                                *ngIf="doctor.info && doctor.info.length > 0"
                            >
                                <div class="doctors__description-text">
                                    <span class="dot"> </span>
                                    {{ experienceText(doctor.workExp) }}
                                    <span class="dot"> </span>
                                    {{ doctor.workSpec }}
                                </div>
                            </div>
                        </div>
                        <div class="doctors__right">
                            <div
                                class="service-select"
                                [ngClass]="{
                                    isInValidSrv: invalidSrv(doctor.doctorid)
                                }"
                            >
                                <div
                                    class="select-trigger"
                                    (click)="toggleSelect(doctor.doctorid)"
                                >
                                    {{
                                        selectedSrvMap[doctor.doctorid]
                                            ? selectedSrvMap[doctor.doctorid]
                                                  .text
                                            : "Выберите услугу"
                                    }}

                                    <!-- <div
                                        *ngIf="selectedSrv?.is_online_pay == 1"
                                        class="cls-icon-is-online-pay-selected"
                                    >
                                        <img src="./assets/img/svg/rub.svg" />
                                    </div> -->
                                </div>

                                <div
                                    class="filter__dropdown-list"
                                    *ngIf="openSelectStates[doctor.doctorid]"
                                >
                                    <div
                                        *ngFor="let docSrv of doctor.srvlist"
                                        class="select-option"
                                        (click)="selectService(docSrv, doctor)"
                                    >
                                        <div class="filter__option-container">
                                            <div class="srv-option__text">
                                                <span>{{ docSrv.text }}</span>
                                            </div>
                                            <div
                                                class="srv-option__price-container"
                                            >
                                                <button
                                                    *ngIf="docSrv.description"
                                                    class="doc-info-btn"
                                                    (click)="
                                                        openServDescModal(
                                                            docSrv,
                                                            $event
                                                        )
                                                    "
                                                >
                                                    <img
                                                        src="./assets/img/svg/info.svg"
                                                    />
                                                </button>
                                                <div class="srv-option__price">
                                                    <span
                                                        *ngIf="
                                                            docSrv.price != 0
                                                        "
                                                        >{{
                                                            docSrv.price
                                                        }}
                                                        руб.</span
                                                    >
                                                    <span
                                                        *ngIf="
                                                            docSrv.price == 0
                                                        "
                                                        >Бесплатно</span
                                                    >
                                                </div>
                                                <div
                                                    *ngIf="
                                                        docSrv.is_online_pay ==
                                                        1
                                                    "
                                                    class="srv-option__price-icon"
                                                >
                                                    <img
                                                        src="./assets/img/svg/rub.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="doctors__rcount"
                                *ngIf="!loadingListDoc"
                            >
                                {{ rcountText(doctor.rcount) }}
                            </div>
                        </div>
                    </div>
                </button>
            </ng-container>
            <!-- <div *ngIf="loadingListDoc" class="loading">
                <loader type="page"> </loader>
            </div> -->
        </div>
    </div>
</div>

<div
    *ngIf="showCalendar"
    class="cls-date-rnum"
    [@rightPanelAnimation]="talonPickerAnime"
>
    <div *ngIf="loadingCalendar" class="loader-wrapper">
        <loader type="page"></loader>
    </div>
    <!-- *ngIf="!loadingCalendar" -->
    <app-talonpicker
        [params]="paramsTalonpicker"
        (talonPickerClosed)="onTalonPickerClosed()"
    ></app-talonpicker>
</div>
