// компонент для отображения списка услуг врача
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IServ } from '../../../../interfaces/record.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServModalComponent } from 'src/app/modals/service-modal/service-modal.component';

@Component({
    selector: 'rec-mobile-docsrv-list',
    templateUrl: './rec-mobile-docsrv-list.html',
    styleUrls: ['./rec-mobile-docsrv-list.scss'],
})
export class recMobileDocsrvList {
    @Input() staticSrvSelected: number | null = null;
    @Input() srvList: IServ[] = [];
    @Output() srvChanged = new EventEmitter<{
        typeEvent: string;
        data: any;
    }>();
    @Output() closeSrvList = new EventEmitter<void>();

    constructor(private modalService: NgbModal) {}

    closeSrvListHandler(): void {
        this.closeSrvList.emit();
    }

    openServDescModal(docSrv: IServ, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(ServModalComponent);
        modal.componentInstance.serv = docSrv;
    }

    srvPicked(srv: IServ): void {
        this.srvChanged.emit({
            typeEvent: 'srvPicked',
            data: srv,
        });
    }
}
