<div class="filter__wrapper" *ngIf="!loading">
    <div class="filter__top">
        <div class="filter__top-iconback">
            <img
                src="./assets/img/svg/arrow_back.svg"
                alt="arrow_back"
                (click)="toggleDropdown()"
            />
        </div>
        <div class="filter-top__title">Фильтр</div>
    </div>
    <div class="filter__list">
        <div
            class="filter__list-item"
            *ngFor="let stBTN of filterList"
            (click)="onLabelClick(stBTN.keyid)"
            [class.selected]="stBTN.keyid === staticFilterSelected"
        >
            <label for="consultation_{{ stBTN.keyid }}" class="checkbox__label">
                {{ stBTN.text }}
            </label>
        </div>
    </div>
    <div class="filter__buttons">
        <button class="buttons filter__buttons-reset" (click)="clearFilter()">
            Сбросить
        </button>
        <button class="buttons filter__buttons-apply" (click)="searchClick()">
            Применить
        </button>
    </div>
</div>
<div class="loader" *ngIf="loading">
    <loader type="page"> </loader>
</div>
