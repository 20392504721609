<div class="mobile-template">
    <div class="mobile-template__header">
        <div class="mobile-template__header__icon">
            <img
                src="./assets/img/svg/arrow_back.svg"
                alt="arrow_back"
                (click)="closeComponent()"
            />
        </div>
        <span class="mobile-template__header__span">Запись на услугу</span>
        <div class="mobile-template__header__icon" (click)="calendarHandler()">
            <img
                src="./assets/img/svg/calendar.svg"
                alt="calendar"
                *ngIf="showCalendarIcon"
            />
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <loader type="page"> </loader>
    </div>
    <div class="mobile-template_content" *ngIf="!loading">
        <div class="mobile-template_content__top" *ngIf="!isCalendarActive">
            <div class="doctor__card">
                <img
                    class="doctor__img"
                    src="{{ getDocImg(selectedDoctor?.docid) }}"
                    (error)="getDocImgDefault($event)"
                    alt="doc"
                    width="70"
                    height="70"
                    border-radius="5px"
                />
                <div class="doctor__desc">
                    <div class="doctor__name">
                        {{ this.selectedDoctor?.text }}
                    </div>
                    <div class="doctor__spec">
                        {{ this.selectedDoctor?.specid_1 }} (специальность)
                    </div>
                </div>
                <button
                    type="button"
                    class="doctor__info"
                    (click)="openDocInfoModal(this.selectedDoctor, $event)"
                >
                    <img src="./assets/img/svg/info.svg" alt="info" />
                </button>
            </div>
            <div class="deps_wrapper" *ngIf="this.talonListFiltered.length > 0">
                <button class="deps_button" (click)="toggleDropdown()">
                    <span>{{ placeholderDep() }}</span>
                </button>
                <div class="deps_list" *ngIf="showDropdown">
                    <div
                        class="dropdown__item"
                        *ngFor="let dep of depList"
                        (click)="selectDep(dep)"
                    >
                        {{ dep.text }}
                    </div>
                </div>
                <div
                    class="talons__empty"
                    *ngIf="this.talonListFiltered.length === 0"
                >
                    Свободных номерков нет
                </div>
            </div>
        </div>
        <div
            class="mobile-calendar"
            *ngIf="isCalendarActive"
            [class.slide-down]="isCalendarActive"
            [class.slide-up]="!isCalendarActive"
        >
            <!-- <mat-calendar
                *ngIf="talonListAll.length !== 0"
                class="calendar talonpicker__calendar"
                #calendar
                [dateFilter]="dateFilter"
                [(selected)]="selectedDay"
                (selectedChange)="selectDay($event)"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [headerComponent]="header"
            ></mat-calendar> -->

            <app-custom-calendar
                [activeDates]="activeDays"
                [selectedDate]="selectedDay"
                (dateSelected)="onDateSelected($event)"
            ></app-custom-calendar>
        </div>
        <!-- </div> -->
    </div>
    <div
        class="talons"
        *ngIf="depPicked || selectedDep"
        [class.isCalendarActive]="isCalendarActive"
    >
        <div class="time-choice__nav" *ngIf="this.talonListFiltered.length > 0">
            <div class="button__prev">
                <button
                    (click)="prevDate()"
                    class="buttons"
                    [disabled]="isFirstDate()"
                    [class.arrow__disable]="isFirstDate()"
                >
                    <img src="./assets/img/svg/arrow_r.svg" alt="prev" />
                </button>
            </div>
            <!-- *ngIf="selectedDep || depPicked" -->
            <div
                class="time-choice__info"
                (click)="toggleClass()"
                *ngIf="
                    (talonListFiltered.length !== 0 &&
                        talonListFiltered[0].interval_id !== null) ||
                    talonListFiltered[0].rnumbid !== null
                "
            >
                <span class="time-choice__label">Свободно на</span>
                <span class="time-choice__date">{{ headerTxt }}</span>
            </div>
            <div
                class="time-choice__info"
                *ngIf="
                    talonListFiltered.length !== 0 &&
                    talonListFiltered[0].rnumbid === null &&
                    talonListFiltered[0].interval_id === null
                "
            >
                Вы записываетесь на
                <span class="time-choice__date">
                    <br />
                    {{ headerTxt }}</span
                >
            </div>
            <div class="button__next">
                <button
                    (click)="nextDate()"
                    class="buttons"
                    [disabled]="isLastDate()"
                    [class.arrow__disable]="isLastDate()"
                >
                    <img src="./assets/img/svg/arrow_r.svg" alt="next" />
                </button>
            </div>
        </div>
        <div
            class="talons__list"
            [class.slide-right]="isSlideRight"
            [class.slide-left]="isSlideLeft"
            *ngIf="
                (talonListFiltered.length !== 0 &&
                    talonListFiltered[0].interval_id !== null) ||
                talonListFiltered[0].rnumbid !== null
            "
        >
            <div
                class="talons__list-item"
                *ngFor="let talon of talonListFiltered"
                (click)="clickTalon($event, talon)"
                [class.active]="talon === pickedTalon"
                [ngClass]="{
                    disabled: !talonDisable(talon)
                }"
            >
                {{ talon.dtBegin | date : "HH:mm" }}
            </div>
        </div>
        <!-- <button
            class="btn btn-dialog-accept"
            (click)="openTalonModal($event, null)"
            [disabled]="!pickedTalon"
        >
            {{ pickedTalon ? "Выбрать" : "Выберите время" }}
        </button> -->

        <ng-container
            *ngIf="
                talonListFiltered.length !== 0 &&
                talonListFiltered[0].rnumbid === null &&
                talonListFiltered[0].interval_id === null
            "
        >
            <button
                class="btn btn-dialog-accept"
                (click)="openTalonModal($event, talonListFiltered[0])"
            >
                Продолжить
            </button>
        </ng-container>

        <ng-container
            *ngIf="
                !(
                    talonListFiltered.length !== 0 &&
                    talonListFiltered[0].rnumbid === null &&
                    talonListFiltered[0].interval_id === null
                )
            "
        >
            <button
                class="btn btn-dialog-accept"
                (click)="openTalonModal($event, null)"
                [disabled]="!pickedTalon"
            >
                <!-- {{ pickedTalon ? "Выбрать" : "Выберите время" }} -->
                {{ buttonAcceptplaceholder() }}
            </button>
        </ng-container>
    </div>
</div>
